export enum ConsumptionReportConfigurableColumns {
    UNIT = 'unit',
    TIME_RANGE = 'timeRange',
    CATEGORY = 'category',
}

export enum ConsumptionReportObligatoryColumns {
    STATUS_ICON = 'statusIcon',
    PRODUCT = 'product',
    QUANTITY = 'quantity',
}
