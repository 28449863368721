export enum Role {
    MANAGING_DIRECTOR_FINANCE = 'ManagingDirectorFinance',
    CHIEF_FINANCIAL_OFFICER = 'ChiefFinancialOfficer',
    BUSINESS_CONTROLLER = 'BusinessController',
    SALES_REPRESENTATIVE = 'SalesRepresentative',
    PURCHASER = 'Purchaser',
    VICE_PRESIDENT_OPERATIONS = 'VicePresidentOperations',
    MAINTENANCE_MANAGER = 'MaintenanceManager',
    MAINTENANCE_OPERATOR = 'MaintenanceOperator',
    STORE_MAN = 'StoreMan',
    TRAINEE = 'Trainee',
    ADMINISTRATOR = 'Administrator',
    OWNER = 'Owner',
    MEMBER = 'Member',
    PRODUCTION_PLANNER = 'ProductionPlanner',
    PREPRESS_MANAGER = 'PrepressManager',
    PREPRESS_OPERATOR = 'PrepressOperator',
    PREPRESS_ASSISTENT = 'PrepressAssistant',
    COLOR_MANAGER = 'ColorManager',
    PRESSROOM_MANAGER = 'PressroomManager',
    PRESS_OPERATOR = 'PressOperator',
    PRESS_ASSISTENT = 'PressAssistent',
    POSTPRESS_MANAGER = 'PostpressManager',
    POSTPRESS_OPERATOR = 'PostpressOperator',
    POSTPRESS_ASSISTENT = 'PostpressAssistent',
}
