import {Pipe, PipeTransform} from '@angular/core';

import {Transaction} from '@vmi/feature-transactions';

@Pipe({
    standalone: true,
    name: 'transactionProcessedDate',
})
export class TransactionProcessedDatePipe implements PipeTransform {
    transform(transaction: Transaction): Date | undefined {
        return transaction.retrievedBackAt;
    }
}
