<section class="table-wrapper">
    @if (dataSource.data.length > 0) {
        <table mat-table [dataSource]="dataSource" matSort>
            <caption>
                Consumption Report
            </caption>
            <!-- Status Icon column -->
            <ng-container [matColumnDef]="OBLIGATORY_COLUMNS.STATUS_ICON">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let report">
                    <mat-icon svgIcon="hdmui:hookCircled" class="hdmui-success"></mat-icon>
                </td>
            </ng-container>
            <!-- Product column -->
            <ng-container [matColumnDef]="OBLIGATORY_COLUMNS.PRODUCT">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.product' | translate }}
                        <span class="col__subtitle hdmui-font-small">{{
                            'inventory.products.grid.nameAndId' | translate
                        }}</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.name | empty }}
                    <span class="col__subtitle">{{ report.id | empty }}</span>
                </td>
            </ng-container>
            <!-- Quantity column -->
            <ng-container [matColumnDef]="OBLIGATORY_COLUMNS.QUANTITY">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.productDetails.grid.amount' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.amount }}
                </td>
            </ng-container>
            <!-- Unit column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.UNIT">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.unit' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.isoCode | translateProductUnit }}
                </td>
            </ng-container>
            <!-- Category column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.CATEGORY">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.category' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ report.category | translateProductCategory }}
                </td>
            </ng-container>
            <!-- Time Range column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.TIME_RANGE">
                <th mat-header-cell *matHeaderCellDef>
                    <div class="col__header-wrapper">
                        {{ 'inventory.consumptionReport.timeRange' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let report">
                    {{ timeRange }}
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
    } @else {
        <hdmui-empty-states icon="hdmui:warning">
            <hdmui-empty-states-title>{{ '*.items.notAvailable' | translate }}</hdmui-empty-states-title>
            <hdmui-empty-states-description>{{
                'inventory.consumptionReport.emptyState.description' | translate
            }}</hdmui-empty-states-description>
        </hdmui-empty-states>
    }

    <div class="button__wrapper">
        @if (displayShowMoreButton) {
            <button class="hdmui-mt-4" mat-stroked-button (click)="onShowMoreButtonClick()">
                {{ 'inventory.action.showMore' | translate }}
            </button>
        }
    </div>
</section>
