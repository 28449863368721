<section class="table-wrapper" [class.product-details-table-wrapper]="isProductDetailsPage">
    @if (dataSource.data.length > 0) {
        <table mat-table [dataSource]="dataSource" matSort>
            <caption>
                Transactions
            </caption>
            <!-- Status Icon column -->
            <ng-container [matColumnDef]="OBLIGATORY_COLUMNS.STATUS_ICON">
                <th mat-header-cell *matHeaderCellDef></th>
                <td mat-cell *matCellDef="let transaction">
                    <mat-icon
                        svgIcon="{{ transaction.status | transactionStatusIcon }}"
                        [class]="getIconClass(transaction.status)"
                    ></mat-icon>
                </td>
            </ng-container>
            <!-- Status column -->
            <ng-container [matColumnDef]="OBLIGATORY_COLUMNS.STATUS">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.status' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">{{ transaction.status | translateTransactionStatus }}</td>
            </ng-container>
            <!-- Movement Type column -->
            <ng-container [matColumnDef]="OBLIGATORY_COLUMNS.MOVEMENT_TYPE">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.productDetails.grid.type' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">{{ transaction.movementType | translateMovementType }}</td>
            </ng-container>
            <!-- Product column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.PRODUCT">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.product' | translate }}
                        <span class="col__subtitle hdmui-font-small">{{
                            'inventory.products.grid.nameAndId' | translate
                        }}</span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">
                    {{ transaction.name | empty }}
                    <span class="col__subtitle">{{ transaction.id | empty }}</span>
                </td>
            </ng-container>
            <!-- Category column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.CATEGORY">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.category' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">
                    {{ transaction.category | translateProductCategory | empty }}
                </td>
            </ng-container>
            <!-- Quantity column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.QUANTITY">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.productDetails.grid.amount' | translate }}
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">
                    <div class="quantity__wrapper">
                        {{ transaction | transactionQuantity }}
                        @if (isProcessedStockCount(transaction)) {
                            <mat-icon
                                [svgIcon]="transaction.amount < 0 ? 'hdmui:arrowClassic-D' : 'hdmui:arrowClassic-U'"
                                [class]="transaction.amount < 0 ? 'hdmui-error' : 'hdmui-success'"
                            ></mat-icon>
                        }
                    </div>
                </td>
            </ng-container>
            <!-- Posted column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.POSTED">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.posted' | translate }}
                        <span class="col__subtitle hdmui-font-small">
                            {{ 'inventory.products.grid.dateTime' | translate }}
                        </span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">
                    @if (transaction | transactionPostedDate) {
                        <span class="col__subtitle">
                            {{ transaction | transactionPostedDate | takeDateOnly | empty }},
                        </span>
                        <span class="col__subtitle">
                            {{ transaction | transactionPostedDate | takeTimeOnly | empty }}
                        </span>
                    } @else {
                        —
                    }
                </td>
            </ng-container>
            <!-- Processed column -->
            <ng-container [matColumnDef]="CONFIGURABLE_COLUMNS.PROCESSED">
                <th mat-header-cell *matHeaderCellDef mat-sort-header matRipple>
                    <div class="col__header-wrapper">
                        {{ 'inventory.products.grid.processed' | translate }}
                        <span class="col__subtitle hdmui-font-small">
                            {{ 'inventory.products.grid.dateTime' | translate }}
                        </span>
                    </div>
                </th>
                <td mat-cell *matCellDef="let transaction">
                    @if (transaction | transactionProcessedDate) {
                        <span class="col__subtitle">
                            {{ transaction | transactionProcessedDate | takeDateOnly | empty }},
                        </span>
                        <span class="col__subtitle">
                            {{ transaction | transactionProcessedDate | takeTimeOnly | empty }}
                        </span>
                    } @else {
                        —
                    }
                </td>
            </ng-container>
            <tr mat-header-row *matHeaderRowDef="columns; sticky: true"></tr>
            <tr mat-row *matRowDef="let row; columns: columns"></tr>
        </table>
    } @else {
        <hdmui-empty-states icon="hdmui:warning">
            <hdmui-empty-states-title>{{ '*.items.notAvailable' | translate }}</hdmui-empty-states-title>
            <hdmui-empty-states-description>{{
                'inventory.inventory.emptyState.description' | translate
            }}</hdmui-empty-states-description>
        </hdmui-empty-states>
    }

    <div class="button__wrapper">
        @if (displayShowMoreButton) {
            <button class="hdmui-mt-4" mat-stroked-button (click)="onShowMoreButtonClick()">
                {{ 'inventory.action.showMore' | translate }}
            </button>
        }
    </div>
</section>
