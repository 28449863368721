import {ChangeDetectionStrategy, Component, EventEmitter, inject, Input, Output} from '@angular/core';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {
  HdmuiAppearanceCustomizerComponent,
  HdmuiAppearanceCustomizerCounter,
  HdmuiAppearanceCustomizerElement,
  HdmuiAppearanceCustomizerEntry,
  HdmuiAppearanceCustomizerEntryType,
  HdmuiAppearanceCustomizerReset,
  HdmuiAppearanceCustomizerValidation,
  HdmuiIconsModule,
} from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    imports: [
        TranslateModule,
        HdmuiAppearanceCustomizerComponent,
        HdmuiIconsModule,
        MatButtonModule,
        MatIconModule,
        HdmuiIconsModule,
        MatBadgeModule,
    ],
    selector: 'vmi-table-appearance-customizer',
    templateUrl: './table-appearance-customizer.component.html',
    styleUrls: ['./table-appearance-customizer.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TableAppearanceCustomizerComponent {
    readonly #translateService = inject(TranslateService);

    @Input()
    title!: string;

    @Input()
    disabled = false;

    @Input()
    elements!: HdmuiAppearanceCustomizerElement[];

    @Input()
    set defaultElements(defaultElements: HdmuiAppearanceCustomizerElement[]) {
        this.RESET_DATA.applicationDefault = defaultElements;
        this.COUNTER_DATA.upperBound = defaultElements.length;
    }

    @Input()
    displayBadge = false;

    @Output()
    configElementsChange = new EventEmitter<HdmuiAppearanceCustomizerElement[]>();

    get entry(): HdmuiAppearanceCustomizerEntry {
        return this.ENTRY_DATA;
    }

    get counter(): HdmuiAppearanceCustomizerCounter {
        return this.COUNTER_DATA;
    }

    get reset(): HdmuiAppearanceCustomizerReset {
        return this.RESET_DATA;
    }

    get validation(): HdmuiAppearanceCustomizerValidation {
        return this.VALIDATION_DATA;
    }

    public onElementsChangeEvent(elements: HdmuiAppearanceCustomizerElement[]): void {
        this.configElementsChange.emit(elements);
    }

    private readonly ENTRY_DATA: HdmuiAppearanceCustomizerEntry = {
        entryText: this.#translateService.instant('*.appearanceCustomizer.entryText'),
        entryType: HdmuiAppearanceCustomizerEntryType.ICON_BUTTON,
    };

    private readonly COUNTER_DATA: HdmuiAppearanceCustomizerCounter = {
        counterText: this.#translateService.instant('*.appearanceCustomizer.selected'),
        lowerBoundHint: this.#translateService.instant('*.appearanceCustomizer.lowerBoundHint'),
        upperBoundHint: this.#translateService.instant('*.appearanceCustomizer.upperBoundHint'),
        lowerBound: 0,
        upperBound: 0,
    };

    private readonly RESET_DATA: HdmuiAppearanceCustomizerReset = {
        resetText: this.#translateService.instant('*.reset'),
        resetToDefaultText: this.#translateService.instant('*.reset_to_default'),
        resetToLastSavedText: this.#translateService.instant('*.reset_to_last_saved'),
        applicationDefault: [],
    };

    private readonly VALIDATION_DATA: HdmuiAppearanceCustomizerValidation = {
        cancelText: this.#translateService.instant('*.action.cancel'),
        discardText: this.#translateService.instant('*.discard_changes'),
        acceptText: this.#translateService.instant('*.action.save'),

        validationOnCloseText: this.#translateService.instant('*.action.discardChanges.question'),
        validationOnCloseHint: this.#translateService.instant('*.appearanceCustomizer.validationOnCloseHint'),

        validationOnResetText: this.#translateService.instant('*.appearanceCustomizer.validationOnResetText'),
        validationOnResetHint: this.#translateService.instant('*.appearanceCustomizer.validationOnResetHint'),
    };
}
