import {inject, Injectable} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {
  BehaviorSubject,
  debounceTime,
  filter,
  interval,
  map,
  mergeWith,
  Observable,
  pairwise,
  startWith,
  Subject,
  switchMap,
  take,
  tap,
} from 'rxjs';
import {uniqBy} from 'lodash-es';

import {ContractType} from '@heidelberg/vmi-subscription-api-client';

import {FileDownloaderService, RequestMetadata, ResizeService} from '@vmi/utils';
import {InventoryService, PrintShopsService} from '@vmi/core';
import {DATA_REFRESH_INTERVAL, PAGE_VISIBILITY_CHANGE} from '@vmi/injection-tokens';
import {DataDisplayType, MimeType} from '@vmi/shared-models';
import {
  DisplayTypeStorageKey,
  DisplayTypeStorageService,
  FiltersStorageKey,
  FiltersStorageService,
  GlobalLoadingSpinnerService,
  TransactionsFiltersStorage,
} from '@vmi/ui-smart';

import {MovementType, Transaction, TransactionsFilters, TransactionStatus} from '../models';
import {TranslateTransactionStatusPipe} from '../pipes/translate-transaction-status.pipe';
import {TranslateMovementTypePipe} from '../pipes/translate-movement-type.pipe';
import {TransactionsClipboardService} from '../services/transactions-clipboard.service';

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class TransactionsFacade {
    readonly FIVE = 5;

    readonly #inventoryService = inject(InventoryService);
    readonly #resizeService = inject(ResizeService);
    readonly #DATA_REFRESH_INTERVAL = inject(DATA_REFRESH_INTERVAL);
    readonly #displayTypeLocalStorageService = inject(DisplayTypeStorageService);
    readonly #filtersLocalStorageService = inject(FiltersStorageService);
    readonly #fileDownloaderService = inject(FileDownloaderService);
    readonly #globalLoadingSpinnerService = inject(GlobalLoadingSpinnerService);
    readonly #printShopsService = inject(PrintShopsService);
    readonly #pageVisibilityChange$ = inject(PAGE_VISIBILITY_CHANGE);
    readonly #transactionsClipboardService = inject(TransactionsClipboardService);

    readonly #translateMovementTypePipe = new TranslateMovementTypePipe();
    readonly #translateTransactionStatusPipe = new TranslateTransactionStatusPipe();

    readonly #searchPhraseSubj$ = new BehaviorSubject<string>('');
    readonly #transactionsMetadataSubj$ = new BehaviorSubject<RequestMetadata<Transaction[]> | undefined>(undefined);
    readonly #getTransactionsTriggerSubj$ = new Subject<void>();
    readonly #isSearchPhraseLoadingSubj$ = new BehaviorSubject<boolean>(false);
    readonly #dataDisplayTypeSubj$ = new BehaviorSubject<DataDisplayType>(
        this.#displayTypeLocalStorageService.getDisplayType(DisplayTypeStorageKey.TRANSACTIONS) || DataDisplayType.TABLE
    );
    readonly #appliedFiltersSubj$ = new BehaviorSubject<TransactionsFilters | undefined>(undefined);

    readonly #date = new Date();

    public readonly searchPhrase$ = this.#searchPhraseSubj$.asObservable();
    public readonly transactionsMetadata$ = this.#transactionsMetadataSubj$.asObservable();
    public readonly getTransactionsTrigger$ = this.#getTransactionsTriggerSubj$.asObservable();
    public readonly isSearchPhraseLoading$ = this.#isSearchPhraseLoadingSubj$.asObservable();
    public readonly dataDisplayType$ = this.#dataDisplayTypeSubj$.asObservable();
    public readonly appliedFilters$ = this.#appliedFiltersSubj$.asObservable();

    public isTabletUp$ = this.#resizeService.isTabletUp$;

    public readonly transactionsMetadataRequest$: Observable<RequestMetadata<Transaction[]>> = this.#inventoryService
        .getAllTransactions(this.#date)
        .pipe(
            untilDestroyed(this),
            mergeWith(
                this.getTransactionsTrigger$.pipe(
                    switchMap(() =>
                        this.#inventoryService.getAllTransactions(this.#date).pipe(
                            untilDestroyed(this),
                            filter((requestMetadata) => !requestMetadata.isLoading)
                        )
                    )
                )
            ),
            tap((transactionsMetadata) => {
                this.#transactionsMetadataSubj$.next(transactionsMetadata);
            })
        );

    public readonly activeTransactionsRequest$: Observable<RequestMetadata<Transaction[]>> = this.#inventoryService
        .getActiveTransactions(this.FIVE)
        .pipe(untilDestroyed(this));

    public availableFilters$: Observable<TransactionsFilters> = this.transactionsMetadata$.pipe(
        map((metadata) => metadata?.data || []),
        map(
            (transactions) =>
                ({
                    statuses: this.getStatusesFilterEntries(transactions),
                    movementTypes: this.getMovementTypesFilterEntries(transactions),
                }) as TransactionsFilters
        )
    );

    constructor() {
        this.#date.setMonth(this.#date.getMonth() - 2);
        this.#date.setHours(0, 0, 0, 0);

        this.listenForGetTransactionsTrigger();
        this.listenForSearchPhraseChanges();
    }

    public triggerGetTransactions(): void {
        this.#getTransactionsTriggerSubj$.next();
    }

    public setSearchPhrase(searchPhrase: string): void {
        this.#searchPhraseSubj$.next(searchPhrase);
    }

    public toggleDisplayType(): void {
        const newDisplayType =
            this.#dataDisplayTypeSubj$.value === DataDisplayType.TABLE ? DataDisplayType.CARDS : DataDisplayType.TABLE;

        this.#displayTypeLocalStorageService.saveDisplayType(DisplayTypeStorageKey.TRANSACTIONS, newDisplayType);
        this.#dataDisplayTypeSubj$.next(newDisplayType);
    }

    public updateFiltersFromLocalStorage(): void {
        const filters = this.#filtersLocalStorageService.getSavedFilters(
            FiltersStorageKey.TRANSACTIONS
        ) as TransactionsFilters;

        if (filters) {
            this.applyFilters(filters, false);
        }
    }

    public applyFilters(filters: TransactionsFilters, shouldPersist = true): void {
        this.#appliedFiltersSubj$.next(filters);

        if (shouldPersist) {
            this.#filtersLocalStorageService.saveFilters({
                key: FiltersStorageKey.TRANSACTIONS,
                payload: filters,
            } as TransactionsFiltersStorage);
        }
    }

    public exportTransactionsHistory(): void {
        this.#globalLoadingSpinnerService.startLoading();

        this.#inventoryService
            .downloadProductBookingsHistoryXlsx([ContractType.DispoHeidelberg, ContractType.DispoCustomer])
            .pipe(take(1))
            .subscribe((fileResponse) => {
                const customerNumber = this.#printShopsService.selectedPrintShopNumber;
                const fileName = `ProductMovementsHistory_${customerNumber}.xlsx`;

                this.#fileDownloaderService.download(fileResponse, fileName, MimeType.XLSX);
                this.#globalLoadingSpinnerService.stopLoading();
            });
    }

    public copyToClipboard(
        transactions: Transaction[],
        searchPhrase: string,
        filters: TransactionsFilters | undefined
    ): void {
        this.#transactionsClipboardService.copy(transactions, searchPhrase, filters);
    }

    private listenForGetTransactionsTrigger(): void {
        this.#pageVisibilityChange$
            .pipe(
                untilDestroyed(this),
                startWith(true),
                pairwise(),
                tap(
                    ([wasPageVisible, isPageVisible]) =>
                        !wasPageVisible && isPageVisible && this.triggerGetTransactions()
                ),
                switchMap(([, isPageVisible]) =>
                    interval(this.#DATA_REFRESH_INTERVAL).pipe(
                        filter(() => isPageVisible),
                        untilDestroyed(this)
                    )
                )
            )
            .subscribe(() => {
                this.triggerGetTransactions();
            });
    }

    private listenForSearchPhraseChanges(): void {
        this.searchPhrase$
            .pipe(
                tap(() => {
                    this.#isSearchPhraseLoadingSubj$.next(true);
                }),
                debounceTime(1000),
                untilDestroyed(this)
            )
            .subscribe(() => {
                this.#isSearchPhraseLoadingSubj$.next(false);
            });
    }

    private getStatusesFilterEntries(transactions: Transaction[]): TransactionStatus[] {
        const statusesFilterSortOrder: TransactionStatus[] = [
            TransactionStatus.PENDING,
            TransactionStatus.PROCESSING,
            TransactionStatus.COMPLETED,
            TransactionStatus.REJECTED,
            TransactionStatus.UNKNOWN,
        ];

        return uniqBy(transactions, (transaction) => this.#translateTransactionStatusPipe.transform(transaction.status))
            .map((transaction) => transaction.status)
            .sort((a, b) => statusesFilterSortOrder.indexOf(a) - statusesFilterSortOrder.indexOf(b));
    }

    private getMovementTypesFilterEntries(transactions: Transaction[]): MovementType[] {
        const movementTypesSortOrder: MovementType[] = [
            MovementType.PENDING_DELIVERY,
            MovementType.POSTED_CONSUMPTION,
            MovementType.POSTED_STOCK_COUNT,
            MovementType.PROCESSED_CONSUMPTION,
            MovementType.PROCESSED_STOCK_COUNT,
            MovementType.COMPLETED_DELIVERY,
            MovementType.PROCESSED_CONSUMPTION,
            MovementType.PROCESSED_STOCK_COUNT,
            MovementType.REJECTED_DELIVERY,
            MovementType.REJECTED_CONSUMPTION,
            MovementType.REJECTED_STOCK_COUNT,
            MovementType.UNKNOWN,
        ];

        return uniqBy(transactions, (transaction) =>
            this.#translateMovementTypePipe.transform(transaction.movementType)
        )
            .map((transaction) => transaction.movementType)
            .sort((a, b) => movementTypesSortOrder.indexOf(a) - movementTypesSortOrder.indexOf(b));
    }
}
