import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatRippleModule} from '@angular/material/core';

import {MatButtonModule} from '@angular/material/button';
import {isEqual} from 'lodash-es';

import {HdmuiEmptyStatesModule} from '@heidelberg/hdmui-angular';

import {EmptyPipe, LocalizedDatePipe, TakeDateOnlyPipe, TakeTimeOnlyPipe} from '@vmi/ui-presentational';
import {TableColumn} from '@vmi/shared-models';
import {MovementType, Transaction, TransactionsFilters, TransactionStatus} from '@vmi/feature-transactions';

import {ConfigurableColumns, ObligatoryColumns} from '../../models';
import {TransactionsFilterService} from '../../services/transactions-filter.service';
import {TranslateTransactionStatusPipe} from '../../pipes/translate-transaction-status.pipe';
import {TranslateMovementTypePipe} from '../../pipes/translate-movement-type.pipe';
import {getIconClass, getTransactionMovementType} from '../../functions/transaction.functions';
import {TransactionStatusIconPipe} from '../../pipes/transaction-status-icon.pipe';
import {TransactionPostedDatePipe} from '../../pipes/transaction-posted-date.pipe';
import {TransactionProcessedDatePipe} from '../../pipes/transaction-processed-date.pipe';
import {
  TranslateProductCategoryPipe
} from '../../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';
import {TransactionQuantityPipe} from '../../pipes/transaction-quantity.pipe';

@Component({
    standalone: true,
    selector: 'vmi-transactions-table',
    imports: [
        MatTableModule,
        TranslateModule,
        LocalizedDatePipe,
        HdmuiEmptyStatesModule,
        MatIconModule,
        EmptyPipe,
        MatButtonModule,
        TakeDateOnlyPipe,
        TakeTimeOnlyPipe,
        MatSortModule,
        MatRippleModule,
        TranslateMovementTypePipe,
        TranslateTransactionStatusPipe,
        TransactionStatusIconPipe,
        TransactionPostedDatePipe,
        TransactionProcessedDatePipe,
        TranslateProductCategoryPipe,
        TransactionQuantityPipe,
    ],
    templateUrl: './transactions-table.component.html',
    styleUrls: ['./transactions-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsTableComponent implements OnChanges, AfterViewInit {
    readonly #translateMovementTypePipe = new TranslateMovementTypePipe();
    readonly #translateTransactionStatusPipe = new TranslateTransactionStatusPipe();
    readonly #transactionPostedDatePipe = new TransactionPostedDatePipe();
    readonly transactionProcessedDatePipe = new TransactionProcessedDatePipe();
    readonly #translateProductCategoryPipe = new TranslateProductCategoryPipe();

    private readonly CHUNK_SIZE = 100;

    public readonly CONFIGURABLE_COLUMNS = ConfigurableColumns;
    public readonly OBLIGATORY_COLUMNS = ObligatoryColumns;

    public dataSource = new MatTableDataSource<Transaction>([]);
    public maxDisplayedTransactions = this.CHUNK_SIZE;
    public displayShowMoreButton = false;

    @ViewChild(MatSort) sort: MatSort | null = null;

    private _columns!: string[];

    @Input()
    isProductDetailsPage = false;

    @Input()
    set visibleConfigurableColumns(visibleConfigurableColumns: TableColumn[]) {
        const columnIds = visibleConfigurableColumns.map((col) => col.id);
        this._columns = [
            ObligatoryColumns.STATUS_ICON,
            ObligatoryColumns.STATUS,
            ObligatoryColumns.MOVEMENT_TYPE,
            ...columnIds,
        ];
    }

    @Input()
    transactions!: Transaction[] | undefined;

    @Input()
    searchPhrase!: string;

    @Input()
    filters!: TransactionsFilters | undefined;

    #previousTransactions: Transaction[] = [];

    constructor(
        private readonly cdRef: ChangeDetectorRef,
        private readonly transactionsFilterService: TransactionsFilterService
    ) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['transactions'] || changes['searchPhrase'] || changes['filters']) {
            const transactions: Transaction[] = changes['transactions']?.currentValue || this.transactions;
            const searchPhrase = changes['searchPhrase']?.currentValue || this.searchPhrase;
            const filters = changes['filters']?.currentValue || this.filters;

            if (transactions !== undefined) {
                if (this.shouldUpdateTableData(transactions, !!changes['filters'])) {
                    this.updateData(searchPhrase, filters, transactions);
                }
                this.#previousTransactions = transactions;
            }
        }
    }

    ngAfterViewInit(): void {
        this.sortDataSource();
    }

    public get columns(): string[] {
        return this._columns;
    }

    public getIconClass(status: TransactionStatus): string {
        return getIconClass(status);
    }

    public onShowMoreButtonClick(): void {
        if (!this.isFurtherShowMoreActionAllowed()) {
            this.displayShowMoreButton = false;
        }

        this.maxDisplayedTransactions += this.CHUNK_SIZE;
        this.updateData(this.searchPhrase, this.filters, this.transactions, false);
    }

    public isProcessedStockCount(transaction: Transaction): boolean {
        return getTransactionMovementType(transaction) === MovementType.PROCESSED_STOCK_COUNT;
    }

    private isFurtherShowMoreActionAllowed() {
        return this.transactions && this.maxDisplayedTransactions + this.CHUNK_SIZE <= this.transactions.length;
    }

    private updateData(
        searchPhrase: string,
        filters: TransactionsFilters | undefined,
        transactions: Transaction[] = [],
        resetDisplayedDeliveriesNo = true
    ): void {
        const data = this.prepareData(transactions, searchPhrase, filters);

        this.dataSource = new MatTableDataSource<Transaction>(data);
        this.sortDataSource();

        const shouldDisplayButtonWhenDataDefault =
            ((!!this.transactions && this.transactions?.length) || 0) > this.dataSource.data.length;
        const shouldDisplayButtonWhenDataFiltered = this.maxDisplayedTransactions <= this.dataSource.data.length;

        this.displayShowMoreButton =
            searchPhrase || (filters && Object.values(filters).some((filter) => filter.length))
                ? shouldDisplayButtonWhenDataFiltered
                : shouldDisplayButtonWhenDataDefault;
        this.maxDisplayedTransactions = resetDisplayedDeliveriesNo ? this.CHUNK_SIZE : this.maxDisplayedTransactions;

        setTimeout(() => {
            this.cdRef.detectChanges();
        });
    }

    private prepareData(
        transactions: Transaction[],
        searchPhrase: string,
        filters: TransactionsFilters | undefined
    ): Transaction[] {
        const searchedData = this.transactionsFilterService.applySearchPhrase(transactions, searchPhrase);
        const preparedData = this.transactionsFilterService.applyFilters(searchedData, filters);

        return preparedData?.slice(0, this.maxDisplayedTransactions);
    }

    private shouldUpdateTableData(transactions: Transaction[], filtersChange: boolean): boolean {
        return filtersChange || !isEqual(transactions, this.#previousTransactions);
    }

    private sortDataSource(): void {
        this.dataSource.sortingDataAccessor = (transaction, column) => {
            switch (column) {
                case ObligatoryColumns.STATUS: {
                    return this.#translateTransactionStatusPipe.transform(transaction.status);
                }
                case ObligatoryColumns.MOVEMENT_TYPE: {
                    return this.#translateMovementTypePipe.transform(transaction.movementType);
                }
                case ConfigurableColumns.PRODUCT: {
                    return transaction.name;
                }
                case ConfigurableColumns.QUANTITY: {
                    return transaction.amount;
                }
                case ConfigurableColumns.CATEGORY: {
                    return this.#translateProductCategoryPipe.transform(transaction.category);
                }
                case ConfigurableColumns.POSTED: {
                    return this.#transactionPostedDatePipe.transform(transaction);
                }
                case ConfigurableColumns.PROCESSED: {
                    return this.transactionProcessedDatePipe.transform(transaction);
                }
                default: {
                    if (transaction.hasOwnProperty(column)) {
                        return (transaction as any)[column];
                    }
                    return '';
                }
            }
        };

        this.dataSource.sort = this.sort;
    }
}
