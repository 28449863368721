import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class OrgTokenService {
    #orgToken?: string;

    public set orgToken(token: string) {
        this.#orgToken = token;
    }

    public get orgToken(): string | undefined {
        return this.#orgToken;
    }
}
