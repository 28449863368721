import {HttpErrorResponse} from '@angular/common/http';

export interface SanitizedError {
  message: string;
  details: string[];
}


export function sanitiseError(e: Error | HttpErrorResponse): SanitizedError {
  const sanitisedError: SanitizedError = {
    message: e.message || '(null)',
    details: []
  };

  const isHttpError = e instanceof HttpErrorResponse;

  if (!isHttpError && e.stack) {
    sanitisedError.details.push(e.stack);
  } else if (isHttpError && e.error) {
    const message = e.error.message ?? e.error;

    // ensure that message is a plain string and not stringified object in case when e.error was an object
    if (typeof message === 'string') {
      sanitisedError.message = `${e.status}: ${message}`;
    }
  } else {
    sanitisedError.details.push(JSON.stringify(e));
  }

  return sanitisedError;
}
