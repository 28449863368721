import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DateTimeFormatOptions} from 'luxon';

@Pipe({
    standalone: true,
    name: 'takeTimeOnly',
    pure: false,
})
export class TakeTimeOnlyPipe implements PipeTransform {
    readonly #translateService = inject(TranslateService);

    transform(date: Date | undefined): string | undefined {
        if (!date) {
            return undefined;
        }

        const options: DateTimeFormatOptions = {
            hour: 'numeric',
            minute: 'numeric',
        };
        return date.toLocaleTimeString(this.#translateService.currentLang, options);
    }
}
