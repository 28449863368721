export enum MovementType {
    REJECTED_STOCK_COUNT = 'rejected.stockCount',
    REJECTED_CONSUMPTION = 'rejected.consumption',
    REJECTED_DELIVERY = 'rejected.delivery',

    POSTED_STOCK_COUNT = 'posted.stockCount',
    POSTED_CONSUMPTION = 'posted.consumption',
    PENDING_DELIVERY = 'isPending',

    PROCESSED_STOCK_COUNT = 'processed.stockCount',
    PROCESSED_CONSUMPTION = 'processed.consumption',
    COMPLETED_DELIVERY = 'completed.delivery',

    UNKNOWN = 'unknown',
}
