import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

import {ProductBookingDto} from '@heidelberg/vmi-subscription-api-client';

import {WINDOW} from '@vmi/injection-tokens';
import {EmptyPipe} from '@vmi/ui-presentational';

import {
  TranslateProductCategoryPipe
} from '../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';
import {FilterConsumptionReportsPipe} from '../pipes/filter-consumption-reports.pipe';
import {ConsumptionReportFilters} from '../models/consumption-report-filters.interface';

@Injectable({
    providedIn: 'root',
})
export class ConsumptionReportClipboardService {
    readonly #window = inject(WINDOW);
    readonly #snackbar = inject(MatSnackBar);
    readonly #translateService = inject(TranslateService);

    readonly #emptyPipe = new EmptyPipe();
    readonly #translateProductCategoryPipe = new TranslateProductCategoryPipe();
    readonly #filterConsumptionReportsPipe = new FilterConsumptionReportsPipe();

    public copy(consumptionReports: ProductBookingDto[], timeRange: string, filters: ConsumptionReportFilters): void {
        const preparedData = this.#filterConsumptionReportsPipe.transform(consumptionReports, filters);

        const text = this.convertToExcelString(preparedData, timeRange);
        this.#window.navigator.clipboard.writeText(text).then(() =>
            this.#snackbar.open(this.#translateService.instant('inventory.clipboard.saved'), undefined, {
                duration: 3000,
            })
        );
    }

    private convertToExcelString(consumptionReports: ProductBookingDto[], timeRange: string): string {
        const NEW_CELL = '\t';
        const NEW_LINE = '\n';

        const headers = [
            this.#translateService.instant('inventory.products.grid.name'),
            this.#translateService.instant('inventory.products.grid.id'),
            this.#translateService.instant('inventory.productDetails.grid.amount'),
            this.#translateService.instant('inventory.products.grid.category'),
            this.#translateService.instant('inventory.consumptionReport.timeRange'),
        ].join(NEW_CELL);

        const dataToExport = consumptionReports
            .map((report) => {
                return [
                    this.#emptyPipe.transform(report.name),
                    this.#emptyPipe.transform(report.id),
                    this.#emptyPipe.transform(report.amount),
                    this.#emptyPipe.transform(this.#translateProductCategoryPipe.transform(report.category)),
                    timeRange,
                ];
            })
            .map((row) => {
                return row.join(NEW_CELL);
            })
            .join(NEW_LINE);

        return headers + NEW_LINE + dataToExport;
    }
}
