/**
 * Mapping of permissions to routes
 */

import {
  ROUTE_PATH,
  SAMPLE_PERM_ORG_SETTINGS_MANAGE,
  SAMPLE_PERM_ORG_SETTINGS_READ,
  SAMPLE_PERM_PROFILE_READ,
  SAMPLE_ROLE_ORGANIZATION_ADMIN,
  SAMPLE_ROLE_ORGANIZATION_MEMBER,
  SAMPLE_ROLE_ORGANIZATION_OWNER,
  SAMPLE_ROLE_USER,
  SampleRole,
} from '@vmi/shared-models';

import {absRoute} from '@vmi/utils';
import {Permission, Role} from '@vmi/core';

// Organization

export const PERM_ORG_SETTINGS_READ: Permission = {
    id: SAMPLE_PERM_ORG_SETTINGS_READ.id,
    translationKey: 'Organization settings read',
    routes: [
        {
            appPath: absRoute(ROUTE_PATH.dashboard),
            pagePath: absRoute(ROUTE_PATH.dashboard),
        },
        {
            appPath: absRoute(ROUTE_PATH.inventory),
            pagePath: absRoute(ROUTE_PATH.inventory),
        },
        {
            appPath: absRoute(ROUTE_PATH.deliveries),
            pagePath: absRoute(ROUTE_PATH.deliveries),
        },
        {
            appPath: absRoute(ROUTE_PATH.transactions),
            pagePath: absRoute(ROUTE_PATH.transactions),
        },
    ],
};

export const PERM_ORG_SETTINGS_MANAGE: Permission = {
    id: SAMPLE_PERM_ORG_SETTINGS_MANAGE.id,
    translationKey: 'Organization settings manage',
    routes: PERM_ORG_SETTINGS_READ.routes?.concat([]),
};

export const PERM_PROFILE_READ: Permission = {
    id: SAMPLE_PERM_PROFILE_READ.id,
    translationKey: 'Profile',
    routes: [],
};

// Service logs

export const ALL_PERMISSIONS = [PERM_ORG_SETTINGS_READ, PERM_ORG_SETTINGS_MANAGE, PERM_PROFILE_READ];

function mapRolePermissions(serverRole: SampleRole): Permission[] {
    const result = serverRole.permissions
        .map((sPermission) => ALL_PERMISSIONS.find((cPermission) => cPermission.id === sPermission.id))
        .filter((p) => !!p);
    return result as Permission[];
}

export const ROLE_USER: Role = {
    id: SAMPLE_ROLE_USER.id,
    permissions: mapRolePermissions(SAMPLE_ROLE_USER),
};

export const ROLE_ORGANIZATION_MEMBER: Role = {
    id: SAMPLE_ROLE_ORGANIZATION_MEMBER.id,
    permissions: mapRolePermissions(SAMPLE_ROLE_ORGANIZATION_MEMBER),
};

export const ROLE_ORGANIZATION_ADMIN: Role = {
    id: SAMPLE_ROLE_ORGANIZATION_ADMIN.id,
    permissions: mapRolePermissions(SAMPLE_ROLE_ORGANIZATION_ADMIN),
};

export const ROLE_ORGANIZATION_OWNER: Role = {
    id: SAMPLE_ROLE_ORGANIZATION_OWNER.id,
    permissions: mapRolePermissions(SAMPLE_ROLE_ORGANIZATION_OWNER),
};

export const ALL_ROLES = [ROLE_USER, ROLE_ORGANIZATION_MEMBER, ROLE_ORGANIZATION_ADMIN, ROLE_ORGANIZATION_OWNER];
export const ALL_ORG_ROLES = [ROLE_ORGANIZATION_MEMBER];
export const ALL_ORG_ADMIN_ROLES = [ROLE_ORGANIZATION_OWNER, ROLE_ORGANIZATION_ADMIN, ROLE_ORGANIZATION_MEMBER];

export function mapRoles(roles: SampleRole[]): Role[] {
    return roles
        ? (roles
              .map((consumerRole) => {
                  return ALL_ROLES.find((r) => r.id === consumerRole.id);
              })
              .filter((r) => !!r) as Role[])
        : [];
}
