import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Injectable, NgZone} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {TranslateService} from '@ngx-translate/core';
import {catchError, EMPTY, Observable, retry} from 'rxjs';

import {CCAuthService} from '@heidelberg/control-center-frontend-integration/auth';

import {ErrorDialogComponent, RefreshPageDialogComponent} from '@vmi/ui-presentational';

import {ErrorDisplayService} from './error-display.service';

/**
 * Global error handler, if an uncaught error occurs.
 * Shows the error in the Snackbar
 */
@Injectable()
export class GlobalErrorHandler implements HttpInterceptor {
    constructor(
        private readonly ngZone: NgZone,
        private readonly dialog: MatDialog,
        private readonly ccAuthService: CCAuthService,
        private readonly translateService: TranslateService,
        private readonly errorDisplayService: ErrorDisplayService
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(
            retry(1),
            catchError((e: HttpErrorResponse) => {
                try {
                    e = JSON.parse(e.error);
                } catch (_e) {
                    // Don't convert to json. Keep as string.
                }
                this.translateService.setDefaultLang('en');
                this.translateService.use(this.translateService.getBrowserLang() ?? 'en');
                this.ngZone.run(() => {
                    if (e.message.indexOf('ChunkLoadError') >= 0 && this.ccAuthService.isAuthenticated()) {
                        this.showReloadDialog(this.dialog);
                        return;
                    }

                    const NOT_FOUND_CODE = 404;

                    if (e.status === NOT_FOUND_CODE && e.error?.message === 'Person not found') {
                        const baseDialogRef = this.dialog.open(ErrorDialogComponent);
                        baseDialogRef.afterClosed().subscribe((_) => {
                            this.ccAuthService.localSignOut();
                        });
                    } else if (this.ccAuthService.isAuthenticated()) {
                        this.errorDisplayService.displayErrorMessage(e);
                    }
                });

                return EMPTY;
            })
        );
    }

    private showReloadDialog(dialog: MatDialog): void {
        dialog.open(RefreshPageDialogComponent);
    }
}
