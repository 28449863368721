import {inject, Pipe, PipeTransform} from '@angular/core';

import {ContractType} from '@heidelberg/vmi-subscription-api-client';
import {TranslateService} from '@ngx-translate/core';

@Pipe({
    standalone: true,
    name: 'translateContractType',
})
export class TranslateContractTypePipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);

    transform(contractType: ContractType): string {
        return this.translateService.instant(`inventory.contractType.type${contractType}`);
    }
}
