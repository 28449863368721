import {
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  inject,
  NgZone,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';

import {MatDialogRef} from '@angular/material/dialog';
import {ZXingScannerModule} from '@zxing/ngx-scanner';
import {BarcodeFormat, Result} from '@zxing/library';
import {MatSnackBar} from '@angular/material/snack-bar';
import {UntilDestroy} from '@ngneat/until-destroy';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatButtonToggleModule} from '@angular/material/button-toggle';
import {MatInputModule} from '@angular/material/input';

import {
  HdmuiBaseDialogComponent,
  HdmuiComponentsModule,
  HdmuiEmptyStatesModule,
  HdmuiIconsModule,
} from '@heidelberg/hdmui-angular';

import {LoadingIndicatorComponent} from '@vmi/ui-presentational';
import {ResizeService} from '@vmi/utils';

const OVERLAY_SIDE_SIZE = 0.8;

@UntilDestroy()
@Component({
    standalone: true,
    styleUrls: ['qr-code-scan-dialog.component.scss'],
    templateUrl: 'qr-code-scan-dialog.component.html',
    imports: [
        MatButtonModule,
        HdmuiIconsModule,
        MatIconModule,
        ZXingScannerModule,
        HdmuiEmptyStatesModule,
        TranslateModule,
        LoadingIndicatorComponent,
        HdmuiComponentsModule,
        MatButtonToggleModule,
        MatInputModule,
    ],
})
export class QrCodeScanDialogComponent implements AfterViewInit, OnDestroy {
    readonly #dialogRef = inject(MatDialogRef<QrCodeScanDialogComponent>);
    readonly #snackBar = inject(MatSnackBar);
    readonly #translateService = inject(TranslateService);
    readonly #ngZone = inject(NgZone);
    readonly #resizeService = inject(ResizeService);

    readonly #resizeCallback: (entries: ResizeObserverEntry[]) => void = (entries) => {
        this.#ngZone.run(() => {
            const entry = entries.pop();
            const width = entry?.contentRect.width || 0;
            const height = entry?.contentRect.height || 0;
            const side = Math.min(width, height) * OVERLAY_SIDE_SIZE;
            this.overlay.nativeElement.style.width = side + 'px';
            this.overlay.nativeElement.style.height = side + 'px';
        });
    };

    readonly #scannerResizeObserver: ResizeObserver = this.#resizeService.getNewResizeObserver(this.#resizeCallback);

    OPTION_NO_BUTTON = HdmuiBaseDialogComponent.OPTION_NO_BUTTON;
    devices: MediaDeviceInfo[] = [];
    selectedDevice?: MediaDeviceInfo;
    isTorchCompatible = false;
    isTorchEnabled = false;
    ALLOWED_FORMATS = [BarcodeFormat.DATA_MATRIX];
    isLoading = true;
    hasCameraPermissions!: boolean;

    @ViewChild('overlay')
    overlay!: ElementRef;

    @ViewChild('scanner')
    scanner!: ElementRef;

    @Output()
    manualSearchClick = new EventEmitter<void>();

    ngAfterViewInit(): void {
        this.#scannerResizeObserver.observe(this.scanner.nativeElement);
    }

    ngOnDestroy(): void {
        this.#scannerResizeObserver.unobserve(this.scanner.nativeElement);
    }

    public get scannerResizeObserver(): ResizeObserver {
        return this.#scannerResizeObserver;
    }

    public get flashlightIcon(): string {
        return this.isTorchEnabled ? 'hdmui:flashlightOn' : 'hdmui:flashlightOff';
    }

    public get emptyStatesTitle(): string {
        const translationKey = this.hasCameraPermissions
            ? 'inventory.qrscandialog.nocameras.title'
            : 'inventory.qrscandialog.nopermissions.title';
        return this.#translateService.instant(translationKey);
    }

    public get emptyStatesDescription(): string {
        const translationKey = this.hasCameraPermissions
            ? 'inventory.qrscandialog.nocameras.description'
            : 'inventory.qrscandialog.nopermissions.description';

        return this.#translateService.instant(translationKey, {
            buttonText: this.#translateService.instant('inventory.action.manualsearch'),
        });
    }

    public changeCamera(): void {
        const currentIndex = this.devices.findIndex((device) => device.deviceId === this.selectedDevice?.deviceId);
        const nextIndex = (currentIndex + 1) % this.devices.length;
        this.selectedDevice = this.devices[nextIndex];
    }

    public toggleFlashlight(): void {
        this.isTorchEnabled = !this.isTorchEnabled;
    }

    public onManualSearchClick(): void {
        this.manualSearchClick.emit();
        this.#dialogRef.close();
    }

    public onTorchCompatible(isTorchCompatible: boolean): void {
        this.isTorchCompatible = isTorchCompatible;
    }

    public camerasFoundHandler(devices: MediaDeviceInfo[]): void {
        this.devices = devices;
        this.isLoading = false;
    }

    public camerasNotFoundHandler(): void {
        this.isLoading = false;
    }

    public onPermissionsResponse(hasPermissions: boolean): void {
        this.hasCameraPermissions = hasPermissions;
        if (!hasPermissions) {
            this.isLoading = false;
        }
    }

    public scanSuccessHandler(): void {
        this.tryVibrate();
    }

    public scanErrorHandler(error: Error): void {
        this.#snackBar.open(
            this.#translateService.instant('inventory.qrscandialog.error', { error: error.message }),
            this.#translateService.instant('*.action.close'),
            {
                duration: 3000,
                panelClass: 'hdmui-error',
            }
        );
    }

    public scanCompleteHandler(result: Result): void {
        if (result) {
            this.#dialogRef.close(result.getText());
        }
    }

    private tryVibrate() {
        const TWO_HUNDRED = 200;
        const ONE_HUNDED = 100;

        // https://developer.mozilla.org/en-US/docs/Web/API/Vibration_API
        if (window && window.navigator && window.navigator.vibrate) {
            window.navigator.vibrate([TWO_HUNDRED, ONE_HUNDED, TWO_HUNDRED]);
        }
    }
}
