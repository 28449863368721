<mat-sidenav-container class="hdmui hdmui-m-0" [class.has-page-sub-navigation]="hasPageSubNavigation">
    <mat-sidenav class="hdmui" [class.has-page-sub-navigation]="hasPageSubNavigation" position="end">
        <div class="hdmui-container">
            <header>
                <mat-toolbar class="sidenav-toolbar">
                    <h3>{{ '*.filter' | translate }}</h3>
                    <div class="hdmui-spacer"></div>
                    <button mat-icon-button (click)="closeSidenav()">
                        <mat-icon svgIcon="hdmui:x"></mat-icon>
                    </button>
                </mat-toolbar>
            </header>
            <section class="hdmui-content">
                <div class="filter--label">
                    <h3 class="hdmui-font-bold hdmui-mx-0">{{ 'inventory.products.grid.status' | translate }}</h3>
                    <span class="hdmui-spacer"></span>
                    <mat-divider [vertical]="true"></mat-divider>
                    <button
                        mat-icon-button
                        (click)="clearSelectedStatuses()"
                        [disabled]="!isSelectedStatusesClearable()"
                    >
                        <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                    </button>
                </div>
                <div class="parameter-group">
                    @for (status of availableStatuses; track status) {
                        <mat-checkbox
                            #statusCheckbox
                            [value]="status.toString()"
                            (change)="statusCheckChange($event, status)"
                        >
                            <span>{{ status | translateTransactionStatus }}</span>
                        </mat-checkbox>
                    }
                </div>

                <div class="filter--label">
                    <h3 class="hdmui-font-bold hdmui-mx-0">{{ 'inventory.productDetails.grid.type' | translate }}</h3>
                    <span class="hdmui-spacer"></span>
                    <mat-divider [vertical]="true"></mat-divider>
                    <button
                        mat-icon-button
                        (click)="clearSelectedMovementTypes()"
                        [disabled]="!isSelectedMovementTypesClearable()"
                    >
                        <mat-icon svgIcon="hdmui:xCircled"></mat-icon>
                    </button>
                </div>
                <div class="parameter-group">
                    @for (movementType of availableMovementTypes; track movementType) {
                        <mat-checkbox
                            #movementTypeCheckbox
                            [value]="movementType.toString()"
                            (change)="movementTypeCheckChange($event, movementType)"
                        >
                            <span>{{ movementType | translateMovementType }}</span>
                        </mat-checkbox>
                    }
                </div>
            </section>

            <footer class="buttons hdmui-px-3">
                <button mat-button (click)="clearAll()" [disabled]="!isClearAllAllowed()">
                    {{ '*.clearAll' | translate }}
                </button>
                <button mat-stroked-button (click)="apply()">{{ '*.apply' | translate }}</button>
            </footer>
        </div>
    </mat-sidenav>
    <mat-sidenav-content>
        <button
            mat-icon-button
            matBadge
            [class.hdmui-badge-minor]="appliedFilters"
            [class.hdmui-badge-highlight]="appliedFilters"
            (click)="openSidenav()"
        >
            <mat-icon svgIcon="hdmui:filter"></mat-icon>
        </button>
    </mat-sidenav-content>
</mat-sidenav-container>
