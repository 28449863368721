import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
    standalone: true,
    name: 'empty',
})
export class EmptyPipe implements PipeTransform {
    transform(value: string | number | undefined | null): string | number {
        return value ? value : '—';
    }
}
