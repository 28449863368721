import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import {MatInputModule} from '@angular/material/input';
import {MatIconModule} from '@angular/material/icon';
import {FormsModule} from '@angular/forms';

import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';

@Component({
    standalone: true,
    selector: 'vmi-search-input',
    imports: [MatInputModule, MatIconModule, FormsModule, MatButtonModule, TranslateModule],
    templateUrl: './search-input.component.html',
    styleUrls: ['./search-input.component.scss'],
})
export class SearchInputComponent {
    @ViewChild('searchInput', { read: ElementRef }) input!: ElementRef<HTMLInputElement>;

    @Input()
    searchPhrase = '';

    @Input()
    qrAllowed = true;

    @Output()
    searchPhraseChange = new EventEmitter<string>();

    @Output()
    qrCodeClick = new EventEmitter<void>();

    public focus(): void {
        this.input.nativeElement.focus();
    }

    public applyInput(): void {
        this.searchPhraseChange.emit(this.searchPhrase);
    }

    public onResetClick(): void {
        this.searchPhrase = '';
        this.applyInput();
    }

    public onQrCodeClick(): void {
        this.qrCodeClick.emit();
    }
}
