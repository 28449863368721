import {Pipe, PipeTransform} from '@angular/core';

import {Transaction} from '@vmi/feature-transactions';

@Pipe({
    standalone: true,
    name: 'transactionPostedDate',
})
export class TransactionPostedDatePipe implements PipeTransform {
    transform(transaction: Transaction): Date | undefined {
        return transaction.createdAt || transaction.transactionDate;
    }
}
