import {DateTime} from 'luxon';

import {IOpenDeliveryDto, OpenDeliveryDto} from '@heidelberg/vmi-subscription-api-client';

import {DeliveryStatus, IOpenDelivery} from '@vmi/shared-models';

export function mapDtoToOpenDelivery(
    openDeliveryDto: IOpenDeliveryDto,
    originalOpenDeliveries: OpenDeliveryDto[]
): IOpenDelivery {
    const orderNumber =
        openDeliveryDto.products && openDeliveryDto.products.length && openDeliveryDto.products[0].orderNumber;

    const deliveryNumber = openDeliveryDto.externalDeliveryNumber || orderNumber || '';

    return { ...openDeliveryDto, deliveryNumber, status: getDeliveryStatus(openDeliveryDto, originalOpenDeliveries) };
}

export function getDeliveryStatus(
    delivery: IOpenDelivery | IOpenDeliveryDto,
    originalOpenDeliveries: OpenDeliveryDto[]
): DeliveryStatus {
    let status: DeliveryStatus = DeliveryStatus.OPEN;

    const hasUndeliveredProducts = delivery.products?.some(
        (product) =>
            product.confirmedQuantity === undefined ||
            product.confirmedQuantity === null ||
            (!!product.quantity && product.confirmedQuantity < product.quantity)
    );

    const hasDeliveredProducts = delivery.products?.some(
        (product) => product.confirmedQuantity !== undefined && product.confirmedQuantity !== null
    );

    if (delivery.completedAt && hasUndeliveredProducts && hasDeliveredProducts) {
        status = DeliveryStatus.PARTLY_COMPLETED;
    }

    const today = DateTime.fromJSDate(new Date());
    const deliveryDate = delivery.deliveryDate ? DateTime.fromJSDate(delivery.deliveryDate) : undefined;

    if (!delivery.completedAt && deliveryDate) {
        const thresholdDate = deliveryDate.plus({ days: 1 });

        if (today >= thresholdDate) {
            status = DeliveryStatus.OVERDUE_OPEN;
        }
    }

    if (
        delivery.completedAt &&
        !originalOpenDeliveries
            .map((originalOpenDelivery) => originalOpenDelivery.externalDeliveryNumber)
            .includes(delivery.externalDeliveryNumber)
    ) {
        status = DeliveryStatus.COMPLETED;
    }

    return status;
}

export function getIconClass(status: DeliveryStatus): string {
    switch (status) {
        case DeliveryStatus.COMPLETED:
            return 'hdmui-success';
        case DeliveryStatus.OVERDUE_OPEN:
            return 'hdmui-error';
        case DeliveryStatus.OPEN:
            return 'hdmui-warning-strong';
        case DeliveryStatus.PARTLY_COMPLETED: {
            return 'hdmui-warning';
        }
        default:
            return '';
    }
}

export function isDeliveryCompleted(delivery: IOpenDelivery): boolean {
    return delivery.status === DeliveryStatus.COMPLETED;
}
