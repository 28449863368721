@if (openTransactions.length > 0 || completedTransactions.length > 0 || unknownTransactions.length > 0) {
    @if (openTransactions.length > 0) {
        <vmi-custom-divider>{{ 'inventory.dashboard.open_transactions.title' | translate }}</vmi-custom-divider>
        <section
            class="transaction-cards__wrapper hdmui-mt-3"
            [class.hdmui-mb-4]="completedTransactions.length > 0 || unknownTransactions.length > 0"
        >
            @for (transaction of openTransactions; track transaction) {
                <vmi-transaction-card
                    [transaction]="transaction"
                    [isCompleted]="false"
                    (cardClick)="onCardClick(transaction)"
                ></vmi-transaction-card>
            }
        </section>
    }
    @if (completedTransactions.length > 0) {
        <vmi-custom-divider>{{ 'inventory.transactions.completedTransactions.title' | translate }}</vmi-custom-divider>
        <section class="transaction-cards__wrapper hdmui-mt-3" [class.hdmui-mb-4]="unknownTransactions.length > 0">
            @for (transaction of completedTransactions; track transaction) {
                <vmi-transaction-card
                    [transaction]="transaction"
                    [isCompleted]="true"
                    (cardClick)="onCardClick(transaction)"
                ></vmi-transaction-card>
            }
        </section>
    }
    @if (unknownTransactions.length > 0) {
        <vmi-custom-divider>{{ 'inventory.transactions.unknownTransactions.title' | translate }}</vmi-custom-divider>
        <section class="transaction-cards__wrapper hdmui-mt-3">
            @for (transaction of unknownTransactions; track transaction) {
                <vmi-transaction-card
                    [transaction]="transaction"
                    [isCompleted]="true"
                    (cardClick)="onCardClick(transaction)"
                ></vmi-transaction-card>
            }
        </section>
    }
    <div class="button__wrapper">
        @if (displayShowMoreButton) {
            <button class="hdmui-mt-4" mat-stroked-button (click)="onShowMoreButtonClick()">
                {{ 'inventory.action.showMore' | translate }}
            </button>
        }
    </div>
} @else {
    <hdmui-empty-states icon="hdmui:warning">
        <hdmui-empty-states-title>{{ '*.items.notAvailable' | translate }}</hdmui-empty-states-title>
        <hdmui-empty-states-description>{{
            'inventory.inventory.emptyState.description' | translate
        }}</hdmui-empty-states-description>
    </hdmui-empty-states>
}
