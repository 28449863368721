export enum ConfigurableColumns {
    POSTED = 'posted',
    PROCESSED = 'processed',
    QUANTITY = 'quantity',
    PRODUCT = 'product',
    CATEGORY = 'category',
}

export enum ObligatoryColumns {
    MOVEMENT_TYPE = 'movementType',
    STATUS_ICON = 'statusIcon',
    STATUS = 'status',
}
