<hdmui-base-dialog
    [dialogOptions]="option"
    (okClicked)="onOk()"
    [okText]="'*.action.close' | translate"
    dialogIcon="hdmui:xCircledFilled"
    [dialogTitle]="'*.error' | translate"
>
    <p class="hdmui-error">
        {{ 'cc.error.unknown-account' | translate }}
    </p>
</hdmui-base-dialog>
