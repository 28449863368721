import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {MovementType} from '@vmi/feature-transactions';

@Pipe({
    standalone: true,
    name: 'translateMovementType',
})
export class TranslateMovementTypePipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);

    transform(movementType: MovementType): string {
        if (movementType === MovementType.UNKNOWN) {
            return '—';
        }
        return this.translateService.instant(`inventory.productDetails.grid.type.${movementType}`);
    }
}
