/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var BackofficeRoleArrayInner;
(function (BackofficeRoleArrayInner) {
  BackofficeRoleArrayInner.IdEnum = {
    Viewer: 'Viewer',
    Standard: 'Standard',
    NotificationManager: 'NotificationManager'
  };
})(BackofficeRoleArrayInner || (BackofficeRoleArrayInner = {}));
var BackofficeV1PmcBoardingsIdGet200Response;
(function (BackofficeV1PmcBoardingsIdGet200Response) {
  BackofficeV1PmcBoardingsIdGet200Response.StatusEnum = {
    created: 'created',
    validated: 'validated',
    completed: 'completed',
    cancelled: 'cancelled',
    error: 'error'
  };
  BackofficeV1PmcBoardingsIdGet200Response.CancelReasonEnum = {
    USER_CANCELLED: 'USER_CANCELLED',
    ORG_MISMATCH: 'ORG_MISMATCH'
  };
})(BackofficeV1PmcBoardingsIdGet200Response || (BackofficeV1PmcBoardingsIdGet200Response = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var CCOrganization;
(function (CCOrganization) {
  CCOrganization.StatusEnum = {
    unboarded: 'unboarded',
    boarding_new: 'boarding_new',
    boarding_onHold: 'boarding_onHold',
    boarded: 'boarded',
    rejected: 'rejected'
  };
  CCOrganization.ZStatusEnum = {
    Connected: 'Connected',
    NotConnected: 'NotConnected'
  };
  CCOrganization.KindsEnum = {
    printer: 'printer',
    supplier: 'supplier',
    machine_vendor: 'machine_vendor',
    brand_owner: 'brand_owner',
    designer: 'designer',
    publisher: 'publisher',
    software_developer: 'software_developer',
    other: 'other'
  };
})(CCOrganization || (CCOrganization = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var CCOrganizationAllOfSections;
(function (CCOrganizationAllOfSections) {
  CCOrganizationAllOfSections.PrinterEnum = {
    softcover_books: 'softcover_books',
    hardcover_books: 'hardcover_books',
    labels: 'labels',
    packagings: 'packagings',
    business_cards: 'business_cards',
    flyers: 'flyers',
    leaflets: 'leaflets',
    brochures: 'brochures',
    large_formats: 'large_formats'
  };
  CCOrganizationAllOfSections.SupplierEnum = {
    sheet_substrates: 'sheet_substrates',
    web_substrates: 'web_substrates',
    ink: 'ink',
    plates: 'plates',
    foils: 'foils',
    other: 'other'
  };
  CCOrganizationAllOfSections.MachineVendorEnum = {
    sheet_fed_offset_presses: 'sheet_fed_offset_presses',
    web_fed_offset_presses: 'web_fed_offset_presses',
    sheet_fed_digital_presses: 'sheet_fed_digital_presses',
    web_fed_digital_presses: 'web_fed_digital_presses',
    cutters: 'cutters',
    binders: 'binders',
    saddle_stitchers: 'saddle_stitchers'
  };
  CCOrganizationAllOfSections.DesignerEnum = {
    self_employed: 'self_employed',
    studio: 'studio',
    inhouse: 'inhouse',
    other: 'other'
  };
  CCOrganizationAllOfSections.PublisherEnum = {
    softcover_books: 'softcover_books',
    hardcover_books: 'hardcover_books',
    brochures: 'brochures'
  };
  CCOrganizationAllOfSections.SoftwareDeveloperEnum = {
    imposing: 'imposing',
    ganging: 'ganging',
    mis: 'mis',
    reporting: 'reporting',
    web_to_print: 'web_to_print',
    logistics: 'logistics',
    warehousing: 'warehousing',
    machine_integration: 'machine_integration',
    preflight: 'preflight',
    workflow: 'workflow',
    billing: 'billing',
    accounting: 'accounting',
    production_planning: 'production_planning',
    production_scheduling: 'production_scheduling',
    production_capacity_planning: 'production_capacity_planning'
  };
})(CCOrganizationAllOfSections || (CCOrganizationAllOfSections = {}));
var CCOrganizationBusinessRelationship;
(function (CCOrganizationBusinessRelationship) {
  CCOrganizationBusinessRelationship.ZKindEnum = {
    printer: 'printer',
    supplier: 'supplier',
    manufacturer: 'manufacturer',
    software_vendor: 'software_vendor'
  };
})(CCOrganizationBusinessRelationship || (CCOrganizationBusinessRelationship = {}));
var CCOrganizationBusinessRelationshipInvitation;
(function (CCOrganizationBusinessRelationshipInvitation) {
  CCOrganizationBusinessRelationshipInvitation.ZKindEnum = {
    printer: 'printer',
    supplier: 'supplier',
    manufacturer: 'manufacturer',
    software_vendor: 'software_vendor'
  };
  CCOrganizationBusinessRelationshipInvitation.StatusEnum = {
    waiting: 'waiting',
    confirmed: 'confirmed',
    declined: 'declined'
  };
})(CCOrganizationBusinessRelationshipInvitation || (CCOrganizationBusinessRelationshipInvitation = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const CCOrganizationRelationshipKind = {
  printer_printbuyer: 'printer_printbuyer'
};

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var CCPerson;
(function (CCPerson) {
  CCPerson.StatusEnum = {
    Active: 'Active',
    Deleted: 'Deleted'
  };
  CCPerson.UnitSystemEnum = {
    metric: 'metric',
    imperial: 'imperial'
  };
})(CCPerson || (CCPerson = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const CCRoleId = {
  mdf: 'mdf',
  cfo: 'cfo',
  buc: 'buc',
  sar: 'sar',
  pur: 'pur',
  vpo: 'vpo',
  mam: 'mam',
  mao: 'mao',
  stm: 'stm',
  tra: 'tra',
  adm: 'adm',
  own: 'own',
  mem: 'mem',
  ppl: 'ppl',
  ppm: 'ppm',
  ppo: 'ppo',
  ppa: 'ppa',
  cma: 'cma',
  prm: 'prm',
  pro: 'pro',
  pra: 'pra',
  pom: 'pom',
  poo: 'poo',
  poa: 'poa',
  vwa: 'vwa'
};

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var HdmCustomer;
(function (HdmCustomer) {
  HdmCustomer.StatusEnum = {
    Active: 'Active',
    Inactive: 'Inactive'
  };
})(HdmCustomer || (HdmCustomer = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var PmcBoarding;
(function (PmcBoarding) {
  PmcBoarding.StatusEnum = {
    created: 'created',
    validated: 'validated',
    completed: 'completed',
    cancelled: 'cancelled',
    error: 'error'
  };
  PmcBoarding.CancelReasonEnum = {
    USER_CANCELLED: 'USER_CANCELLED',
    ORG_MISMATCH: 'ORG_MISMATCH'
  };
})(PmcBoarding || (PmcBoarding = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var PmcBoardingResult;
(function (PmcBoardingResult) {
  PmcBoardingResult.StatusEnum = {
    created: 'created',
    validated: 'validated',
    completed: 'completed',
    cancelled: 'cancelled',
    error: 'error'
  };
  PmcBoardingResult.CancelReasonEnum = {
    USER_CANCELLED: 'USER_CANCELLED',
    ORG_MISMATCH: 'ORG_MISMATCH'
  };
})(PmcBoardingResult || (PmcBoardingResult = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

var PscResponse;
(function (PscResponse) {
  PscResponse.CustomerTypeEnum = {
    Customer: 'Customer',
    Internal: 'Internal',
    Unknown: 'Unknown'
  };
})(PscResponse || (PscResponse = {}));
var PublicV1OauthAccessTokenPost200Response;
(function (PublicV1OauthAccessTokenPost200Response) {
  PublicV1OauthAccessTokenPost200Response.TokenTypeEnum = {
    bearer: 'bearer'
  };
})(PublicV1OauthAccessTokenPost200Response || (PublicV1OauthAccessTokenPost200Response = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PublicV1OauthAccessTokenPost200ResponseBearer;
(function (PublicV1OauthAccessTokenPost200ResponseBearer) {
  PublicV1OauthAccessTokenPost200ResponseBearer.TypeEnum = {
    Organization: 'Organization',
    Person: 'Person'
  };
})(PublicV1OauthAccessTokenPost200ResponseBearer || (PublicV1OauthAccessTokenPost200ResponseBearer = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PublicV1OauthAccessTokenPostRequest;
(function (PublicV1OauthAccessTokenPostRequest) {
  PublicV1OauthAccessTokenPostRequest.GrantTypeEnum = {
    refresh_token: 'refresh_token'
  };
})(PublicV1OauthAccessTokenPostRequest || (PublicV1OauthAccessTokenPostRequest = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
var PublicV1PersonsPersonIdContactPost200Response;
(function (PublicV1PersonsPersonIdContactPost200Response) {
  PublicV1PersonsPersonIdContactPost200Response.SentEmailStatusEnum = {
    success: 'success',
    failure: 'failure',
    offline: 'offline'
  };
})(PublicV1PersonsPersonIdContactPost200Response || (PublicV1PersonsPersonIdContactPost200Response = {}));

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

/**
 * Control Center API V1
 * This Control Center API provides endpoints for the public APIs of the Heidelberg ControlCenter. This API is intended only for internal use inside the Heidelberg company.   # Provided OAuth Scopes -----
 *
 * The version of the OpenAPI document: 0.1.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
const ZRoleId = {
  owner: 'owner',
  admin: 'admin',
  member: 'member',
  purchaser: 'purchaser',
  production_planner: 'production_planner',
  customer_service_representative: 'customer_service_representative',
  sales_representative: 'sales_representative',
  designer: 'designer',
  developer: 'developer'
};
var CCRoleKey;
(function (CCRoleKey) {
  CCRoleKey["ManagingDirectorFinance"] = "ManagingDirectorFinance";
  CCRoleKey["ChiefFinancialOfficer"] = "ChiefFinancialOfficer";
  CCRoleKey["BusinessController"] = "BusinessController";
  CCRoleKey["SalesRepresentative"] = "SalesRepresentative";
  CCRoleKey["Purchaser"] = "Purchaser";
  CCRoleKey["VicePresidentOperations"] = "VicePresidentOperations";
  CCRoleKey["MaintenanceManager"] = "MaintenanceManager";
  CCRoleKey["MaintenanceOperator"] = "MaintenanceOperator";
  CCRoleKey["StoreMan"] = "StoreMan";
  CCRoleKey["Trainee"] = "Trainee";
  CCRoleKey["Administrator"] = "Administrator";
  CCRoleKey["Owner"] = "Owner";
  CCRoleKey["Member"] = "Member";
  CCRoleKey["ProductionPlanner"] = "ProductionPlanner";
  CCRoleKey["PrepressManager"] = "PrepressManager";
  CCRoleKey["PrepressOperator"] = "PrepressOperator";
  CCRoleKey["PrepressAssistant"] = "PrepressAssistant";
  CCRoleKey["ColorManager"] = "ColorManager";
  CCRoleKey["PressroomManager"] = "PressroomManager";
  CCRoleKey["PressOperator"] = "PressOperator";
  CCRoleKey["PressAssistent"] = "PressAssistent";
  CCRoleKey["PostpressManager"] = "PostpressManager";
  CCRoleKey["PostpressOperator"] = "PostpressOperator";
  CCRoleKey["PostpressAssistent"] = "PostpressAssistent";
})(CCRoleKey || (CCRoleKey = {}));

/*
 * Public API Surface of control-center-frontend-integration/cc
 */

/**
 * Generated bundle index. Do not edit.
 */

export { BackofficeRoleArrayInner, BackofficeV1PmcBoardingsIdGet200Response, CCOrganization, CCOrganizationAllOfSections, CCOrganizationBusinessRelationship, CCOrganizationBusinessRelationshipInvitation, CCOrganizationRelationshipKind, CCPerson, CCRoleId, CCRoleKey, HdmCustomer, PmcBoarding, PmcBoardingResult, PscResponse, PublicV1OauthAccessTokenPost200Response, PublicV1OauthAccessTokenPost200ResponseBearer, PublicV1OauthAccessTokenPostRequest, PublicV1PersonsPersonIdContactPost200Response, ZRoleId };
