import {Component} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';

@Component({
    standalone: true,
    imports: [MatDividerModule],
    selector: 'vmi-custom-divider',
    templateUrl: './custom-divider.component.html',
    styleUrls: ['./custom-divider.component.scss'],
})
export class CustomDividerComponent {}
