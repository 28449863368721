import {Pipe, PipeTransform} from '@angular/core';

import {TransactionStatus} from '@vmi/feature-transactions';

@Pipe({
    standalone: true,
    name: 'transactionStatusIcon',
})
export class TransactionStatusIconPipe implements PipeTransform {
    transform(status: TransactionStatus): string {
        switch (status) {
            case TransactionStatus.COMPLETED:
                return 'hdmui:hookCircled';
            case TransactionStatus.PROCESSING:
            case TransactionStatus.PENDING:
                return 'hdmui:clock';
            case TransactionStatus.REJECTED: {
                return 'hdmui:stopSign';
            }
            case TransactionStatus.UNKNOWN: {
                return 'hdmui:minusCircled';
            }
            default:
                return '';
        }
    }
}
