import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

import { DialogScrollComponent } from '../scroll-dialog/dialog-scroll.component';

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, DialogScrollComponent, TranslateModule],
    selector: 'vmi-delete-dialog',
    templateUrl: './delete-dialog.component.html',
})
export class DeleteDialogComponent {
    option = HdmuiBaseDialogComponent.OPTION_CONFIRM_CANCEL;
    value = '';

    constructor(public dialogRef: MatDialogRef<DeleteDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: string) {
        this.value = data;
    }

    onOk(): void {
        this.dialogRef.close(true);
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
