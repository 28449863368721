<hdmui-base-dialog
    [dialogOptions]="option"
    (okClicked)="onOk()"
    [okText]="'*.action.reloadPage' | translate"
    [cancelText]="'*.action.cancel' | translate"
    dialogIcon="hdmui:xCircledFilled"
    (cancelClicked)="onCancel()"
    [dialogTitle]="'*.error' | translate"
>
    <p>
        {{ 'cc.error.pageUpdatedPleaseRefresh' | translate }}
    </p>
</hdmui-base-dialog>
