import {Component} from '@angular/core';
import {TranslateModule, TranslateService} from '@ngx-translate/core';

import {HdmuiComponentsModule, HDMUIContactFormParams, HDMUISendStatusType} from '@heidelberg/hdmui-angular';

import {ContactFormService} from '@vmi/core';
import {ContactParams} from '@vmi/shared-models';

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule],
    selector: 'vmi-contact-form-dialog',
    template: `
        <hdmui-contact-form
            [contactTopics]="translatedTopics"
            (sendClick)="onSend($event)"
            [sendStatus]="sendStatus"
            [dialogTitleText]="'*.contact' | translate"
            [closeButtonText]="'*.action.close' | translate"
            [sendButtonText]="'*.action.send' | translate"
            [cancelButtonText]="'*.action.cancel' | translate"
            [topicText]="'*.contact_form.subject' | translate"
            [massageText]="'*.message' | translate"
            [requieredErrorText]="'*.required' | translate"
            [maxLengthText]="'*.err.max.length' | translate"
            [submittingSuccessText]="'*.mail.contact.success' | translate"
            [submittingOfflineText]="'*.mail.contact.offline' | translate"
            [submittingFailureText]="'*.mail.contact.failure' | translate"
            [submittingSendingText]="'*.mail.contact.sending' | translate"
        ></hdmui-contact-form>
    `,
})
export class ContactFormDialogComponent {
    translatedTopics: string[] = [
        this.translationService.instant('*.contact_form.topic.general_questions'),
        this.translationService.instant('*.contact_form.topic.h_plus_apps'),
        this.translationService.instant('*.contact_form.topic.prinect'),
        this.translationService.instant('*.contact_form.topic.feedback'),
    ];
    sendStatus = HDMUISendStatusType.init;

    constructor(private readonly translationService: TranslateService, private readonly contactFormService: ContactFormService) {}

    onSend(eventParams: HDMUIContactFormParams) {
        const contactFormParams: ContactParams = {
            topic: eventParams.topic ? eventParams.topic : '',
            message: eventParams.message ? eventParams.message : '',
        };

        this.contactFormService.sendIssue(contactFormParams).subscribe((status) => {
            this.sendStatus = status as HDMUISendStatusType;
        });
    }
}
