@if (state$ | async; as state) {
    <div class="consumptionReport__wrapper">
        <div class="consumptionReport__header hdmui-mb-4">
            <div class="consumptionReport__header--toolbar">
                <mat-form-field>
                    <mat-label>{{ 'inventory.consumptionReport.timeRange' | translate }}</mat-label>
                    <mat-select [required]="true" [value]="state.timeRange" (valueChange)="onTimeRangeChange($event)">
                        @for (timeRange of timeRanges; track timeRange) {
                            <mat-option [value]="timeRange">{{
                                'inventory.consumptionReport.timeRange.' + timeRange | translate
                            }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
                @if (state.timeRange === ConsumptionReportTimeRange.CUSTOM) {
                    <mat-form-field>
                        <mat-label>{{ 'inventory.consumptionReport.timeRange.custom' | translate }}</mat-label>
                        <mat-date-range-input
                            [required]="true"
                            [formGroup]="rangeForm"
                            [rangePicker]="rangePicker"
                            [min]="minDate"
                            [max]="maxDate"
                        >
                            <input formControlName="from" matStartDate [placeholder]="'*.from' | translate" />
                            <input formControlName="to" matEndDate [placeholder]="'*.to' | translate" />
                        </mat-date-range-input>
                        <mat-datepicker-toggle [for]="rangePicker" matSuffix>
                            <mat-icon matDatepickerToggleIcon svgIcon="hdmui:calendar"></mat-icon>
                        </mat-datepicker-toggle>
                        <mat-date-range-picker #rangePicker>
                            <mat-datepicker-actions>
                                <button mat-button matDatepickerCancel>{{ '*.action.cancel' | translate }}</button>
                                <button mat-button matDatepickerApply>{{ '*.action.confirm' | translate }}</button>
                            </mat-datepicker-actions>
                        </mat-date-range-picker>
                        @if (
                            rangeForm.controls.from.hasError('matStartDateInvalid') ||
                            rangeForm.controls.to.hasError('matStartDateInvalid') ||
                            rangeForm.controls.from.hasError('matDatepickerParse') ||
                            rangeForm.controls.to.hasError('matDatepickerParse')
                        ) {
                            <mat-error>{{
                                'inventory.consumptionReport.timeRange.custom.error.invalid' | translate
                            }}</mat-error>
                        }
                        @if (
                            rangeForm.controls.from.hasError('matDatepickerMax') ||
                            rangeForm.controls.to.hasError('matDatepickerMax') ||
                            rangeForm.controls.from.hasError('matDatepickerMin') ||
                            rangeForm.controls.to.hasError('matDatepickerMin')
                        ) {
                            <mat-error>
                                {{
                                    'inventory.consumptionReport.timeRange.custom.error.range'
                                        | translate
                                            : { minDate: minDate | localizedDate, maxDate: maxDate | localizedDate }
                                }}
                            </mat-error>
                        }
                    </mat-form-field>
                }
                <mat-form-field>
                    <mat-label>{{ 'inventory.products.grid.category' | translate }}</mat-label>
                    <mat-select [value]="state.category" (valueChange)="onCategoryChange($event)">
                        <mat-option>{{ 'inventory.consumptionReport.category.all' | translate }}</mat-option>
                        @for (category of state.availableCategories; track category) {
                            <mat-option [value]="category">{{ category | translateProductCategory }}</mat-option>
                        }
                    </mat-select>
                </mat-form-field>
            </div>
            @if (state.isTabletUp) {
                <div class="consumptionReport__header--toolbar">
                    <vmi-table-appearance-customizer
                        [title]="'inventory.consumptionReport.customizer.title' | translate"
                        [elements]="state.customizerConfigElements"
                        [displayBadge]="state.shouldDisplayAppearanceCustomizerBadge"
                        [defaultElements]="customizerDefaultElements"
                        (configElementsChange)="onCustomizerConfigElementsChange($event)"
                    ></vmi-table-appearance-customizer>
                    @if (
                        state.timeRange
                            | consumptionReportTimeRange: rangeForm.get('from')?.value : rangeForm.get('to')?.value;
                        as timeRange
                    ) {
                        <button
                            mat-icon-button
                            (click)="
                                onCopyToClipboardClick(state.aggregatedReports, timeRange, {
                                    category: state.category,
                                })
                            "
                        >
                            <mat-icon svgIcon="hdmui:rteCopy"></mat-icon>
                        </button>
                    }
                </div>
            }
        </div>
        @if (state.reportsMetadata.isLoading) {
            <vmi-loading-indicator></vmi-loading-indicator>
        } @else {
            <vmi-consumption-report-table
                [reports]="state.aggregatedReports | filterConsumptionReports: { category: state.category }"
                [visibleConfigurableColumns]="state.visibleColumns"
                [timeRange]="
                    state.timeRange
                        | consumptionReportTimeRange: rangeForm.get('from')?.value : rangeForm.get('to')?.value
                "
            ></vmi-consumption-report-table>
        }
    </div>
}
