<hdmui-appearance-customizer
    [class.hdmui-badge-minor]="displayBadge && !disabled"
    [class.hdmui-badge-highlight]="displayBadge && !disabled"
    matBadge
    [title]="title"
    [info]="'*.appearanceCustomizer.info' | translate"
    [confirm]="'*.apply' | translate"
    [disable]="disabled"
    [entry]="entry"
    [counter]="counter"
    [reset]="reset"
    [validation]="validation"
    [elements]="elements"
    (elementsChange)="onElementsChangeEvent($event)"
/>
