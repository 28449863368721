import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  standalone: true,
  name: 'translateProductUnit',
})
export class TranslateProductUnitPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  transform(isoCode: string | undefined): string | undefined {
    return isoCode ? this.translateService.instant(`common.units.isocode.${isoCode}`) : undefined;
  }
}
