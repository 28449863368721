import {inject, Injectable} from '@angular/core';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';

import {QrCodeScanDialogComponent} from '@vmi/ui-smart';

@Injectable({
    providedIn: 'root',
})
export class QrScanDialogService {
    readonly #dialog = inject(MatDialog);

    public openQrScanDialog(): MatDialogRef<QrCodeScanDialogComponent> {
        return this.#dialog.open<QrCodeScanDialogComponent>(QrCodeScanDialogComponent, {
            disableClose: true,
            maxHeight: '90vh',
            height: '100%',
        });
    }
}
