import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule} from '@ngx-translate/core';
import {MatCardModule} from '@angular/material/card';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatIconModule} from '@angular/material/icon';

import {HdmuiComponentsModule} from '@heidelberg/hdmui-angular';

import {EmptyPipe, LocalizedDatePipe} from '@vmi/ui-presentational';
import {MovementType, Transaction, TransactionStatus} from '@vmi/feature-transactions';

import {TransactionStatusIconPipe} from '../../pipes/transaction-status-icon.pipe';
import {TranslateTransactionStatusPipe} from '../../pipes/translate-transaction-status.pipe';
import {TranslateMovementTypePipe} from '../../pipes/translate-movement-type.pipe';
import {getIconClass, getTransactionMovementType} from '../../functions/transaction.functions';
import {TransactionProcessedDatePipe} from '../../pipes/transaction-processed-date.pipe';
import {TransactionPostedDatePipe} from '../../pipes/transaction-posted-date.pipe';
import {TransactionQuantityPipe} from '../../pipes/transaction-quantity.pipe';
import {
  TranslateProductCategoryPipe
} from '../../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';

@Component({
    standalone: true,
    selector: 'vmi-transaction-card',
    styleUrls: ['./transaction-card.component.scss'],
    templateUrl: './transaction-card.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        HdmuiComponentsModule,
        CommonModule,
        LocalizedDatePipe,
        TranslateModule,
        EmptyPipe,
        MatCardModule,
        MatTooltipModule,
        MatIconModule,
        TransactionStatusIconPipe,
        TranslateTransactionStatusPipe,
        TranslateMovementTypePipe,
        TransactionProcessedDatePipe,
        TransactionPostedDatePipe,
        TransactionQuantityPipe,
        TranslateProductCategoryPipe,
    ],
})
export class TransactionCardComponent {
    public attrOrder: string[] = [];

    @Input()
    transaction!: Transaction;

    @Input()
    set isCompleted(isCompleted: boolean) {
        this.attrOrder = isCompleted
            ? ['status', 'movementType', 'category', 'quantity', 'posted', 'processed']
            : ['status', 'movementType', 'category', 'quantity', 'posted'];
    }

    public getIconClass(status: TransactionStatus): string {
        return getIconClass(status);
    }

    public isProcessedStockCount(transaction: Transaction): boolean {
        return getTransactionMovementType(transaction) === MovementType.PROCESSED_STOCK_COUNT;
    }
}
