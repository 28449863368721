@if (state$ | async; as state) {
    <div class="transactions__wrapper">
        <div class="transactions__header hdmui-mb-4">
            <button
                mat-flat-button
                class="hdmui-button-big hdmui-m-0 hdmui-me-3"
                [routerLink]="'./' + consumptionReportPath"
                [matTooltip]="'inventory.consumptionReport' | translate"
            >
                <mat-icon svgIcon="hdmui:receivingGoodsChecked"></mat-icon>
                @if (state.isTabletUp) {
                    {{ 'inventory.consumptionReport' | translate }}
                }
            </button>

            <div class="transactions__header--toolbar">
                <vmi-search-input
                    [qrAllowed]="false"
                    [searchPhrase]="state.searchPhrase"
                    (searchPhraseChange)="onSearchPhraseChange($event)"
                    (qrCodeClick)="openQrCodeDialogForSearch()"
                ></vmi-search-input>
                <vmi-transactions-filters
                    class="hdmui-ms-3"
                    [availableStatuses]="state.availableFilters.statuses"
                    [availableMovementTypes]="state.availableFilters.movementTypes"
                    [appliedFilters]="state.appliedFilters"
                    (applyClick)="applyFilters($event)"
                ></vmi-transactions-filters>
                @if (state.isTabletUp) {
                    <vmi-table-appearance-customizer
                        [disabled]="!isDisplayTypeTable(state.dataDisplayType)"
                        [title]="'inventory.transactions.customizer.title' | translate"
                        [elements]="customizerConfigElements"
                        [defaultElements]="defaultCustomizerConfigElements"
                        [displayBadge]="shouldDisplayAppearanceCustomizerBadge"
                        (configElementsChange)="onCustomizerConfigElementsChange($event)"
                    ></vmi-table-appearance-customizer>
                }
                @if (state.isTabletUp) {
                    <button mat-icon-button (click)="toggleDisplayType()">
                        <mat-icon
                            [svgIcon]="isDisplayTypeTable(state.dataDisplayType) ? 'hdmui:tiles' : 'hdmui:rteTable'"
                        ></mat-icon>
                    </button>
                    <button
                        mat-icon-button
                        (click)="
                            onCopyToClipboardClick(
                                state.transactionsMetadata.data || [],
                                state.searchPhrase,
                                state.appliedFilters
                            )
                        "
                    >
                        <mat-icon svgIcon="hdmui:rteCopy"></mat-icon>
                    </button>
                }
            </div>
        </div>
        @if (state.transactionsMetadata.isLoading || state.isSearchPhraseLoading) {
            <vmi-loading-indicator></vmi-loading-indicator>
        } @else {
            @if (isDisplayTypeTable(state.dataDisplayType) && state.isTabletUp) {
                <vmi-transactions-table
                    [searchPhrase]="state.searchPhrase"
                    [transactions]="state.transactionsMetadata.data | filterOutFutureTransactions"
                    [visibleConfigurableColumns]="visibleColumns"
                    [filters]="state.appliedFilters"
                ></vmi-transactions-table>
            } @else {
                <vmi-transaction-cards
                    [searchPhrase]="state.searchPhrase"
                    [transactions]="state.transactionsMetadata.data | filterOutFutureTransactions"
                    [filters]="state.appliedFilters"
                ></vmi-transaction-cards>
            }
        }
    </div>
}
