import {inject, Injectable} from '@angular/core';

import {HdmuiAppearanceCustomizerElement} from '@heidelberg/hdmui-angular';

import {LOCAL_STORAGE} from '@vmi/injection-tokens';

export type AppearanceCustomizerConfigStorageElement = Pick<HdmuiAppearanceCustomizerElement, 'id' | 'visible'>;

export enum AppearanceCustomizerStorageKey {
    INVENTORY = 'inventory-customizer-config-elements',
    STOCK_COUNTING = 'stock-counting-customizer-config-elements',
    DELIVERIES = 'deliveries-customizer-config-elements',
    TRANSACTIONS = 'transactions-customizer-config-elements',
    CONSUMPTION_REPORT = 'consumption-report-customizer-config-elements',
}

@Injectable({
    providedIn: 'root',
})
export class AppearanceCustomizerStorageService {
    private readonly localStorage = inject(LOCAL_STORAGE);

    public getSavedCustomizerConfigElements(
        key: AppearanceCustomizerStorageKey
    ): AppearanceCustomizerConfigStorageElement[] | undefined {
        const customizedElements = this.localStorage.getItem(key);
        return customizedElements ? JSON.parse(customizedElements) : undefined;
    }

    public saveCustomizerConfigElements(
        key: AppearanceCustomizerStorageKey,
        customizedElements: AppearanceCustomizerConfigStorageElement[]
    ): void {
        this.localStorage.setItem(key, JSON.stringify(customizedElements));
    }
}
