import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule],
    selector: 'vmi-refresh-page-dialog',
    templateUrl: './refresh-page-dialog.component.html',
})
export class RefreshPageDialogComponent {
    option = HdmuiBaseDialogComponent.OPTION_OK_CANCEL;

    constructor(public dialogRef: MatDialogRef<RefreshPageDialogComponent>) {}

    onOk() {
        window.location.reload();
    }

    onCancel(): void {
        this.dialogRef.close();
    }
}
