import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DateTime} from 'luxon';

import {LocalizedDatePipe} from '@vmi/ui-presentational';

import {ConsumptionReportTimeRange} from '../models/consumption-report-time-range.enum';

@Pipe({
    standalone: true,
    name: 'consumptionReportTimeRange',
})
export class ConsumptionReportTimeRangePipe implements PipeTransform {
    readonly #translateService = inject(TranslateService);
    readonly #localizedDatePipe = new LocalizedDatePipe(this.#translateService);

    transform(
        timeRange: ConsumptionReportTimeRange,
        customFromDate: Date | undefined,
        customToDate: Date | undefined
    ): string {
        let dateFrom: Date | undefined;
        let dateTo: Date | undefined;
        let month: Date | undefined;

        const todayDateTime = DateTime.fromJSDate(new Date(), { zone: 'utc' });

        switch (timeRange) {
            case ConsumptionReportTimeRange.THIS_WEEK:
                dateFrom = todayDateTime.startOf('week').toJSDate();
                dateTo = todayDateTime.endOf('week').toJSDate();
                break;
            case ConsumptionReportTimeRange.LAST_WEEK:
                dateFrom = todayDateTime.minus({ week: 1 }).startOf('week').toJSDate();
                dateTo = todayDateTime.minus({ week: 1 }).endOf('week').toJSDate();
                break;
            case ConsumptionReportTimeRange.THIS_MONTH:
                month = todayDateTime.startOf('month').toJSDate();
                break;
            case ConsumptionReportTimeRange.LAST_MONTH:
                month = todayDateTime.minus({ month: 1 }).startOf('month').toJSDate();
                break;
            case ConsumptionReportTimeRange.CUSTOM:
                dateFrom = customFromDate;
                dateTo = customToDate;
                break;
        }

        const localizedDateFrom = this.#localizedDatePipe.transform(dateFrom);
        const localizedDateTo = this.#localizedDatePipe.transform(dateTo);
        const localizedMonth = month?.toLocaleDateString(this.#translateService.currentLang, {
            year: 'numeric',
            month: 'long',
        });
        return localizedMonth ? localizedMonth : `${localizedDateFrom} - ${localizedDateTo}`;
    }
}
