import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";

@Pipe({
  standalone: true,
  name: 'translateProductCategory',
})
export class TranslateProductCategoryPipe implements PipeTransform {
  private readonly translateService = inject(TranslateService);

  transform(category: string | undefined): string | undefined {
    return category ? this.translateService.instant(`inventory.products.grid.category.${category}`) : undefined;
  }
}
