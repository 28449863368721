export enum ROUTE_PATH {
    dashboard = 'dashboard',
    inventory = 'inventory',
    stockCounting = 'stock-counting',
    productDetails = 'product-details',
    deliveries = 'deliveries',
    deliveryProcessing = 'delivery-processing',
    transactions = 'transactions',
    consumptionReport = 'consumption-report',
    accessDenied = 'access-denied',
}

export enum ROUTE_PARAMS {
    orgId = 'orgId',
    productId = 'productId',
    deliveryNumber = 'deliveryNumber',
}
