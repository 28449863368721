import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class GlobalLoadingSpinnerService {
    readonly #isLoadingSubj$ = new BehaviorSubject<boolean>(false);
    public readonly isLoading$ = this.#isLoadingSubj$.asObservable();

    public startLoading(): void {
        this.#isLoadingSubj$.next(true);
    }

    public stopLoading(): void {
        this.#isLoadingSubj$.next(false);
    }
}
