import * as i0 from '@angular/core';
import { InjectionToken, Injectable, Inject, Optional, NgModule } from '@angular/core';
import * as i1 from '@angular/common/http';
import { HttpParams, HttpHeaders, HttpResponseBase, HttpResponse, HttpClient } from '@angular/common/http';
import { from, throwError, of, Observable } from 'rxjs';
import { mergeMap, catchError } from 'rxjs/operators';

//----------------------
const API_BASE_URL = new InjectionToken('API_BASE_URL');
class ClientConfiguration {}
class BaseClient {
  constructor(config) {
    this.config = config;
    this.httpMetaData = {};
  }
  setHttpMetaData(key, obj) {
    this.httpMetaData[key] = obj;
  }
  removeHttpMetaData(key) {
    delete this.httpMetaData[key];
  }
  getBaseUrl(ignored) {
    return this.config.baseUrl;
  }
  transformOptions(options) {
    const promisedOptions = (async () => {
      if (this.config.withCredentials) {
        options.withCredentials = true;
      }
      if (this.config.token) {
        const token = typeof this.config.token === 'string' ? this.config.token : await this.config.token();
        options.headers = options.headers.set('Authorization', 'Bearer ' + token);
      }
      const httpMetaDataKeys = Object.keys(this.httpMetaData);
      if (httpMetaDataKeys.length) {
        const params = withMetaData(options.params || new HttpParams());
        httpMetaDataKeys.forEach(x => params.meta.add(this.httpMetaData[x]));
        options.params = params;
      }
      return options;
    })();
    return from(promisedOptions);
  }
}
class InternalContractsClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  getContractsByCustomers(customerNumbers, activeOnly) {
    let url_ = this.baseUrl + "/internal/contracts/query/byCustomers?";
    if (activeOnly === null) throw new Error("The parameter 'activeOnly' cannot be null.");else if (activeOnly !== undefined) url_ += "activeOnly=" + encodeURIComponent("" + activeOnly) + "&";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(customerNumbers);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetContractsByCustomers(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetContractsByCustomers(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetContractsByCustomers(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getContractsByEquipments(identifiers, activeOnly) {
    let url_ = this.baseUrl + "/internal/contracts/query/byEquipments?";
    if (activeOnly === null) throw new Error("The parameter 'activeOnly' cannot be null.");else if (activeOnly !== undefined) url_ += "activeOnly=" + encodeURIComponent("" + activeOnly) + "&";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(identifiers);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetContractsByEquipments(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetContractsByEquipments(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetContractsByEquipments(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
InternalContractsClient.ɵfac = function InternalContractsClient_Factory(t) {
  return new (t || InternalContractsClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
InternalContractsClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalContractsClient,
  factory: InternalContractsClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalContractsClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class InternalEquipmentsClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  getEquipmentsByNumbers(identifiers) {
    let url_ = this.baseUrl + "/internal/equipments/query/byNumbers";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(identifiers);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetEquipmentsByNumbers(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetEquipmentsByNumbers(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetEquipmentsByNumbers(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getEquipmentsByCustomers(customerNumbers) {
    let url_ = this.baseUrl + "/internal/equipments/query/byCustomers";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(customerNumbers);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetEquipmentsByCustomers(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetEquipmentsByCustomers(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetEquipmentsByCustomers(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
InternalEquipmentsClient.ɵfac = function InternalEquipmentsClient_Factory(t) {
  return new (t || InternalEquipmentsClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
InternalEquipmentsClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: InternalEquipmentsClient,
  factory: InternalEquipmentsClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(InternalEquipmentsClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class ConsumerContractsClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  getContracts(request) {
    let url_ = this.baseUrl + "/consumer/contracts/query";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(request);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetContracts(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetContracts(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetContracts(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getContract(contractNumber, contractItem, equipmentNumber) {
    let url_ = this.baseUrl + "/consumer/contracts/{contractNumber}/{contractItem}?";
    if (contractNumber === undefined || contractNumber === null) throw new Error("The parameter 'contractNumber' must be defined.");
    url_ = url_.replace("{contractNumber}", encodeURIComponent("" + contractNumber));
    if (contractItem === undefined || contractItem === null) throw new Error("The parameter 'contractItem' must be defined.");
    url_ = url_.replace("{contractItem}", encodeURIComponent("" + contractItem));
    if (equipmentNumber !== undefined && equipmentNumber !== null) url_ += "equipmentNumber=" + encodeURIComponent("" + equipmentNumber) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetContract(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetContract(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetContract(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
ConsumerContractsClient.ɵfac = function ConsumerContractsClient_Factory(t) {
  return new (t || ConsumerContractsClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
ConsumerContractsClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ConsumerContractsClient,
  factory: ConsumerContractsClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConsumerContractsClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class ConsumerEquipmentsClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  queryEquipments(request) {
    let url_ = this.baseUrl + "/consumer/equipments/query";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(request);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processQueryEquipments(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processQueryEquipments(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processQueryEquipments(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getEquipmentByEquipmentNumber(equipmentNumber) {
    let url_ = this.baseUrl + "/consumer/equipments/{equipmentNumber}";
    if (equipmentNumber === undefined || equipmentNumber === null) throw new Error("The parameter 'equipmentNumber' must be defined.");
    url_ = url_.replace("{equipmentNumber}", encodeURIComponent("" + equipmentNumber));
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetEquipmentByEquipmentNumber(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetEquipmentByEquipmentNumber(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetEquipmentByEquipmentNumber(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  updateEquipment(equipmentNumber, request) {
    let url_ = this.baseUrl + "/consumer/equipments/{equipmentNumber}";
    if (equipmentNumber === undefined || equipmentNumber === null) throw new Error("The parameter 'equipmentNumber' must be defined.");
    url_ = url_.replace("{equipmentNumber}", encodeURIComponent("" + equipmentNumber));
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(request);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("put", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processUpdateEquipment(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processUpdateEquipment(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processUpdateEquipment(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return of(null);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getEquipmentProductFeaturesByEquipmentNumber(equipmentNumber, locale) {
    let url_ = this.baseUrl + "/consumer/equipments/{equipmentNumber}/productFeatures?";
    if (equipmentNumber === undefined || equipmentNumber === null) throw new Error("The parameter 'equipmentNumber' must be defined.");
    url_ = url_.replace("{equipmentNumber}", encodeURIComponent("" + equipmentNumber));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetEquipmentProductFeaturesByEquipmentNumber(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetEquipmentProductFeaturesByEquipmentNumber(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetEquipmentProductFeaturesByEquipmentNumber(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getManualsByEquipmentNumber(equipmentNumber, locale) {
    let url_ = this.baseUrl + "/consumer/equipments/{equipmentNumber}/manuals?";
    if (equipmentNumber === undefined || equipmentNumber === null) throw new Error("The parameter 'equipmentNumber' must be defined.");
    url_ = url_.replace("{equipmentNumber}", encodeURIComponent("" + equipmentNumber));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetManualsByEquipmentNumber(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetManualsByEquipmentNumber(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetManualsByEquipmentNumber(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getManualDownloadUrl(equipmentNumber, manualName, locale) {
    let url_ = this.baseUrl + "/consumer/equipments/{equipmentNumber}/manuals/{manualName}/downloadUrl?";
    if (equipmentNumber === undefined || equipmentNumber === null) throw new Error("The parameter 'equipmentNumber' must be defined.");
    url_ = url_.replace("{equipmentNumber}", encodeURIComponent("" + equipmentNumber));
    if (manualName === undefined || manualName === null) throw new Error("The parameter 'manualName' must be defined.");
    url_ = url_.replace("{manualName}", encodeURIComponent("" + manualName));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetManualDownloadUrl(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetManualDownloadUrl(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetManualDownloadUrl(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
ConsumerEquipmentsClient.ɵfac = function ConsumerEquipmentsClient_Factory(t) {
  return new (t || ConsumerEquipmentsClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
ConsumerEquipmentsClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ConsumerEquipmentsClient,
  factory: ConsumerEquipmentsClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConsumerEquipmentsClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class ConsumerInvoicesClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  getInvoices(locale) {
    let url_ = this.baseUrl + "/consumer/invoices?";
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetInvoices(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetInvoices(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetInvoices(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  downloadInvoicePdf(invoiceNumber) {
    let url_ = this.baseUrl + "/consumer/invoices/{invoiceNumber}/pdf";
    if (invoiceNumber === undefined || invoiceNumber === null) throw new Error("The parameter 'invoiceNumber' must be defined.");
    url_ = url_.replace("{invoiceNumber}", encodeURIComponent("" + invoiceNumber));
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/octet-stream"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processDownloadInvoicePdf(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processDownloadInvoicePdf(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processDownloadInvoicePdf(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200 || status === 206) {
      const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
      const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
      const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
      return of({
        fileName: fileName,
        data: responseBlob,
        status: status,
        headers: _headers
      });
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getMonthlyInvoices(locale) {
    let url_ = this.baseUrl + "/consumer/invoices/monthlyInvoices?";
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetMonthlyInvoices(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetMonthlyInvoices(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetMonthlyInvoices(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  downloadMonthlyInvoicePdf(invoiceListNumber) {
    let url_ = this.baseUrl + "/consumer/invoices/monthlyInvoices/{invoiceListNumber}/pdf";
    if (invoiceListNumber === undefined || invoiceListNumber === null) throw new Error("The parameter 'invoiceListNumber' must be defined.");
    url_ = url_.replace("{invoiceListNumber}", encodeURIComponent("" + invoiceListNumber));
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/octet-stream"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processDownloadMonthlyInvoicePdf(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processDownloadMonthlyInvoicePdf(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processDownloadMonthlyInvoicePdf(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200 || status === 206) {
      const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
      const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
      const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
      return of({
        fileName: fileName,
        data: responseBlob,
        status: status,
        headers: _headers
      });
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
ConsumerInvoicesClient.ɵfac = function ConsumerInvoicesClient_Factory(t) {
  return new (t || ConsumerInvoicesClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
ConsumerInvoicesClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: ConsumerInvoicesClient,
  factory: ConsumerInvoicesClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(ConsumerInvoicesClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class BackofficeContractsClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  createCustomContract(request) {
    let url_ = this.baseUrl + "/backoffice/contracts";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(request);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processCreateCustomContract(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processCreateCustomContract(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processCreateCustomContract(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getAllContracts() {
    let url_ = this.baseUrl + "/backoffice/contracts";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetAllContracts(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetAllContracts(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetAllContracts(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  deleteCustomContract(contractNumber, contractItem, equipmentNumber) {
    let url_ = this.baseUrl + "/backoffice/contracts/{contractNumber}/{contractItem}/{equipmentNumber}";
    if (contractNumber === undefined || contractNumber === null) throw new Error("The parameter 'contractNumber' must be defined.");
    url_ = url_.replace("{contractNumber}", encodeURIComponent("" + contractNumber));
    if (contractItem === undefined || contractItem === null) throw new Error("The parameter 'contractItem' must be defined.");
    url_ = url_.replace("{contractItem}", encodeURIComponent("" + contractItem));
    if (equipmentNumber === undefined || equipmentNumber === null) throw new Error("The parameter 'equipmentNumber' must be defined.");
    url_ = url_.replace("{equipmentNumber}", encodeURIComponent("" + equipmentNumber));
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({})
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("delete", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processDeleteCustomContract(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processDeleteCustomContract(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processDeleteCustomContract(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return of(null);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
BackofficeContractsClient.ɵfac = function BackofficeContractsClient_Factory(t) {
  return new (t || BackofficeContractsClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
BackofficeContractsClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BackofficeContractsClient,
  factory: BackofficeContractsClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BackofficeContractsClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class BackofficeEquipmentsClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  getAllEquipments() {
    let url_ = this.baseUrl + "/backoffice/equipments";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetAllEquipments(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetAllEquipments(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetAllEquipments(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
BackofficeEquipmentsClient.ɵfac = function BackofficeEquipmentsClient_Factory(t) {
  return new (t || BackofficeEquipmentsClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
BackofficeEquipmentsClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BackofficeEquipmentsClient,
  factory: BackofficeEquipmentsClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BackofficeEquipmentsClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class BackofficeReplicationClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  bla(customerNumber) {
    let url_ = this.baseUrl + "/backoffice/replication/scheduleContractReplicationForCustomer?";
    if (customerNumber !== undefined && customerNumber !== null) url_ += "customerNumber=" + encodeURIComponent("" + customerNumber) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({})
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processBla(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processBla(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processBla(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return of(null);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
BackofficeReplicationClient.ɵfac = function BackofficeReplicationClient_Factory(t) {
  return new (t || BackofficeReplicationClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
BackofficeReplicationClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: BackofficeReplicationClient,
  factory: BackofficeReplicationClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(BackofficeReplicationClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class StandardAppInfoClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  getAppInfo(appId, locale) {
    let url_ = this.baseUrl + "/appInfo/{appId}/api/v1/appinfo?";
    if (appId === undefined || appId === null) throw new Error("The parameter 'appId' must be defined.");
    url_ = url_.replace("{appId}", encodeURIComponent("" + appId));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetAppInfo(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetAppInfo(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetAppInfo(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getWidgetTypes(appId, locale) {
    let url_ = this.baseUrl + "/appInfo/{appId}/api/v1/widgettypes?";
    if (appId === undefined || appId === null) throw new Error("The parameter 'appId' must be defined.");
    url_ = url_.replace("{appId}", encodeURIComponent("" + appId));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/octet-stream"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetWidgetTypes(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetWidgetTypes(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetWidgetTypes(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200 || status === 206) {
      const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
      const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
      const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
      return of({
        fileName: fileName,
        data: responseBlob,
        status: status,
        headers: _headers
      });
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getWidgetOptions(appId, widgetId, locale) {
    let url_ = this.baseUrl + "/appInfo/{appId}/api/v1/widgettypes/{widgetId}/options?";
    if (appId === undefined || appId === null) throw new Error("The parameter 'appId' must be defined.");
    url_ = url_.replace("{appId}", encodeURIComponent("" + appId));
    if (widgetId === undefined || widgetId === null) throw new Error("The parameter 'widgetId' must be defined.");
    url_ = url_.replace("{widgetId}", encodeURIComponent("" + widgetId));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/octet-stream"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetWidgetOptions(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetWidgetOptions(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetWidgetOptions(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200 || status === 206) {
      const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
      const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
      const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
      return of({
        fileName: fileName,
        data: responseBlob,
        status: status,
        headers: _headers
      });
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
  getWidgetData(appId, widgetId, settings, locale) {
    let url_ = this.baseUrl + "/appInfo/{appId}/api/v1/widgettypes/{widgetId}/data?";
    if (appId === undefined || appId === null) throw new Error("The parameter 'appId' must be defined.");
    url_ = url_.replace("{appId}", encodeURIComponent("" + appId));
    if (widgetId === undefined || widgetId === null) throw new Error("The parameter 'widgetId' must be defined.");
    url_ = url_.replace("{widgetId}", encodeURIComponent("" + widgetId));
    if (locale !== undefined && locale !== null) url_ += "locale=" + encodeURIComponent("" + locale) + "&";
    url_ = url_.replace(/[?&]$/, "");
    const content_ = JSON.stringify(settings);
    let options_ = {
      body: content_,
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Content-Type": "application/json",
        "Accept": "application/octet-stream"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("post", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGetWidgetData(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGetWidgetData(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGetWidgetData(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200 || status === 206) {
      const contentDisposition = response.headers ? response.headers.get("content-disposition") : undefined;
      const fileNameMatch = contentDisposition ? /filename="?([^"]*?)"?(;|$)/g.exec(contentDisposition) : undefined;
      const fileName = fileNameMatch && fileNameMatch.length > 1 ? fileNameMatch[1] : undefined;
      return of({
        fileName: fileName,
        data: responseBlob,
        status: status,
        headers: _headers
      });
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
StandardAppInfoClient.ɵfac = function StandardAppInfoClient_Factory(t) {
  return new (t || StandardAppInfoClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
StandardAppInfoClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StandardAppInfoClient,
  factory: StandardAppInfoClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StandardAppInfoClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
class StatusClient extends BaseClient {
  constructor(configuration, http, baseUrl) {
    super(configuration);
    this.jsonParseReviver = undefined;
    this.http = http;
    this.baseUrl = baseUrl !== undefined && baseUrl !== null ? baseUrl : this.getBaseUrl("");
  }
  /**
   * Gets the status of the application
   * @param versionOnly (optional) Allows to skip all dependency checks and retrieve name and version only
   * @return Success
   */
  get(versionOnly) {
    let url_ = this.baseUrl + "/status?";
    if (versionOnly === null) throw new Error("The parameter 'versionOnly' cannot be null.");else if (versionOnly !== undefined) url_ += "versionOnly=" + encodeURIComponent("" + versionOnly) + "&";
    url_ = url_.replace(/[?&]$/, "");
    let options_ = {
      observe: "response",
      responseType: "blob",
      headers: new HttpHeaders({
        "Accept": "application/json"
      })
    };
    return from(this.transformOptions(options_)).pipe(mergeMap(transformedOptions_ => {
      return this.http.request("get", url_, transformedOptions_);
    })).pipe(mergeMap(response_ => {
      return this.processGet(response_);
    })).pipe(catchError(response_ => {
      if (response_ instanceof HttpResponseBase) {
        try {
          return this.processGet(response_);
        } catch (e) {
          return throwError(e);
        }
      } else return throwError(response_);
    }));
  }
  processGet(response) {
    const status = response.status;
    const responseBlob = response instanceof HttpResponse ? response.body : response.error instanceof Blob ? response.error : undefined;
    let _headers = {};
    if (response.headers) {
      for (let key of response.headers.keys()) {
        _headers[key] = response.headers.get(key);
      }
    }
    if (status === 200) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result200 = null;
        result200 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return of(result200);
      }));
    } else if (status === 500) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        let result500 = null;
        result500 = _responseText === "" ? null : JSON.parse(_responseText, this.jsonParseReviver);
        return throwException("Error", status, _responseText, _headers, result500);
      }));
    } else if (status !== 200 && status !== 204) {
      return blobToText(responseBlob).pipe(mergeMap(_responseText => {
        return throwException("An unexpected server error occurred.", status, _responseText, _headers);
      }));
    }
    return of(null);
  }
}
StatusClient.ɵfac = function StatusClient_Factory(t) {
  return new (t || StatusClient)(i0.ɵɵinject(ClientConfiguration), i0.ɵɵinject(HttpClient), i0.ɵɵinject(API_BASE_URL, 8));
};
StatusClient.ɵprov = /* @__PURE__ */i0.ɵɵdefineInjectable({
  token: StatusClient,
  factory: StatusClient.ɵfac
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(StatusClient, [{
    type: Injectable
  }], function () {
    return [{
      type: ClientConfiguration,
      decorators: [{
        type: Inject,
        args: [ClientConfiguration]
      }]
    }, {
      type: i1.HttpClient,
      decorators: [{
        type: Inject,
        args: [HttpClient]
      }]
    }, {
      type: undefined,
      decorators: [{
        type: Optional
      }, {
        type: Inject,
        args: [API_BASE_URL]
      }]
    }];
  }, null);
})();
var EquipmentCategory;
(function (EquipmentCategory) {
  EquipmentCategory["Unknown"] = "Unknown";
  EquipmentCategory["PrePress"] = "PrePress";
  EquipmentCategory["Press"] = "Press";
  EquipmentCategory["PostPress"] = "PostPress";
  EquipmentCategory["Workflow"] = "Workflow";
})(EquipmentCategory || (EquipmentCategory = {}));
var ContractStatus;
(function (ContractStatus) {
  ContractStatus["Active"] = "Active";
  ContractStatus["Expired"] = "Expired";
  ContractStatus["AboutToExpire"] = "AboutToExpire";
})(ContractStatus || (ContractStatus = {}));
var EquipmentKind;
(function (EquipmentKind) {
  EquipmentKind["Unknown"] = "Unknown";
  EquipmentKind["Heidelberg"] = "Heidelberg";
  EquipmentKind["Extern"] = "Extern";
})(EquipmentKind || (EquipmentKind = {}));
var InvoiceStatus;
(function (InvoiceStatus) {
  InvoiceStatus["Open"] = "Open";
  InvoiceStatus["Closed"] = "Closed";
  InvoiceStatus["Dispute"] = "Dispute";
})(InvoiceStatus || (InvoiceStatus = {}));
var InvoiceCategory;
(function (InvoiceCategory) {
  InvoiceCategory["Unknown"] = "Unknown";
  InvoiceCategory["Consumables"] = "Consumables";
  InvoiceCategory["ServiceMaintenance"] = "ServiceMaintenance";
  InvoiceCategory["ServiceContract"] = "ServiceContract";
  InvoiceCategory["ServiceParts"] = "ServiceParts";
})(InvoiceCategory || (InvoiceCategory = {}));
var AssetSource;
(function (AssetSource) {
  AssetSource["Sap"] = "Sap";
  AssetSource["Crm"] = "Crm";
  AssetSource["Custom"] = "Custom";
})(AssetSource || (AssetSource = {}));
var Status;
(function (Status) {
  Status["OK"] = "ok";
  Status["Unstable"] = "unstable";
  Status["Error"] = "error";
})(Status || (Status = {}));
class ApiException extends Error {
  constructor(message, status, response, headers, result) {
    super();
    this.isApiException = true;
    this.message = message;
    this.status = status;
    this.response = response;
    this.headers = headers;
    this.result = result;
  }
  static isApiException(obj) {
    return obj.isApiException === true;
  }
}
function throwException(message, status, response, headers, result) {
  if (result !== null && result !== undefined) return throwError(result);else return throwError(new ApiException(message, status, response, headers, null));
}
function blobToText(blob) {
  return new Observable(observer => {
    if (!blob) {
      observer.next("");
      observer.complete();
    } else {
      let reader = new FileReader();
      reader.onload = event => {
        observer.next(event.target.result);
        observer.complete();
      };
      reader.readAsText(blob);
    }
  });
}
function get(type) {
  return this.metaData[type.name];
}
function add(data) {
  this.metaData[data.constructor.name] = data;
}
function withMetaData(httpParams) {
  const meta = {
    add,
    get,
    metaData: {}
  };
  return Object.assign(httpParams, {
    meta
  });
}
class AssetServiceApiApi {
  static forRoot(configurationFactory, deps) {
    return {
      ngModule: AssetServiceApiApi,
      providers: [{
        provide: ClientConfiguration,
        useFactory: configurationFactory,
        deps
      }]
    };
  }
}
AssetServiceApiApi.ɵfac = function AssetServiceApiApi_Factory(t) {
  return new (t || AssetServiceApiApi)();
};
AssetServiceApiApi.ɵmod = /* @__PURE__ */i0.ɵɵdefineNgModule({
  type: AssetServiceApiApi
});
AssetServiceApiApi.ɵinj = /* @__PURE__ */i0.ɵɵdefineInjector({
  providers: [InternalContractsClient, InternalEquipmentsClient, ConsumerContractsClient, ConsumerEquipmentsClient, ConsumerInvoicesClient, BackofficeContractsClient, BackofficeEquipmentsClient, BackofficeReplicationClient, StandardAppInfoClient, StatusClient]
});
(() => {
  (typeof ngDevMode === "undefined" || ngDevMode) && i0.ɵsetClassMetadata(AssetServiceApiApi, [{
    type: NgModule,
    args: [{
      imports: [],
      declarations: [],
      exports: [],
      providers: [InternalContractsClient, InternalEquipmentsClient, ConsumerContractsClient, ConsumerEquipmentsClient, ConsumerInvoicesClient, BackofficeContractsClient, BackofficeEquipmentsClient, BackofficeReplicationClient, StandardAppInfoClient, StatusClient]
    }]
  }], null, null);
})();

/**
 * Generated bundle index. Do not edit.
 */

export { API_BASE_URL, ApiException, AssetServiceApiApi, AssetSource, BackofficeContractsClient, BackofficeEquipmentsClient, BackofficeReplicationClient, BaseClient, ClientConfiguration, ConsumerContractsClient, ConsumerEquipmentsClient, ConsumerInvoicesClient, ContractStatus, EquipmentCategory, EquipmentKind, InternalContractsClient, InternalEquipmentsClient, InvoiceCategory, InvoiceStatus, StandardAppInfoClient, Status, StatusClient };
