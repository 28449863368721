import {Component, EventEmitter, Input, Output} from '@angular/core';

import {TranslateModule} from '@ngx-translate/core';
import {MatButtonModule} from '@angular/material/button';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';

@Component({
    standalone: true,
    imports: [TranslateModule, MatButtonModule, MatIconModule, MatMenuModule],
    selector: 'vmi-export-menu',
    templateUrl: './export-menu.component.html',
    styleUrls: ['./export-menu.component.scss'],
})
export class ExportMenuComponent {
    @Input()
    isMovementHistoryExportAllowed = false;

    @Input()
    isMonthlyMovementSummaryExportAllowed = false;

    @Input()
    isQrExportAllowed = false;

    @Output()
    excelExportClick = new EventEmitter<void>();

    @Output()
    movementHistoryExportClick = new EventEmitter<void>();

    @Output()
    monthlyMovementSummaryExportClick = new EventEmitter<void>();

    @Output()
    qrExportClick = new EventEmitter<void>();

    public onExcelExportClick(): void {
        this.excelExportClick.emit();
    }

    public onMovementHistoryExportClick(): void {
        this.movementHistoryExportClick.emit();
    }

    public onMonthlyMovementSummaryExportClick(): void {
        this.monthlyMovementSummaryExportClick.emit();
    }

    public onQrExportClick(): void {
        this.qrExportClick.emit();
    }
}
