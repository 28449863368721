/**
 * Here your define your application specific Roles and their permissions.
 */

import {CCRoleId} from '@heidelberg/control-center-frontend-integration/cc';

export interface SamplePermission {
    readonly id: string;
}

export interface SampleRole {
    readonly id: string;
    readonly permissions: SamplePermission[];
}

export const SAMPLE_PERM_ORG_SETTINGS_READ: SamplePermission = {
    id: 'organization_settings.read',
};

export const SAMPLE_PERM_ORG_SETTINGS_MANAGE: SamplePermission = {
    id: 'organization_settings.manage',
};

// Service logs

export const SAMPLE_PERM_PROFILE_READ: SamplePermission = {
    id: 'profile.read',
};

export const SAMPLE_ROLE_USER: SampleRole = {
    id: 'User',
    permissions: [SAMPLE_PERM_PROFILE_READ],
};

export const SAMPLE_ROLE_ORGANIZATION_MEMBER: SampleRole = {
    id: 'OrgMember',
    permissions: [SAMPLE_PERM_ORG_SETTINGS_READ],
};

export const SAMPLE_ROLE_ORGANIZATION_ADMIN: SampleRole = {
    id: 'OrgAdmin',
    permissions: [SAMPLE_PERM_ORG_SETTINGS_READ, SAMPLE_PERM_ORG_SETTINGS_MANAGE],
};

export const SAMPLE_ROLE_ORGANIZATION_OWNER: SampleRole = {
    id: 'OrgOwner',
    permissions: [SAMPLE_PERM_ORG_SETTINGS_READ, SAMPLE_PERM_ORG_SETTINGS_MANAGE],
};

export const SAMPLE_ALL_ROLES = [
    SAMPLE_ROLE_USER,
    SAMPLE_ROLE_ORGANIZATION_MEMBER,
    SAMPLE_ROLE_ORGANIZATION_ADMIN,
    SAMPLE_ROLE_ORGANIZATION_OWNER,
];

export const SAMPLE_ALL_ORG_ROLES = [
    SAMPLE_ROLE_ORGANIZATION_MEMBER,
    SAMPLE_ROLE_ORGANIZATION_ADMIN,
    SAMPLE_ROLE_ORGANIZATION_OWNER,
];

/**
 * Map roles for a person
 * @param roles
 */
export function calculatePersonRoles(roles: SampleRole[]) {
    roles.push(SAMPLE_ROLE_USER);
}

/**
 * Map roles for an organization member calculated from ControlCenter roles.
 * This calculation is used on server and on client.
 * @param ccRoles
 * @param roles
 */
export function calculateRolesFromCCRoles(ccRoles: CCRoleId[], roles: SampleRole[]) {
    if (isCCOwner(ccRoles)) {
        roles.push(SAMPLE_ROLE_ORGANIZATION_OWNER);
        roles.push(SAMPLE_ROLE_ORGANIZATION_MEMBER);
    } else if (isCCAdmin(ccRoles)) {
        roles.push(SAMPLE_ROLE_ORGANIZATION_MEMBER);
        roles.push(SAMPLE_ROLE_ORGANIZATION_ADMIN);
    } else {
        roles.push(SAMPLE_ROLE_ORGANIZATION_MEMBER);
    }
}

export function isCCAdmin(ccRoles: string[]) {
    if (isCCOwner(ccRoles) || ccRoles.indexOf('adm') !== -1) {
        return true;
    } else {
        return false;
    }
}

export function isCCOwner(ccRoles: string[]) {
    if (ccRoles.indexOf('own') !== -1) {
        return true;
    } else {
        return false;
    }
}
