import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';

import {ROUTE_PATH} from '@vmi/shared-models';

import {TransactionsWrapperComponent} from './containers/transactions-wrapper/transactions-wrapper.component';
import {
  ConsumptionReportWrapperComponent
} from './containers/consumption-report-wrapper/consumption-report-wrapper.component';

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild([
            {
                path: '',
                pathMatch: 'full',
                component: TransactionsWrapperComponent,
            },
            {
                path: ROUTE_PATH.consumptionReport,
                component: ConsumptionReportWrapperComponent,
            },
        ]),
    ],
})
export default class FeatureTransactionsModule {}
