import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule],
    selector: 'vmi-error-dialog',
    templateUrl: './error-dialog.component.html',
})
export class ErrorDialogComponent {
    option = HdmuiBaseDialogComponent.OPTION_OK;

    constructor(public readonly dialogRef: MatDialogRef<ErrorDialogComponent>) {}

    onOk() {
        this.dialogRef.close();
    }
}
