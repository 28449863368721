import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import {MatDividerModule} from '@angular/material/divider';
import {MatCheckbox, MatCheckboxChange, MatCheckboxModule} from '@angular/material/checkbox';
import {MatSidenav, MatSidenavModule} from '@angular/material/sidenav';
import {MatToolbarModule} from '@angular/material/toolbar';
import {MatIconModule} from '@angular/material/icon';

import {MatButtonModule} from '@angular/material/button';
import {TranslateModule} from '@ngx-translate/core';
import {MatBadgeModule} from '@angular/material/badge';

import {MovementType, TransactionsFilters, TransactionStatus} from '@vmi/feature-transactions';

import {TranslateTransactionStatusPipe} from '../../pipes/translate-transaction-status.pipe';
import {TranslateMovementTypePipe} from '../../pipes/translate-movement-type.pipe';

@Component({
    standalone: true,
    selector: 'vmi-transactions-filters',
    templateUrl: './transactions-filters.component.html',
    styleUrls: ['transactions-filters.component.scss'],
    imports: [
        MatSidenavModule,
        MatToolbarModule,
        MatIconModule,
        MatCheckboxModule,
        MatButtonModule,
        TranslateModule,
        MatBadgeModule,
        MatDividerModule,
        TranslateTransactionStatusPipe,
        TranslateMovementTypePipe,
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TransactionsFiltersComponent {
    public selectedStatuses: TransactionStatus[] = [];
    public selectedMovementTypes: MovementType[] = [];

    @ViewChildren('statusCheckbox') statusesCheckboxes!: QueryList<MatCheckbox>;
    @ViewChildren('movementTypeCheckbox') movementTypesCheckboxes!: QueryList<MatCheckbox>;

    @ViewChild(MatSidenav) matSidenav!: MatSidenav;

    @Input()
    appliedFilters: TransactionsFilters | undefined;

    @Input()
    availableStatuses: TransactionStatus[] = [];

    @Input()
    availableMovementTypes: MovementType[] = [];

    @Input()
    hasPageSubNavigation = true;

    @Output()
    applyClick = new EventEmitter<TransactionsFilters>();

    public openSidenav(): void {
        this.trySelectingFilters();
        this.matSidenav.open();
    }

    public closeSidenav(): void {
        this.matSidenav.close();
    }

    public isClearAllAllowed(): boolean {
        return this.isSelectedStatusesClearable() || this.isSelectedMovementTypesClearable();
    }

    public isSelectedStatusesClearable(): boolean {
        return !!this.selectedStatuses.length;
    }

    public isSelectedMovementTypesClearable(): boolean {
        return !!this.selectedMovementTypes.length;
    }

    public apply(): void {
        const filters: TransactionsFilters | undefined = this.hasAnyFiltersSelected()
            ? {
                  statuses: this.selectedStatuses,
                  movementTypes: this.selectedMovementTypes,
              }
            : undefined;

        this.applyClick.emit(filters);
        this.matSidenav.close();
    }

    public clearAll(): void {
        this.clearSelectedStatuses();
        this.clearSelectedMovementTypes();
    }

    public clearSelectedStatuses(): void {
        this.selectedStatuses = [];
        this.statusesCheckboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.toggle();
            }
        });
    }

    public clearSelectedMovementTypes(): void {
        this.selectedMovementTypes = [];
        this.movementTypesCheckboxes.forEach((checkbox) => {
            if (checkbox.checked) {
                checkbox.toggle();
            }
        });
    }

    public statusCheckChange(event: MatCheckboxChange, status: TransactionStatus): void {
        if (event.checked) {
            this.selectedStatuses.push(status);
        } else {
            this.selectedStatuses = this.selectedStatuses.filter((selectedStatus) => selectedStatus !== status);
        }
    }

    public movementTypeCheckChange(event: MatCheckboxChange, movementType: MovementType): void {
        if (event.checked) {
            this.selectedMovementTypes.push(movementType);
        } else {
            this.selectedMovementTypes = this.selectedMovementTypes.filter(
                (selectedMovementType) => selectedMovementType !== movementType
            );
        }
    }

    private trySelectingFilters() {
        this.selectedStatuses = [];
        this.selectedMovementTypes = [];

        this.statusesCheckboxes.forEach((checkbox) => {
            this.tryUncheckingCheckbox(checkbox);
            this.tryCheckingAppliedStatusCheckbox(checkbox);
        });

        this.movementTypesCheckboxes.forEach((checkbox) => {
            this.tryUncheckingCheckbox(checkbox);
            this.tryCheckingAppliedMovementTypeCheckbox(checkbox);
        });
    }

    private tryUncheckingCheckbox(checkbox: MatCheckbox): void {
        if (checkbox.checked) {
            checkbox.toggle();
        }
    }

    private tryCheckingAppliedStatusCheckbox(checkbox: MatCheckbox): void {
        this.appliedFilters?.statuses.forEach((appliedStatus) => {
            if (checkbox.value === appliedStatus.toString() && !checkbox.checked) {
                this.selectedStatuses.push(appliedStatus);
                checkbox.toggle();
            }
        });
    }

    private tryCheckingAppliedMovementTypeCheckbox(checkbox: MatCheckbox): void {
        this.appliedFilters?.movementTypes.forEach((appliedMovementType) => {
            if (checkbox.value === appliedMovementType.toString() && !checkbox.checked) {
                this.selectedMovementTypes.push(appliedMovementType);
                checkbox.toggle();
            }
        });
    }

    private hasAnyFiltersSelected(): boolean {
        return !!this.selectedStatuses.length || !!this.selectedMovementTypes.length;
    }
}
