import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import {MatTableDataSource, MatTableModule} from '@angular/material/table';
import {TranslateModule} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatSort, MatSortModule} from '@angular/material/sort';
import {MatRippleModule} from '@angular/material/core';

import {MatButtonModule} from '@angular/material/button';
import {isEqual} from 'lodash-es';

import {HdmuiEmptyStatesModule} from '@heidelberg/hdmui-angular';
import {ProductBookingDto} from '@heidelberg/vmi-subscription-api-client';

import {EmptyPipe} from '@vmi/ui-presentational';
import {TableColumn} from '@vmi/shared-models';
import {ConsumptionReportConfigurableColumns, ConsumptionReportObligatoryColumns} from '@vmi/feature-transactions';

import {TranslateProductUnitPipe} from '../../../../../feature-inventory/src/lib/pipes/translate-product-unit.pipe';
import {
  TranslateProductCategoryPipe
} from '../../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';

@Component({
    standalone: true,
    selector: 'vmi-consumption-report-table',
    imports: [
        MatTableModule,
        TranslateModule,
        HdmuiEmptyStatesModule,
        MatIconModule,
        EmptyPipe,
        MatButtonModule,
        MatSortModule,
        MatRippleModule,
        TranslateProductUnitPipe,
        TranslateProductCategoryPipe,
    ],
    templateUrl: './consumption-report-table.component.html',
    styleUrls: ['./consumption-report-table.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsumptionReportTableComponent implements OnChanges, AfterViewInit {
    readonly #translateProductUnitPipe = new TranslateProductUnitPipe();
    readonly #translateProductCategoryPipe = new TranslateProductCategoryPipe();

    private readonly CHUNK_SIZE = 100;

    public readonly CONFIGURABLE_COLUMNS = ConsumptionReportConfigurableColumns;
    public readonly OBLIGATORY_COLUMNS = ConsumptionReportObligatoryColumns;

    public dataSource = new MatTableDataSource<ProductBookingDto>([]);
    public maxDisplayedReports = this.CHUNK_SIZE;
    public displayShowMoreButton = false;

    @ViewChild(MatSort) sort: MatSort | null = null;

    private _columns!: string[];

    @Input()
    set visibleConfigurableColumns(visibleConfigurableColumns: TableColumn[]) {
        const columnIds = visibleConfigurableColumns.map((col) => col.id);
        this._columns = [
            ConsumptionReportObligatoryColumns.STATUS_ICON,
            ConsumptionReportObligatoryColumns.PRODUCT,
            ConsumptionReportObligatoryColumns.QUANTITY,
            ...columnIds,
        ];
    }

    @Input()
    reports!: ProductBookingDto[] | undefined;

    @Input()
    timeRange!: string;

    #previousReports: ProductBookingDto[] = [];

    constructor(private readonly cdRef: ChangeDetectorRef) {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['reports']) {
            const reports: ProductBookingDto[] = changes['reports']?.currentValue || this.reports;

            if (reports !== undefined) {
                if (this.shouldUpdateTableData(reports)) {
                    this.updateData(reports);
                }
                this.#previousReports = reports;
            }
        }
    }

    ngAfterViewInit(): void {
        this.sortDataSource();
    }

    public get columns(): string[] {
        return this._columns;
    }

    public onShowMoreButtonClick(): void {
        if (!this.isFurtherShowMoreActionAllowed()) {
            this.displayShowMoreButton = false;
        }

        this.maxDisplayedReports += this.CHUNK_SIZE;
        this.updateData(this.reports, false);
    }

    private isFurtherShowMoreActionAllowed() {
        return this.reports && this.maxDisplayedReports + this.CHUNK_SIZE <= this.reports.length;
    }

    private updateData(reports: ProductBookingDto[] = [], resetDisplayedDeliveriesNo = true): void {
        const data = this.prepareData(reports);

        this.dataSource = new MatTableDataSource<ProductBookingDto>(data);
        this.sortDataSource();

        this.displayShowMoreButton = ((!!this.reports && this.reports?.length) || 0) > this.dataSource.data.length;
        this.maxDisplayedReports = resetDisplayedDeliveriesNo ? this.CHUNK_SIZE : this.maxDisplayedReports;

        setTimeout(() => {
            this.cdRef.detectChanges();
        });
    }

    private prepareData(reports: ProductBookingDto[]): ProductBookingDto[] {
        return reports?.slice(0, this.maxDisplayedReports);
    }

    private shouldUpdateTableData(reports: ProductBookingDto[]): boolean {
        return !isEqual(reports, this.#previousReports);
    }

    private sortDataSource(): void {
        this.dataSource.sortingDataAccessor = (report, column) => {
            switch (column) {
                case ConsumptionReportObligatoryColumns.PRODUCT: {
                    return report.name;
                }
                case ConsumptionReportObligatoryColumns.QUANTITY: {
                    return report.amount;
                }
                case ConsumptionReportConfigurableColumns.CATEGORY: {
                    return this.#translateProductCategoryPipe.transform(report.category);
                }
                case ConsumptionReportConfigurableColumns.UNIT: {
                    return this.#translateProductUnitPipe.transform(report.isoCode);
                }
                default: {
                    if (report.hasOwnProperty(column)) {
                        return (report as any)[column];
                    }
                    return '';
                }
            }
        };

        this.dataSource.sort = this.sort;
    }
}
