import { Component } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';

@Component({
    standalone: true,
    imports: [MatDialogModule],
    selector: 'vmi-dialog-scroll',
    templateUrl: './dialog-scroll.component.html',
})
export class DialogScrollComponent {}
