import {TranslateService} from '@ngx-translate/core';
import {inject, Injectable} from '@angular/core';
import {DateTime} from 'luxon';

import {LocalizedDatePipe} from '@vmi/ui-presentational';
import {Transaction, TransactionsFilters} from '@vmi/feature-transactions';

import {TranslateTransactionStatusPipe} from '../pipes/translate-transaction-status.pipe';
import {TranslateMovementTypePipe} from '../pipes/translate-movement-type.pipe';
import {TransactionPostedDatePipe} from '../pipes/transaction-posted-date.pipe';
import {TransactionProcessedDatePipe} from '../pipes/transaction-processed-date.pipe';
import {
  TranslateProductCategoryPipe
} from '../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';

interface FilterStep {
    filterFnc: (data: Transaction[], filters: TransactionsFilters) => Transaction[];
}

@Injectable({
    providedIn: 'root',
})
export class TransactionsFilterService {
    readonly #translateService = inject(TranslateService);
    readonly #localizedDatePipe = new LocalizedDatePipe(this.#translateService);
    readonly #translateMovementTypePipe = new TranslateMovementTypePipe();
    readonly #translateTransactionStatusPipe = new TranslateTransactionStatusPipe();
    readonly #transactionPostedDatePipe = new TransactionPostedDatePipe();
    readonly #transactionProcessedDatePipe = new TransactionProcessedDatePipe();
    readonly #translateProductCategoryPipe = new TranslateProductCategoryPipe();

    public applyFilters(data: Transaction[], filters: TransactionsFilters | undefined): Transaction[] {
        if (!filters) {
            return data;
        }

        const filterPipeline: FilterStep[] = [
            {
                filterFnc: this.filterByStatus,
            },
            {
                filterFnc: this.filterByMovementType,
            },
        ];

        return filterPipeline.reduce((filteredData, { filterFnc }) => {
            return filterFnc(filteredData, filters);
        }, data);
    }

    public applySearchPhrase(data: Transaction[], searchPhrase: string): Transaction[] {
        if (!searchPhrase) {
            return data;
        }

        const lowerCaseSearchPhrase = searchPhrase?.toLowerCase();

        return data.filter(
            (item) =>
                this.searchByStatus(item, lowerCaseSearchPhrase) ||
                this.searchByMovementType(item, lowerCaseSearchPhrase) ||
                this.searchByQuantity(item, lowerCaseSearchPhrase) ||
                this.searchByProductId(item, lowerCaseSearchPhrase) ||
                this.searchByProductName(item, lowerCaseSearchPhrase) ||
                this.searchByProductCategory(item, lowerCaseSearchPhrase) ||
                this.searchByPostedDate(item, lowerCaseSearchPhrase) ||
                this.searchByProcessedDate(item, lowerCaseSearchPhrase)
        );
    }

    public filterOutFutureTransactions(data: Transaction[]): Transaction[] {
        return data.filter((item) => {
            const postedDate = this.#transactionPostedDatePipe.transform(item);
            return !postedDate || DateTime.fromJSDate(postedDate) < DateTime.now();
        });
    }

    private filterByStatus(data: Transaction[], filters: TransactionsFilters): Transaction[] {
        if (filters.statuses.length) {
            return data.filter((item) => filters.statuses.includes(item.status));
        }

        return data;
    }

    private filterByMovementType(data: Transaction[], filters: TransactionsFilters): Transaction[] {
        if (filters.movementTypes.length) {
            return data.filter((item) => filters.movementTypes.includes(item.movementType));
        }

        return data;
    }

    private searchByStatus(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        const translatedStatus = this.#translateTransactionStatusPipe.transform(item.status);
        return translatedStatus?.toLowerCase().includes(lowerCaseSearchPhrase);
    }

    private searchByMovementType(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        const translatedMovementType = this.#translateMovementTypePipe.transform(item.movementType);
        return translatedMovementType?.toLowerCase().includes(lowerCaseSearchPhrase);
    }

    private searchByProductName(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        return !!item.name?.toLowerCase().includes(lowerCaseSearchPhrase);
    }

    private searchByProductCategory(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        const translatedCategory = this.#translateProductCategoryPipe.transform(item.category);
        return !!translatedCategory?.toLowerCase().includes(lowerCaseSearchPhrase);
    }

    private searchByProductId(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        return !!item.id?.toLowerCase().includes(lowerCaseSearchPhrase);
    }

    private searchByQuantity(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        return item.amount?.toString() === lowerCaseSearchPhrase;
    }

    private searchByPostedDate(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        return !!this.#localizedDatePipe
            .transform(this.#transactionPostedDatePipe.transform(item), 'short', 'short')
            ?.includes(lowerCaseSearchPhrase);
    }

    private searchByProcessedDate(item: Transaction, lowerCaseSearchPhrase: string): boolean {
        return !!this.#localizedDatePipe
            .transform(this.#transactionProcessedDatePipe.transform(item), 'short', 'short')
            ?.includes(lowerCaseSearchPhrase);
    }
}
