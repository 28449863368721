import {Component} from '@angular/core';

@Component({
    standalone: true,
    selector: 'vmi-form-warning',
    template: `
        <p class="hdmui-m-0 hdmui-warning-strong">
            <ng-content></ng-content>
        </p>
    `,
})
export class FormWarningComponent {}
