import {MovementDirection, ProductBookingDto} from '@heidelberg/vmi-subscription-api-client';

import {MovementType, Transaction, TransactionStatus} from '@vmi/feature-transactions';

export function mapProductBookingDtoToTransaction(productBookingDto: ProductBookingDto): Transaction {
    return {
        ...productBookingDto,
        status: getTransactionStatus(productBookingDto),
        movementType: getTransactionMovementType(productBookingDto),
    };
}

export function sortTransactionsByDateDescending(transactions: Transaction[]): Transaction[] {
    const transactionsWithoutDate = transactions.filter(
        (transaction) => !transaction.retrievedBackAt && !transaction.transactionDate && !transaction.createdAt
    );
    const transactionsWithDate = transactions.filter(
        (transaction) => transaction.retrievedBackAt || transaction.transactionDate || transaction.createdAt
    );

    transactionsWithDate.sort((a, b) => {
        const aTransactionDate = a.transactionDate?.toISOString();
        const aCreatedAtDate = a.createdAt?.toISOString();
        const aRetrievedBackAtDate = a.retrievedBackAt?.toISOString();
        const bTransactionDate = b.transactionDate?.toISOString();
        const bCreatedAtDate = b.createdAt?.toISOString();
        const bRetrievedBackAtDate = b.retrievedBackAt?.toISOString();

        const aDate = (aRetrievedBackAtDate || aTransactionDate || aCreatedAtDate) as string;
        const bDate = (bRetrievedBackAtDate || bTransactionDate || bCreatedAtDate) as string;

        return bDate.localeCompare(aDate);
    });

    return [...transactionsWithDate, ...transactionsWithoutDate];
}

export function getTransactionStatus(productBookingDto: ProductBookingDto): TransactionStatus {
    const movementType = getTransactionMovementType(productBookingDto);

    const processingMovementTypes = [MovementType.POSTED_STOCK_COUNT, MovementType.POSTED_CONSUMPTION];

    const pendingMovementTypes = [MovementType.PENDING_DELIVERY];

    const completedMovementTypes = [
        MovementType.PROCESSED_STOCK_COUNT,
        MovementType.PROCESSED_CONSUMPTION,
        MovementType.COMPLETED_DELIVERY,
    ];

    const rejectedMovementTypes = [
        MovementType.REJECTED_STOCK_COUNT,
        MovementType.REJECTED_CONSUMPTION,
        MovementType.REJECTED_DELIVERY,
    ];

    if (processingMovementTypes.includes(movementType)) {
        return TransactionStatus.PROCESSING;
    }

    if (pendingMovementTypes.includes(movementType)) {
        return TransactionStatus.PENDING;
    }

    if (completedMovementTypes.includes(movementType)) {
        return TransactionStatus.COMPLETED;
    }

    if (rejectedMovementTypes.includes(movementType)) {
        return TransactionStatus.REJECTED;
    }

    return TransactionStatus.UNKNOWN;
}

export function getTransactionMovementType(productBookingDto: ProductBookingDto | Transaction): MovementType {
    if (productBookingDto.type === '0' || productBookingDto.type === '632') {
        return getStockCountType(!!productBookingDto.retrievedBackAt);
    }

    if (productBookingDto.type === '631') {
        return getConsumptionType(!!productBookingDto.retrievedBackAt);
    }

    if (productBookingDto.type === '101' || productBookingDto.isPendingDelivery) {
        return getDeliveryType(productBookingDto.isPendingDelivery);
    }

    if (
        productBookingDto.type === null &&
        productBookingDto.direction === MovementDirection.H &&
        (productBookingDto.isPosted || productBookingDto.isUnposted)
    ) {
        return MovementType.POSTED_CONSUMPTION;
    }

    return MovementType.UNKNOWN;
}

export function getIconClass(status: TransactionStatus): string {
    switch (status) {
        case TransactionStatus.COMPLETED:
            return 'hdmui-success';
        case TransactionStatus.REJECTED:
            return 'hdmui-error';
        case TransactionStatus.PROCESSING:
        case TransactionStatus.PENDING:
            return 'hdmui-warning-strong';
        case TransactionStatus.UNKNOWN:
            return '';
        default:
            return '';
    }
}

function getStockCountType(isRetrieved: boolean): MovementType.PROCESSED_STOCK_COUNT | MovementType.POSTED_STOCK_COUNT {
    return isRetrieved ? MovementType.PROCESSED_STOCK_COUNT : MovementType.POSTED_STOCK_COUNT;
}

function getConsumptionType(
    isRetrieved: boolean
): MovementType.PROCESSED_CONSUMPTION | MovementType.POSTED_CONSUMPTION {
    return isRetrieved ? MovementType.PROCESSED_CONSUMPTION : MovementType.POSTED_CONSUMPTION;
}

function getDeliveryType(isPending: boolean): MovementType.PENDING_DELIVERY | MovementType.COMPLETED_DELIVERY {
    return isPending ? MovementType.PENDING_DELIVERY : MovementType.COMPLETED_DELIVERY;
}
