/**
 * - mapping membership roles to permissions. check methods for permissions
 */

import {Injectable} from '@angular/core';

import {CCSession} from '@heidelberg/control-center-frontend-integration/auth';
import {CCOrganizationMembership} from '@heidelberg/control-center-frontend-integration/cc';

import {fromRoles, mapRoles, NO_CONTEXT_ID, Permission, Permissions, RoleContext, RoleContexts} from '@vmi/core';

import {calculatePersonRoles, calculateRolesFromCCRoles, SampleRole} from '@vmi/shared-models';

@Injectable({
    providedIn: 'root',
})
export class PermissionService {
    private roleContexts: RoleContexts = {};

    public buildRoleContexts(session: CCSession): void {
        this.roleContexts = {};

        const consumerRoles: SampleRole[] = [];
        calculatePersonRoles(consumerRoles);
        const roles = mapRoles(consumerRoles);
        const noContextContext: RoleContext = {
            id: NO_CONTEXT_ID,
            roles,
            permissions: fromRoles(roles),
        };
        this.roleContexts[noContextContext.id] = noContextContext;

        for (const membership of session.user.memberships) {
            const context = this.buildMemberRoleContext(membership);
            this.roleContexts[context.id] = context;
        }
    }

    private buildMemberRoleContext(membership: CCOrganizationMembership): RoleContext {
        const consumerRoles: SampleRole[] = [];
        calculateRolesFromCCRoles(membership.ccRoles, consumerRoles);
        const roles = mapRoles(consumerRoles);

        return {
            id: membership.organizationId,
            roles,
            permissions: fromRoles(roles),
        };
    }

    public checkPermission(permission: string | string[], organizationId: string | undefined): boolean {
        let ok = false;
        const assignedPermissions = this.getAllContextAndNoContextPermissions(organizationId);
        if (this.isString(permission)) {
            const perm = assignedPermissions[permission];
            ok = perm !== undefined;
        } else {
            const matching = permission.map((s) => this.checkPermission(s, organizationId));
            ok = matching.find((p) => p === true) !== undefined;
        }

        return ok;
    }

    public checkAllPermission(permission: string | string[], organizationId: string | undefined): boolean {
        let ok = false;
        const assignedPermissions = this.getAllContextAndNoContextPermissions(organizationId);

        if (this.isString(permission)) {
            ok = assignedPermissions[permission] !== undefined;
        } else {
            const matching = permission.map((s) => this.checkPermission(s, organizationId));
            const hasFalse = matching.find((p) => p === false) !== undefined;
            ok = !hasFalse;
        }
        return ok;
    }
    private isString(x: any): x is string {
        return typeof x === 'string';
    }

    private getAllContextAndNoContextPermissions(organizationId: string | undefined): Permissions {
        if (this.roleContexts[NO_CONTEXT_ID]) {
            const result = { ...this.roleContexts[NO_CONTEXT_ID].permissions };
            if (organizationId) {
                const orgPermissions = this.roleContexts[organizationId]?.permissions;
                if (orgPermissions) {
                    for (const key in orgPermissions) {
                        if (Object.prototype.hasOwnProperty.call(orgPermissions, key)) {
                            result[key] = orgPermissions[key];
                        }
                    }
                }
            }
            return result;
        }

        return {};
    }

    checkLinkRoute(url: string, organizationId: string | undefined): boolean {
        const assignedPermissions = this.getAllContextAndNoContextPermissions(organizationId);
        let matchingPermission: Permission | undefined;

        for (const key in assignedPermissions) {
            if (Object.prototype.hasOwnProperty.call(assignedPermissions, key)) {
                const permission = assignedPermissions[key];
                if (permission?.routes) {
                    const route = permission.routes.find((r) => {
                        return url.indexOf(r.appPath) !== -1 && (url.indexOf(r.pagePath) !== -1 || '/*' === r.pagePath);
                    });
                    if (route) {
                        matchingPermission = permission;
                        break;
                    }
                }
            }
        }

        if (matchingPermission === undefined) {
            console.log('Route is not allowed', url, organizationId);
        }
        return matchingPermission !== undefined;
    }

    async checkRouteWithOrganizationCheck(url: string, currentOrganizationId?: string): Promise<boolean> {
        let organizationId: string | undefined = extractOrganizationId(url);
        if (!organizationId) {
            organizationId = currentOrganizationId;
        }
        return this.checkLinkRoute(url, organizationId);
    }
}

export function extractOrganizationId(url: string): string | undefined {
    const organizationIdPrefix = `/organizations/`;
    const index = url.indexOf(organizationIdPrefix);
    if (index !== -1) {
        let organizationId = url.substring(index + organizationIdPrefix.length);
        const endIndex = organizationId.indexOf('/');
        if (endIndex !== -1) {
            organizationId = organizationId.substring(0, endIndex);
        }
        return organizationId;
    }
    return undefined;
}
