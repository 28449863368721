import {ClientConfiguration as LazyTokenClientConfig} from 'heidelberg-assets-api-client';

import {OrgTokenService} from '../services';

export function ClientConfigurationFactory() {
    return function (serviceUrl: string, orgTokenService: OrgTokenService) {
        const config = new LazyTokenClientConfig();
        config.token = orgTokenService.orgToken;
        config.baseUrl = serviceUrl;

        return config;
    };
}
