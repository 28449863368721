import {registerLocaleData} from '@angular/common';
import {HttpClient} from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import localeDe from '@angular/common/locales/de';
import localeEn from '@angular/common/locales/en';
import localeEnGB from '@angular/common/locales/en-GB';
import localeEs from '@angular/common/locales/es';
import localeFr from '@angular/common/locales/fr';
import localeHu from '@angular/common/locales/hu';
import localeIt from '@angular/common/locales/it';
import localeJa from '@angular/common/locales/ja';
import localeKo from '@angular/common/locales/ko';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localePt from '@angular/common/locales/pt';
import localeRo from '@angular/common/locales/ro';
import localeRu from '@angular/common/locales/ru';
import localeTh from '@angular/common/locales/th';
import localeZh from '@angular/common/locales/zh';
import {TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {firstValueFrom} from 'rxjs';

import {CCAuthService} from '@heidelberg/control-center-frontend-integration/auth';

import {TRANSLATION_VERSION} from '../../../../src/app/generated/translation_version';

registerLocaleData(localeDe);
registerLocaleData(localeEn);
registerLocaleData(localeEnGB);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localeIt);
registerLocaleData(localeJa);
registerLocaleData(localeKo);
registerLocaleData(localeZh);
registerLocaleData(localePt);
registerLocaleData(localePl);
registerLocaleData(localeNl);
registerLocaleData(localeCs);
registerLocaleData(localeTh);
registerLocaleData(localeRu);
registerLocaleData(localeHu, 'hu');
registerLocaleData(localeRo, 'ro');

const LOCALSTORAGE_LOCALE_KEY = 'Language';

export async function getLocale(translate: TranslateService, ccAuthService: CCAuthService): Promise<string> {
    translate.addLangs([
        'cs',
        'de',
        'es',
        'fr',
        'hu',
        'de',
        'it',
        'ja',
        'ko',
        'nl',
        'pl',
        'pt',
        'ro',
        'ru',
        'th',
        'zh',
        'en',
    ]);
    translate.setDefaultLang('en');
    const currentUserLocale = ccAuthService.getCurrentUser()?.locale;
    if (currentUserLocale) {
        localStorage.setItem(LOCALSTORAGE_LOCALE_KEY, currentUserLocale);
        translate.use(extractSupportedLocale(currentUserLocale));
    } else {
        const browserLang = translate.getBrowserLang();
        const locale = localStorage.getItem(LOCALSTORAGE_LOCALE_KEY) ?? browserLang;
        translate.use(extractSupportedLocale(locale));
    }

    // fetch any text to wait for translations available
    await firstValueFrom(translate.get('*.about'));

    return translate.currentLang;
}

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
    return new TranslateHttpLoader(http, `./assets/generated/i18n/${TRANSLATION_VERSION}/vmi_`, '.json');
}

function extractSupportedLocale(locale: string | undefined): string {
    let patchedLocale = locale;
    if (patchedLocale) {
        const index = patchedLocale.indexOf('-');
        if (index !== -1) {
            patchedLocale = patchedLocale.substring(0, index);
        }
        return patchedLocale.match(/en|de|cs|es|fr|hu|it|ja|ko|nl|pl|pt|ro|ru|th|zh/) ? patchedLocale : 'en';
    } else {
        return 'en';
    }
}
