import {Component, OnInit, ViewChild} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TranslateModule, TranslateService} from '@ngx-translate/core';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import {UntilDestroy} from '@ngneat/until-destroy';
import {combineLatest, map, Observable, take} from 'rxjs';
import {RouterLink} from '@angular/router';
import {isEqual} from 'lodash-es';
import {MatTooltip} from "@angular/material/tooltip";

import {HdmuiAppearanceCustomizerElement} from '@heidelberg/hdmui-angular';

import {
  ExportMenuComponent,
  LoadingIndicatorComponent,
  SearchInputComponent,
  TableAppearanceCustomizerComponent,
} from '@vmi/ui-presentational';
import {DataDisplayType, ROUTE_PATH, TableColumn} from '@vmi/shared-models';
import {
  AppearanceCustomizerStorageKey,
  AppearanceCustomizerStorageService,
  QrSearchDialogService,
} from '@vmi/ui-smart';
import {ConfigurableColumns, Transaction, TransactionsFacade, TransactionsFilters} from '@vmi/feature-transactions';

import {TransactionsState} from '../../models/transactions-state.interface';
import {TransactionsFiltersComponent} from '../../components/transactions-filters/transactions-filters.component';
import {TransactionCardsComponent} from '../../components/transaction-cards/transaction-cards.component';
import {TransactionsTableComponent} from '../../components/transactions-table/transactions-table.component';
import {FilterOutFutureTransactionsPipe} from '../../pipes/filter-out-future-transactions.pipe';

@UntilDestroy()
@Component({
    standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    MatIconModule,
    MatButtonModule,
    SearchInputComponent,
    LoadingIndicatorComponent,
    ExportMenuComponent,
    TableAppearanceCustomizerComponent,
    TransactionsFiltersComponent,
    TransactionsTableComponent,
    TransactionCardsComponent,
    RouterLink,
    FilterOutFutureTransactionsPipe,
    MatTooltip,
  ],
    templateUrl: './transactions-wrapper.component.html',
    styleUrls: ['./transactions-wrapper.component.scss'],
})
export class TransactionsWrapperComponent implements OnInit {
    @ViewChild(SearchInputComponent) searchInputComponent!: SearchInputComponent;

    private readonly CONFIGURABLE_COLUMNS: TableColumn[] = [
        {
            id: ConfigurableColumns.PRODUCT,
            name: this.translateService.instant('inventory.products.grid.product'),
        },
        {
            id: ConfigurableColumns.CATEGORY,
            name: this.translateService.instant('inventory.products.grid.category'),
        },
        {
            id: ConfigurableColumns.QUANTITY,
            name: this.translateService.instant('inventory.productDetails.grid.amount'),
        },
        {
            id: ConfigurableColumns.POSTED,
            name: this.translateService.instant('inventory.products.grid.posted'),
        },
        {
            id: ConfigurableColumns.PROCESSED,
            name: this.translateService.instant('inventory.products.grid.processed'),
        },
    ];

    public readonly consumptionReportPath = ROUTE_PATH.consumptionReport;

    public readonly state$: Observable<TransactionsState> = combineLatest([
        this.transactionsFacade.isTabletUp$,
        this.transactionsFacade.transactionsMetadataRequest$,
        this.transactionsFacade.searchPhrase$,
        this.transactionsFacade.isSearchPhraseLoading$,
        this.transactionsFacade.dataDisplayType$,
        this.transactionsFacade.availableFilters$,
        this.transactionsFacade.appliedFilters$,
    ]).pipe(
        map(
            ([
                isTabletUp,
                transactionsMetadata,
                searchPhrase,
                isSearchPhraseLoading,
                dataDisplayType,
                availableFilters,
                appliedFilters,
            ]) => ({
                isTabletUp,
                transactionsMetadata,
                searchPhrase,
                isSearchPhraseLoading,
                dataDisplayType,
                availableFilters,
                appliedFilters,
            })
        )
    );

    public visibleColumns = [...this.CONFIGURABLE_COLUMNS];
    public customizerConfigElements: HdmuiAppearanceCustomizerElement[] = this.visibleColumns.map((col) => ({
        id: col.id,
        visible: true,
        name: col.name,
    }));

    public defaultCustomizerConfigElements: HdmuiAppearanceCustomizerElement[] = this.CONFIGURABLE_COLUMNS.map(
        (col) => ({
            id: col.id,
            visible: true,
            name: col.name,
        })
    );

    constructor(
        private readonly transactionsFacade: TransactionsFacade,
        private readonly qrSearchDialogService: QrSearchDialogService,
        private readonly appearanceCustomizerLocalStorageService: AppearanceCustomizerStorageService,
        private readonly translateService: TranslateService
    ) {}

    ngOnInit(): void {
        this.updateCustomizerElementsFromLocalStorage();
        this.transactionsFacade.updateFiltersFromLocalStorage();
    }

    public get shouldDisplayAppearanceCustomizerBadge(): boolean {
        return !isEqual(this.defaultCustomizerConfigElements, this.customizerConfigElements);
    }

    public onSearchPhraseChange(phrase: string): void {
        this.transactionsFacade.setSearchPhrase(phrase);
    }

    public isDisplayTypeTable(displayType: DataDisplayType): boolean {
        return displayType === DataDisplayType.TABLE;
    }

    public toggleDisplayType(): void {
        this.transactionsFacade.toggleDisplayType();
    }

    public onCustomizerConfigElementsChange(
        elements: HdmuiAppearanceCustomizerElement[],
        shouldPersist = true
    ): void {
        this.customizerConfigElements = elements;
        this.visibleColumns = elements
            .filter((el) => el.visible)
            .map((visibleEl) => ({ id: visibleEl.id, name: visibleEl.name }));

        if (shouldPersist) {
            this.appearanceCustomizerLocalStorageService.saveCustomizerConfigElements(
                AppearanceCustomizerStorageKey.TRANSACTIONS,
                elements.map((el) => ({ id: el.id, visible: el.visible }))
            );
        }
    }

    public openQrCodeDialogForSearch(): void {
        this.qrSearchDialogService
            .openQrCodeDialogForSearch(this.searchInputComponent)
            .afterClosed()
            .pipe(take(1))
            .subscribe((extractedValues) => {
                const id = extractedValues.id;
                const shelf = extractedValues.shelf;

                if (id) {
                    this.transactionsFacade.setSearchPhrase(id);
                } else if (shelf) {
                    this.transactionsFacade.setSearchPhrase(shelf);
                }
            });
    }

    public applyFilters(filters: TransactionsFilters): void {
        this.transactionsFacade.applyFilters(filters);
    }

    public onExcelExportClick(): void {
        this.transactionsFacade.exportTransactionsHistory();
    }

    public onCopyToClipboardClick(
        transactions: Transaction[],
        searchPhrase: string,
        filters: TransactionsFilters | undefined
    ): void {
        this.transactionsFacade.copyToClipboard(transactions, searchPhrase, filters);
    }

    private updateCustomizerElementsFromLocalStorage(): void {
        const savedCustomizerElements = this.appearanceCustomizerLocalStorageService.getSavedCustomizerConfigElements(
            AppearanceCustomizerStorageKey.TRANSACTIONS
        );

        if (savedCustomizerElements) {
            const elements: HdmuiAppearanceCustomizerElement[] = savedCustomizerElements.map((el) => {
                const defaultEl = this.defaultCustomizerConfigElements.find((e) => el.id === e.id);

                return {
                    id: el.id,
                    name: defaultEl?.name || '',
                    visible: el.visible,
                };
            });

            this.onCustomizerConfigElementsChange(elements, false);
        }
    }
}
