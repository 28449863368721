import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

import {TransactionStatus} from '@vmi/feature-transactions';

@Pipe({
    standalone: true,
    name: 'translateTransactionStatus',
})
export class TranslateTransactionStatusPipe implements PipeTransform {
    private readonly translateService = inject(TranslateService);

    transform(transactionStatus: TransactionStatus): string {
        let translateKey = '—';

        switch (transactionStatus) {
            case TransactionStatus.COMPLETED:
                translateKey = 'inventory.deliveryProcessing.status.completed';
                break;
            case TransactionStatus.PENDING:
                translateKey = 'inventory.transactions.status.pending';
                break;
            case TransactionStatus.PROCESSING:
                translateKey = 'inventory.transactions.status.processing';
                break;
            case TransactionStatus.REJECTED:
                translateKey = 'inventory.transactions.status.rejected';
                break;
        }

        return this.translateService.instant(translateKey);
    }
}
