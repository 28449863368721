import {Injectable} from '@angular/core';
import {combineLatest, EMPTY, filter, map, Observable, switchMap, tap} from 'rxjs';

import {
  DocumentsClient,
  FileResponse,
  ISaveOpenDeliveryProductDto,
  OpenDeliveriesClient,
  OpenDeliveryDto,
  OpenDeliveryProductDto,
  SaveOpenDeliveryDto,
} from '@heidelberg/vmi-subscription-api-client';
import {CCAuthService} from '@heidelberg/control-center-frontend-integration/auth';

import {PrintShopsService} from '@vmi/core';
import {IConfirmedOpenDelivery, IOpenDelivery} from '@vmi/shared-models';
import {mapToRequestMetadataWithRetry, RequestMetadata} from '@vmi/utils';

import {mapDtoToOpenDelivery} from '../../../../feature-deliveries/src/lib/functions/delivery-status.functions';
import {
  getDeliveryProductWorkingId
} from '../../../../feature-deliveries/src/lib/functions/get-delivery-product-working-id.function';

// TBD: split into delivery service and delivery api service

@Injectable({
    providedIn: 'root',
})
export class InventoryDeliveryService {
    #originalOpenDeliveries: OpenDeliveryDto[] = [];

    constructor(
        private readonly openDeliveriesClient: OpenDeliveriesClient,
        private readonly documentsClient: DocumentsClient,
        private readonly sessionService: CCAuthService,
        private readonly printShopsService: PrintShopsService
    ) {}

    public getOpenDeliveriesXlsx(deliveryNumbers: string[]): Observable<FileResponse> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.documentsClient.getOpenDeliveriesXlsx(
                    selectedPrintshop?.customerNumber,
                    deliveryNumbers,
                    this.sessionService.getCurrentUser()?.locale
                )
            )
        );
    }

    public getOriginalOpenDeliveries(): OpenDeliveryDto[] {
        return this.#originalOpenDeliveries;
    }

    public getAllDeliveries(): Observable<RequestMetadata<IOpenDelivery[]>> {
        this.#originalOpenDeliveries = [];
        const openDeliveriesThatArePartlyCompletedMap = new Map<string, OpenDeliveryDto>();

        return this.fetchDeliveries(false).pipe(
            tap((openDeliveries) => {
                this.#originalOpenDeliveries = openDeliveries;
            }),
            switchMap((openDeliveries) =>
                this.fetchDeliveries(true).pipe(
                    map((completedDeliveries) => [
                        ...this.prepareOpenDeliveries(
                            openDeliveries,
                            completedDeliveries,
                            openDeliveriesThatArePartlyCompletedMap
                        ),
                        ...this.prepareCompletedDeliveries(
                            completedDeliveries,
                            openDeliveriesThatArePartlyCompletedMap
                        ),
                    ])
                )
            ),
            map((combinedDeliveries) =>
                combinedDeliveries.map((delivery) => mapDtoToOpenDelivery(delivery, this.#originalOpenDeliveries))
            ),
            mapToRequestMetadataWithRetry()
        );
    }

    public postOpenDelivery(openDelivery: IConfirmedOpenDelivery): Observable<void> {
        const originalOpenDelivery = this.#originalOpenDeliveries.find(
            (d) => openDelivery.externalDeliveryNumber === d.externalDeliveryNumber
        );

        if (!originalOpenDelivery) {
            return EMPTY;
        }

        const products = this.prepareProductsPayload(openDelivery, originalOpenDelivery);

        return combineLatest([this.printShopsService.selectedPrintShop$, this.printShopsService.hasWriteRights$]).pipe(
            filter(([selectedPrintshop, hasWriteRights]) => !!selectedPrintshop && hasWriteRights),
            switchMap(([selectedPrintshop]) =>
                this.openDeliveriesClient.post({
                    customerNumber: selectedPrintshop?.customerNumber,
                    internalDeliveryNumber: openDelivery.internalDeliveryNumber,
                    products,
                } as SaveOpenDeliveryDto)
            )
        );
    }

    private prepareProductsPayload(
        openDelivery: IConfirmedOpenDelivery,
        originalOpenDelivery: OpenDeliveryDto
    ): ISaveOpenDeliveryProductDto[] {
        return (
            openDelivery.products
                ?.filter((product) => originalOpenDelivery.products?.map((p) => p.id).includes(product.id))
                ?.map(
                    (product) =>
                        ({
                            isAdditional: product.isAdditional,
                            isoCode: (product.isCustom && product.isoCode) || null,
                            position: product.position,
                            productId: product.id,
                            quantity: product.confirmedQuantity,
                            materialDescription: (product.isCustom && product.name) || null,
                        }) as ISaveOpenDeliveryProductDto
                ) || []
        );
    }

    private fetchDeliveries(onlyCompleted: boolean): Observable<OpenDeliveryDto[]> {
        return this.printShopsService.selectedPrintShop$.pipe(
            filter((selectedPrintshop) => !!selectedPrintshop),
            switchMap((selectedPrintshop) =>
                this.openDeliveriesClient.get(
                    selectedPrintshop?.customerNumber,
                    this.sessionService.getCurrentUser()?.locale,
                    onlyCompleted
                )
            )
        );
    }

    private prepareOpenDeliveries(
        openDeliveries: OpenDeliveryDto[],
        completedDeliveries: OpenDeliveryDto[],
        openDeliveriesThatArePartlyCompletedMap: Map<string, OpenDeliveryDto>
    ) {
        return openDeliveries.filter((openDelivery) => {
            // if delivery is shared between open and completed deliveries - it is partly completed
            const isPartlyCompleted = completedDeliveries
                .map((completedDelivery) => completedDelivery.externalDeliveryNumber)
                .includes(openDelivery.externalDeliveryNumber);

            if (isPartlyCompleted && openDelivery.externalDeliveryNumber) {
                openDeliveriesThatArePartlyCompletedMap.set(openDelivery.externalDeliveryNumber, openDelivery);
            }

            return !isPartlyCompleted;
        });
    }

    private prepareCompletedDeliveries(
        completedDeliveries: OpenDeliveryDto[],
        openDeliveriesThatArePartlyCompletedMap: Map<string, OpenDeliveryDto>
    ): OpenDeliveryDto[] {
        const uniqueCompletedDeliveriesMap = new Map<string, OpenDeliveryDto>();

        for (const delivery of completedDeliveries) {
            // ignore deliveries without externalDeliveryNumber
            if (!delivery.externalDeliveryNumber) {
                continue;
            }
            this.bindProductsAndSortByPosition(delivery);
            this.prepareUniqueCompletedDeliveriesMap(uniqueCompletedDeliveriesMap, delivery);
        }

        this.bindDataFromOpenDeliveryWithPartlyCompletedDelivery(
            uniqueCompletedDeliveriesMap,
            openDeliveriesThatArePartlyCompletedMap
        );

        return Array.from(uniqueCompletedDeliveriesMap.values());
    }

    private bindProductsAndSortByPosition(delivery: OpenDeliveryDto): void {
        const productsMap = new Map<string, OpenDeliveryProductDto>();

        for (const product of delivery.products || []) {
            if (!product.id || !product.orderNumber) {
                continue;
            }

            const productId = getDeliveryProductWorkingId(product);

            if (!productsMap.has(productId)) {
                productsMap.set(productId, product);
            } else {
                const existingProduct = productsMap.get(productId);

                if (
                    existingProduct &&
                    existingProduct.id &&
                    existingProduct.confirmedQuantity !== undefined &&
                    product.confirmedQuantity !== undefined &&
                    existingProduct.confirmedQuantity < product.confirmedQuantity
                ) {
                    productsMap.set(productId, product);
                }
            }
        }

        delivery.products = [...productsMap.values()].sort((a, b) => a.position - b.position);
    }

    private prepareUniqueCompletedDeliveriesMap(
        uniqueCompletedDeliveriesMap: Map<string, OpenDeliveryDto>,
        delivery: OpenDeliveryDto
    ): void {
        if (!delivery.externalDeliveryNumber) {
            return;
        }

        // take the newest partly completed delivery
        if (!uniqueCompletedDeliveriesMap.has(delivery.externalDeliveryNumber)) {
            uniqueCompletedDeliveriesMap.set(delivery.externalDeliveryNumber, delivery);
        } else {
            const existingDelivery = uniqueCompletedDeliveriesMap.get(delivery.externalDeliveryNumber);
            if (
                existingDelivery &&
                delivery.completedAt &&
                existingDelivery.completedAt &&
                delivery.completedAt > existingDelivery.completedAt
            ) {
                uniqueCompletedDeliveriesMap.set(delivery.externalDeliveryNumber, delivery);
            }
        }
    }

    private bindDataFromOpenDeliveryWithPartlyCompletedDelivery(
        uniqueCompletedDeliveriesMap: Map<string, OpenDeliveryDto>,
        openDeliveriesThatArePartlyCompletedMap: Map<string, OpenDeliveryDto>
    ) {
        Array.from(openDeliveriesThatArePartlyCompletedMap.keys()).forEach((externalDeliveryNumber) => {
            const partlyCompletedDelivery = uniqueCompletedDeliveriesMap.get(externalDeliveryNumber);
            const openDelivery = openDeliveriesThatArePartlyCompletedMap.get(externalDeliveryNumber);

            if (partlyCompletedDelivery && openDelivery && partlyCompletedDelivery.externalDeliveryNumber) {
                // remove products that are not delivered from partly completed delivery
                partlyCompletedDelivery.products = partlyCompletedDelivery.products?.filter(
                    (product) =>
                        !openDelivery.products
                            ?.map((p) => getDeliveryProductWorkingId(p))
                            ?.includes(getDeliveryProductWorkingId(product))
                );

                // add products that are not delivered from open delivery to partly completed delivery
                partlyCompletedDelivery.products?.push(...(openDelivery.products || []));

                // sort products in partly completed delivery by position
                partlyCompletedDelivery.products = partlyCompletedDelivery.products?.sort(
                    (a, b) => a.position - b.position
                );

                uniqueCompletedDeliveriesMap.set(
                    partlyCompletedDelivery.externalDeliveryNumber,
                    partlyCompletedDelivery
                );
            }
        });
    }
}
