import {inject, Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';

import {WINDOW} from '@vmi/injection-tokens';
import {Transaction, TransactionsFilters} from '@vmi/feature-transactions';
import {EmptyPipe} from '@vmi/ui-presentational';

import {TransactionsFilterService} from './transactions-filter.service';
import {TranslateTransactionStatusPipe} from '../pipes/translate-transaction-status.pipe';
import {TranslateMovementTypePipe} from '../pipes/translate-movement-type.pipe';
import {TransactionPostedDatePipe} from '../pipes/transaction-posted-date.pipe';
import {TransactionProcessedDatePipe} from '../pipes/transaction-processed-date.pipe';
import {TransactionQuantityPipe} from '../pipes/transaction-quantity.pipe';
import {
  TranslateProductCategoryPipe
} from '../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';

@Injectable({
    providedIn: 'root',
})
export class TransactionsClipboardService {
    readonly #window = inject(WINDOW);
    readonly #snackbar = inject(MatSnackBar);
    readonly #translateService = inject(TranslateService);
    readonly #transactionsFilterService = inject(TransactionsFilterService);

    readonly #translateTransactionStatusPipe = new TranslateTransactionStatusPipe();
    readonly #translateMovementTypePipe = new TranslateMovementTypePipe();
    readonly #transactionPostedDatePipe = new TransactionPostedDatePipe();
    readonly #transactionProcessedDatePipe = new TransactionProcessedDatePipe();
    readonly #transactionQuantityPipe = new TransactionQuantityPipe();
    readonly #translateProductCategoryPipe = new TranslateProductCategoryPipe();
    readonly #emptyPipe = new EmptyPipe();

    public copy(transactions: Transaction[], searchPhrase: string, filters: TransactionsFilters | undefined): void {
        const historicData = this.#transactionsFilterService.filterOutFutureTransactions(transactions);
        const searchedData = this.#transactionsFilterService.applySearchPhrase(historicData, searchPhrase);
        const preparedData = this.#transactionsFilterService.applyFilters(searchedData, filters);

        const text = this.convertToExcelString(preparedData);
        this.#window.navigator.clipboard.writeText(text).then(() =>
            this.#snackbar.open(this.#translateService.instant('inventory.clipboard.saved'), undefined, {
                duration: 3000,
            })
        );
    }

    private convertToExcelString(transactions: Transaction[]): string {
        const NEW_CELL = '\t';
        const NEW_LINE = '\n';

        const postedLabel = this.#translateService.instant('inventory.products.grid.posted');
        const processedLabel = this.#translateService.instant('inventory.products.grid.processed');
        const dateLabel = this.#translateService.instant('inventory.products.grid.dateTime');

        const headers = [
            this.#translateService.instant('inventory.products.grid.status'),
            this.#translateService.instant('inventory.productDetails.grid.type'),
            this.#translateService.instant('inventory.products.grid.name'),
            this.#translateService.instant('inventory.products.grid.id'),
            this.#translateService.instant('inventory.products.grid.category'),
            this.#translateService.instant('inventory.productDetails.grid.amount'),
            `${postedLabel} - ${dateLabel}`,
            `${processedLabel} - ${dateLabel}`,
        ].join(NEW_CELL);

        const dataToExport = transactions
            .map((transaction) => {
                const postedDate = this.#transactionPostedDatePipe.transform(transaction);
                const processedDate = this.#transactionProcessedDatePipe.transform(transaction);

                return [
                    this.#translateTransactionStatusPipe.transform(transaction.status),
                    this.#translateMovementTypePipe.transform(transaction.movementType),
                    this.#emptyPipe.transform(transaction.name),
                    this.#emptyPipe.transform(transaction.id),
                    this.#emptyPipe.transform(this.#translateProductCategoryPipe.transform(transaction.category)),
                    this.#transactionQuantityPipe.transform(transaction, true) + '',
                    this.#emptyPipe.transform(this.formatDate(postedDate)),
                    this.#emptyPipe.transform(this.formatDate(processedDate)),
                ];
            })
            .map((row) => {
                return row.join(NEW_CELL);
            })
            .join(NEW_LINE);

        return headers + NEW_LINE + dataToExport;
    }

    private formatDate(date: Date | undefined): string | undefined {
        if (!date) {
            return undefined;
        }

        let month = (date.getMonth() + 1).toString();
        let day = date.getDate().toString();
        const year = date.getFullYear().toString();

        let hours = date.getHours().toString();
        let minutes = date.getMinutes().toString();
        let seconds = date.getSeconds().toString();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }
        if (hours.length < 2) {
            hours = '0' + hours;
        }
        if (minutes.length < 2) {
            minutes = '0' + minutes;
        }
        if (seconds.length < 2) {
            seconds = '0' + seconds;
        }

        return `${[year, month, day].join('-')} ${[hours, minutes, seconds].join(':')}`;
    }
}
