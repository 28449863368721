import {Component, inject} from '@angular/core';

import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {TranslateModule} from '@ngx-translate/core';

import {HdmuiBaseDialogComponent, HdmuiComponentsModule} from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, TranslateModule],
    templateUrl: './success-dialog.component.html',
    styleUrls: ['./success-dialog.component.scss'],
})
export class SuccessDialogComponent {
    readonly #dialogRef = inject(MatDialogRef<SuccessDialogComponent>);
    readonly #text: string = inject(MAT_DIALOG_DATA);

    OPTION_OK = HdmuiBaseDialogComponent.OPTION_OK;

    get text(): string {
        return this.#text;
    }

    get dialogRef(): MatDialogRef<SuccessDialogComponent> {
        return this.#dialogRef;
    }

    public onOk(): void {
        this.#dialogRef.close();
    }
}
