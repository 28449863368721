import {inject, Injectable} from '@angular/core';

import {LOCAL_STORAGE} from '@vmi/injection-tokens';
import {DataDisplayType} from '@vmi/shared-models';

export enum DisplayTypeStorageKey {
    INVENTORY = 'inventory-display-type',
    DELIVERIES = 'deliveries-display-type',
    TRANSACTIONS = 'transactions-display-type',
}

@Injectable({
    providedIn: 'root',
})
export class DisplayTypeStorageService {
    private readonly localStorage = inject(LOCAL_STORAGE);

    public getDisplayType(key: DisplayTypeStorageKey): DataDisplayType | undefined {
        const displayType = this.localStorage.getItem(key);
        return displayType ? JSON.parse(displayType) : undefined;
    }

    public saveDisplayType(key: DisplayTypeStorageKey, displayType: DataDisplayType): void {
        this.localStorage.setItem(key, JSON.stringify(displayType));
    }
}
