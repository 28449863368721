import {Component, inject, OnInit} from '@angular/core';
import {RxState} from '@rx-angular/state';
import {CommonModule} from '@angular/common';
import {distinctUntilChanged} from 'rxjs';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatButtonModule} from '@angular/material/button';
import {FormControl, FormGroup, ReactiveFormsModule} from '@angular/forms';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {MatSelectModule} from '@angular/material/select';
import {TranslateModule} from '@ngx-translate/core';

import {HdmuiAppearanceCustomizerElement} from '@heidelberg/hdmui-angular';
import {ProductBookingDto} from '@heidelberg/vmi-subscription-api-client';

import {
  LoadingIndicatorComponent,
  LocalizedDatePipe,
  TableAppearanceCustomizerComponent,
} from '@vmi/ui-presentational';

import {ConsumptionReportFacade} from '../../facades/consumption-report.facade';
import {ConsumptionReportTimeRange} from '../../models/consumption-report-time-range.enum';
import {
  ConsumptionReportTableComponent
} from '../../components/consumption-report-table/consumption-report-table.component';
import {ConsumptionReportTimeRangePipe} from '../../pipes/consumption-report-time-range.pipe';
import {
  TranslateProductCategoryPipe
} from '../../../../../feature-inventory/src/lib/pipes/translate-product-category.pipe';
import {FilterConsumptionReportsPipe} from '../../pipes/filter-consumption-reports.pipe';
import {ConsumptionReportFilters} from '../../models/consumption-report-filters.interface';

@UntilDestroy()
@Component({
    selector: 'app-consumption-report-wrapper',
    templateUrl: './consumption-report-wrapper.component.html',
    styleUrls: ['./consumption-report-wrapper.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        LoadingIndicatorComponent,
        MatSelectModule,
        MatDatepickerModule,
        MatIconModule,
        MatInputModule,
        MatButtonModule,
        ReactiveFormsModule,
        ConsumptionReportTableComponent,
        TableAppearanceCustomizerComponent,
        TranslateModule,
        LocalizedDatePipe,
        ConsumptionReportTimeRangePipe,
        TranslateProductCategoryPipe,
        FilterConsumptionReportsPipe,
    ],
    providers: [RxState, ConsumptionReportFacade],
})
export class ConsumptionReportWrapperComponent implements OnInit {
    readonly #consumptionReportFacade = inject(ConsumptionReportFacade);

    public readonly customizerDefaultElements = this.#consumptionReportFacade.defaultCustomizerElements;

    public readonly state$ = this.#consumptionReportFacade.state$;

    public readonly minDate = new Date();
    public readonly maxDate = new Date();

    public readonly ConsumptionReportTimeRange = ConsumptionReportTimeRange;

    public readonly rangeForm = new FormGroup({
        from: new FormControl(),
        to: new FormControl(),
    });

    public readonly timeRanges = [
        ConsumptionReportTimeRange.THIS_WEEK,
        ConsumptionReportTimeRange.LAST_WEEK,
        ConsumptionReportTimeRange.THIS_MONTH,
        ConsumptionReportTimeRange.LAST_MONTH,
        ConsumptionReportTimeRange.CUSTOM,
    ];

    constructor() {
        const FIFTY_NINE = 59;
        const TWENTY_THREE = 23;

        this.minDate.setMonth(this.minDate.getMonth() - 2);
        this.minDate.setHours(0, 0, 0, 0);

        this.rangeForm.valueChanges.pipe(untilDestroyed(this), distinctUntilChanged()).subscribe(() => {
            const dateFrom = this.rangeForm.get('from')?.value;
            const dateTo = this.rangeForm.get('to')?.value;

            if (dateFrom && dateTo && this.rangeForm.valid) {
                this.#consumptionReportFacade.setDateFrom(
                    new Date(Date.UTC(dateFrom.getFullYear(), dateFrom.getMonth(), dateFrom.getDate(), 0, 0, 0, 0))
                );
                this.#consumptionReportFacade.setDateTo(
                    new Date(
                        Date.UTC(
                            dateTo.getFullYear(),
                            dateTo.getMonth(),
                            dateTo.getDate(),
                            TWENTY_THREE,
                            FIFTY_NINE,
                            FIFTY_NINE,
                            FIFTY_NINE
                        )
                    )
                );
            }
        });
    }

    ngOnInit(): void {
        this.#consumptionReportFacade.updateCustomizerElementsFromLocalStorage();
    }

    public onTimeRangeChange(timeRange: ConsumptionReportTimeRange): void {
        this.rangeForm.reset();
        this.#consumptionReportFacade.selectTimeRange(timeRange);
    }

    public onCategoryChange(category: string | undefined): void {
        this.#consumptionReportFacade.selectCategory(category);
    }

    public onCustomizerConfigElementsChange(elements: HdmuiAppearanceCustomizerElement[], shouldPersist = true): void {
        this.#consumptionReportFacade.onCustomizerConfigElementsChange(elements, shouldPersist);
    }

    public onCopyToClipboardClick(
        consumptionReports: ProductBookingDto[],
        timeRange: string,
        filters: ConsumptionReportFilters
    ): void {
        this.#consumptionReportFacade.copyToClipboard(consumptionReports, timeRange, filters);
    }
}
