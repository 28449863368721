import {Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';

type Formats = 'short' | 'medium' | 'full' | 'long' | undefined;

@Pipe({
    standalone: true,
    name: 'localizedDate',
    pure: false,
})
export class LocalizedDatePipe implements PipeTransform {
    constructor(private readonly translateService: TranslateService) {}

    transform(
        value: undefined | number | Date,
        dateStyle: Formats = undefined,
        timeStyle: Formats = undefined,
        lang = this.translateService.currentLang
    ): string | undefined {
        return value
            ? new Intl.DateTimeFormat(lang, {
                  timeStyle,
                  dateStyle,
              }).format(value)
            : undefined;
    }
}
