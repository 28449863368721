import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, RouterStateSnapshot} from '@angular/router';

import {CCAuthService} from '@heidelberg/control-center-frontend-integration/auth';

import {PermissionService} from '../services';

@Injectable()
export class SessionAuthGuard {
    constructor(
        private readonly permissionService: PermissionService,
        private readonly ccAuthService: CCAuthService
    ) {}

    canActivate(_route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean | Promise<boolean> {
        const url = state.url;
        return this.permissionService.checkRouteWithOrganizationCheck(
            url,
            this.ccAuthService.getCurrentMembership()?.organizationId
        );
    }
}
