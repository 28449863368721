export const NO_CONTEXT_ID = 'no_context';
export interface RoleContexts {
    [id: string]: RoleContext;
}
export interface RoleContext {
    id: string;
    roles: Role[];
    permissions: Permissions;
}

export function fromRoles(roles: Role[]): Permissions {
    const result: Permissions = {};
    for (const role of roles) {
        role.permissions.forEach((perm) => {
            result[perm.id] = perm;
        });
    }
    return result;
}

export enum EUserUnitSystem {
    metric,
    imperial,
}

export interface Permissions {
    [id: string]: Permission;
}

export interface Permission {
    readonly id: string;
    readonly translationKey: string;
    displayName?: string;
    readonly routes?: RouteConfig[];
}

export interface Role {
    readonly id: string;
    readonly permissions: Permission[];
}

export interface RouteConfig {
    appPath: string;
    pagePath: string;
}

export interface SessionOrganization {
    id: string;
    name: string;
}
