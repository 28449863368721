import {inject, Injectable} from '@angular/core';
import {filter, map, Observable, take} from 'rxjs';

import {QrScanDialogService} from '@vmi/ui-smart';
import {ExtractedScannedProductData, extractProductDataFromScannedValue} from '@vmi/utils';
import {SearchInputComponent} from '@vmi/ui-presentational';

@Injectable({
    providedIn: 'root',
})
export class QrSearchDialogService {
    readonly #qrScanDialogService = inject(QrScanDialogService);

    public openQrCodeDialogForSearch(searchInputComponent: SearchInputComponent): {
        afterClosed: () => Observable<ExtractedScannedProductData>;
    } {
        const qrScanDialog = this.#qrScanDialogService.openQrScanDialog();

        qrScanDialog.componentInstance.manualSearchClick.pipe(take(1)).subscribe(() => {
            searchInputComponent.focus();
        });

        return {
            afterClosed: () =>
                qrScanDialog.afterClosed().pipe(
                    take(1),
                    filter((scannedValue) => !!scannedValue),
                    map((scannedValue) => this.onQrScanDialogForSearchClosed(scannedValue))
                ),
        };
    }

    private onQrScanDialogForSearchClosed(scannedValue: string): ExtractedScannedProductData {
        const extractedValues = extractProductDataFromScannedValue(scannedValue);
        const id = extractedValues.id;
        const shelf = extractedValues.shelf;

        return { id, shelf };
    }
}
