import {Injectable} from '@angular/core';

import {
  ConfigService,
  Stage,
  STAGE_DEV,
  STAGE_LOCAL,
  STAGE_STAGE,
} from '@heidelberg/control-center-frontend-integration';
import {DeployEnvironment} from '@heidelberg/control-center-frontend-integration/auth';

import {environment} from '../../../../../src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class AppConfigService {
    private get stage(): Stage {
        return this.configService.stage;
    }

    constructor(private readonly configService: ConfigService) {}

    public getStage(): Stage {
        return this.stage;
    }

    public getCCStage(): DeployEnvironment {
        const stage = this.getStage();
        switch (stage) {
            case STAGE_LOCAL:
                return 'stage';
            case STAGE_DEV:
                return 'stage';
            case STAGE_STAGE:
                return 'stage';
            default:
                break;
        }

        return 'prod';
    }

    public getOAuthClientId(): string {
        return environment.APP_CLIENT_ID;
    }
}
