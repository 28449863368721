import {AfterViewInit, Directive, ElementRef, inject, Input, Renderer2} from '@angular/core';
import {UntilDestroy, untilDestroyed} from '@ngneat/until-destroy';
import {BehaviorSubject, combineLatest, distinctUntilChanged} from 'rxjs';

import {PrintShopsService} from '@vmi/core';

@UntilDestroy()
@Directive({
    standalone: true,
    selector: '[checkWriteAccess]',
})
export class CheckWriteAccessDirective implements AfterViewInit {
    readonly #renderer = inject(Renderer2);
    readonly #elRef = inject(ElementRef);
    readonly #printShopsService = inject(PrintShopsService);

    readonly #forceDisabled$ = new BehaviorSubject<boolean>(false);

    @Input()
    set forceDisabled(forceDisabled: boolean) {
        this.#forceDisabled$.next(forceDisabled);
    }

    ngAfterViewInit(): void {
        combineLatest([this.#printShopsService.hasWriteRights$, this.#forceDisabled$])
            .pipe(untilDestroyed(this), distinctUntilChanged())
            .subscribe(([hasWriteRights, forceDisabled]) => {
                if (forceDisabled || !hasWriteRights) {
                    this.#renderer.setAttribute(this.#elRef.nativeElement, 'disabled', 'true');
                } else if (hasWriteRights) {
                    this.#renderer.removeAttribute(this.#elRef.nativeElement, 'disabled');
                }
            });
    }
}
