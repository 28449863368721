import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule } from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    imports: [TranslateModule, HdmuiComponentsModule],
    selector: 'vmi-about-dialog',
    templateUrl: './about-dialog.component.html',
    styleUrls: ['./about-dialog.component.scss'],
})
export class AboutDialogComponent {
    option = HdmuiBaseDialogComponent.OPTION_OK;
    constructor(
        public dialogRef: MatDialogRef<AboutDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: AboutData
    ) {}

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close(true);
    }
}

export interface AboutData {
    appName: string;
    copyright: string;
}
