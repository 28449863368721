import {inject, Pipe, PipeTransform} from '@angular/core';

import {Transaction} from '@vmi/feature-transactions';

import {TransactionsFilterService} from '../services/transactions-filter.service';

@Pipe({
    standalone: true,
    name: 'filterOutFutureTransactions',
})
export class FilterOutFutureTransactionsPipe implements PipeTransform {
    readonly #filterService = inject(TransactionsFilterService);

    transform(transactions: Transaction[] | undefined): Transaction[] | undefined {
        return transactions && this.#filterService.filterOutFutureTransactions(transactions);
    }
}
