import {inject, Injectable} from '@angular/core';
import {RxState} from '@rx-angular/state';
import {combineLatest, map, switchMap} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {isEqual} from 'lodash-es';
import {DateTime} from 'luxon';

import {HdmuiAppearanceCustomizerElement} from '@heidelberg/hdmui-angular';
import {ProductBookingDto} from '@heidelberg/vmi-subscription-api-client';

import {InventoryService} from '@vmi/core';
import {AppearanceCustomizerStorageKey, AppearanceCustomizerStorageService} from '@vmi/ui-smart';
import {ResizeService} from '@vmi/utils';
import {TableColumn} from '@vmi/shared-models';
import {ConsumptionReportConfigurableColumns} from '@vmi/feature-transactions';

import {ConsumptionReportState} from '../models/consumption-report-state.interface';
import {ConsumptionReportTimeRange} from '../models/consumption-report-time-range.enum';
import {ConsumptionReportClipboardService} from '../services/consumption-report-clipboard.service';
import {ConsumptionReportFilters} from '../models/consumption-report-filters.interface';

@Injectable()
export class ConsumptionReportFacade {
    readonly #state: RxState<ConsumptionReportState> = inject(RxState<ConsumptionReportState>);
    readonly #inventoryService = inject(InventoryService);
    readonly #resizeService = inject(ResizeService);
    readonly #appearanceCustomizerStorageService = inject(AppearanceCustomizerStorageService);
    readonly #translateService = inject(TranslateService);
    readonly #consumptionReportClipboardService = inject(ConsumptionReportClipboardService);

    readonly #configurableColumns: TableColumn[] = [
        {
            id: ConsumptionReportConfigurableColumns.UNIT,
            name: this.#translateService.instant('inventory.products.grid.unit'),
        },
        {
            id: ConsumptionReportConfigurableColumns.CATEGORY,
            name: this.#translateService.instant('inventory.products.grid.category'),
        },
        {
            id: ConsumptionReportConfigurableColumns.TIME_RANGE,
            name: this.#translateService.instant('inventory.consumptionReport.timeRange'),
        },
    ];

    public readonly defaultCustomizerElements: HdmuiAppearanceCustomizerElement[] = this.#configurableColumns.map(
        (col) => ({
            id: col.id,
            visible: true,
            name: col.name,
        })
    );

    public readonly state$ = this.#state.select();

    constructor() {
        this.#state.set({
            aggregatedReports: [],
            visibleColumns: this.#configurableColumns,
            customizerConfigElements: this.#configurableColumns.map((col) => ({
                id: col.id,
                visible: true,
                name: col.name,
            })),
        });

        this.selectTimeRange(ConsumptionReportTimeRange.LAST_MONTH);

        this.#state.connect('isTabletUp', this.#resizeService.isTabletUp$);

        this.#state.connect(
            'reportsMetadata',
            combineLatest([this.#state.select('dateFrom'), this.#state.select('dateTo')]).pipe(
                map(([dateFrom, dateTo]) => ({ dateFrom, dateTo })),
                switchMap(({ dateFrom, dateTo }) => this.#inventoryService.getConsumptionReport(dateFrom, dateTo))
            )
        );

        this.#state.connect(
            'aggregatedReports',
            this.#state.select('reportsMetadata').pipe(
                map((reportsMetadata) => reportsMetadata?.data || []),
                map(this.aggregateReports)
            )
        );

        this.#state.connect(
            'availableCategories',
            this.#state.select('aggregatedReports').pipe(
                map((aggregatedReports) =>
                    aggregatedReports
                        .filter((report) => report.category !== undefined)
                        .map((report) => report.category as string)
                ),
                map((categories) => [...new Set(categories)])
            )
        );

        this.#state.connect(
            'shouldDisplayAppearanceCustomizerBadge',
            this.#state
                .select('customizerConfigElements')
                .pipe(
                    map(
                        (customizerConfigElements) => !isEqual(this.defaultCustomizerElements, customizerConfigElements)
                    )
                )
        );

        this.#state.hold(
            this.#state.select('timeRange').pipe(
                map((timeRange) => {
                    let dateFrom: Date | undefined;
                    let dateTo: Date | undefined;

                    const todayDateTime = DateTime.fromJSDate(new Date(), { zone: 'utc' });

                    switch (timeRange) {
                        case ConsumptionReportTimeRange.THIS_WEEK:
                            dateFrom = todayDateTime.startOf('week').toJSDate();
                            dateTo = todayDateTime.endOf('week').toJSDate();
                            break;
                        case ConsumptionReportTimeRange.LAST_WEEK:
                            dateFrom = todayDateTime.minus({ week: 1 }).startOf('week').toJSDate();
                            dateTo = todayDateTime.minus({ week: 1 }).endOf('week').toJSDate();
                            break;
                        case ConsumptionReportTimeRange.THIS_MONTH:
                            dateFrom = todayDateTime.startOf('month').toJSDate();
                            dateTo = todayDateTime.endOf('month').toJSDate();
                            break;
                        case ConsumptionReportTimeRange.LAST_MONTH:
                            dateFrom = todayDateTime.minus({ month: 1 }).startOf('month').toJSDate();
                            dateTo = todayDateTime.minus({ month: 1 }).endOf('month').toJSDate();
                            break;
                    }

                    if (dateFrom && dateTo) {
                        this.#state.set({
                            dateFrom,
                            dateTo,
                        });
                    }
                })
            )
        );
    }

    public setDateFrom(date: Date | undefined): void {
        this.#state.set({
            dateFrom: date,
        });
    }

    public setDateTo(date: Date | undefined): void {
        this.#state.set({
            dateTo: date,
        });
    }

    public selectTimeRange(timeRange: ConsumptionReportTimeRange): void {
        this.#state.set({
            timeRange,
            category: undefined,
            reportsMetadata: {
                isLoading: false,
                data: [],
            },
        });
    }

    public selectCategory(category: string | undefined): void {
        this.#state.set({
            category,
        });
    }

    public onCustomizerConfigElementsChange(elements: HdmuiAppearanceCustomizerElement[], shouldPersist = true): void {
        this.#state.set({
            customizerConfigElements: elements,
            visibleColumns: elements
                .filter((el) => el.visible)
                .map((visibleEl) => ({ id: visibleEl.id, name: visibleEl.name })),
        });

        if (shouldPersist) {
            this.#appearanceCustomizerStorageService.saveCustomizerConfigElements(
                AppearanceCustomizerStorageKey.CONSUMPTION_REPORT,
                elements.map((el) => ({ id: el.id, visible: el.visible }))
            );
        }
    }

    public updateCustomizerElementsFromLocalStorage(): void {
        const savedCustomizerElements = this.#appearanceCustomizerStorageService.getSavedCustomizerConfigElements(
            AppearanceCustomizerStorageKey.CONSUMPTION_REPORT
        );

        if (savedCustomizerElements) {
            const elements: HdmuiAppearanceCustomizerElement[] = savedCustomizerElements.map((el) => {
                const defaultEl = this.defaultCustomizerElements.find((e) => el.id === e.id);

                return {
                    id: el.id,
                    name: defaultEl?.name || '',
                    visible: el.visible,
                };
            });
            this.onCustomizerConfigElementsChange(elements, false);
        }
    }

    public copyToClipboard(
        consumptionReports: ProductBookingDto[],
        timeRange: string,
        filters: ConsumptionReportFilters
    ): void {
        this.#consumptionReportClipboardService.copy(consumptionReports, timeRange, filters);
    }

    private aggregateReports(reports: ProductBookingDto[]): ProductBookingDto[] {
        const reportsMap = new Map<string, ProductBookingDto>();

        reports.forEach((report) => {
            const { id, amount } = report;

            if (!id) {
                return;
            }

            const aggregatedAmount = reportsMap.get(id)?.amount;
            if (aggregatedAmount !== undefined) {
                reportsMap.set(id, {
                    ...report,
                    amount: aggregatedAmount + amount,
                } as ProductBookingDto);
            } else {
                reportsMap.set(id, report);
            }
        });

        // only negative values, but displayed as positive ones
        return [...reportsMap.values()]
            .filter((report) => report.amount < 0)
            .map((report) => ({ ...report, amount: Math.abs(report.amount) }) as ProductBookingDto);
    }
}
