import {inject, Injectable} from '@angular/core';

import {SESSION_STORAGE} from '@vmi/injection-tokens';
import {InventoryFilters} from '@vmi/feature-inventory';
import {Filters} from '@vmi/shared-models';
import {DeliveryFilters} from '@vmi/feature-deliveries';
import {TransactionsFilters} from '@vmi/feature-transactions';

export enum FiltersStorageKey {
    INVENTORY = 'inventory-filters',
    STOCK_COUNTING = 'stock-counting-filters',
    DELIVERIES = 'deliveries-filters',
    TRANSACTIONS = 'transactions-filters',
}

interface FiltersStorage {
    key: FiltersStorageKey;
    payload: Filters;
}

export interface InventoryFiltersStorage extends FiltersStorage {
    key: FiltersStorageKey.INVENTORY;
    payload: InventoryFilters;
}

export interface DeliveryFiltersStorage extends FiltersStorage {
    key: FiltersStorageKey.DELIVERIES;
    payload: DeliveryFilters;
}

export interface TransactionsFiltersStorage extends FiltersStorage {
    key: FiltersStorageKey.TRANSACTIONS;
    payload: TransactionsFilters;
}

@Injectable({
    providedIn: 'root',
})
export class FiltersStorageService {
    private readonly sessionStorage = inject(SESSION_STORAGE);

    public getSavedFilters(key: FiltersStorageKey): Filters | undefined {
        const filters = this.sessionStorage.getItem(key);
        return filters ? JSON.parse(filters) : undefined;
    }

    public saveFilters(filtersLocalStorage: FiltersStorage): void {
        const { key, payload } = filtersLocalStorage;

        if (payload) {
            this.sessionStorage.setItem(key, JSON.stringify(payload));
        } else {
            this.sessionStorage.removeItem(key);
        }
    }
}
