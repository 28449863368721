<!-- TBD: excel exports are temporarily disabled - backend needs to be adjusted -->

<button mat-icon-button [matMenuTriggerFor]="menu">
    <mat-icon svgIcon="hdmui:export"></mat-icon>
</button>
<mat-menu #menu="matMenu">
    <button mat-menu-item (click)="onExcelExportClick()" [disabled]="true">{{ '*.export.excel' | translate }}</button>
    @if (isMovementHistoryExportAllowed) {
        <button mat-menu-item (click)="onMovementHistoryExportClick()" [disabled]="true">
            {{ 'inventory.exportProductMovementsHistoryXlsx' | translate }}
        </button>
    }
    @if (isMonthlyMovementSummaryExportAllowed) {
        <button mat-menu-item (click)="onMonthlyMovementSummaryExportClick()" [disabled]="true">
            {{ 'inventory.exportProductsMonthlyConsumptionSummaryXlsx' | translate }}
        </button>
    }
    @if (isQrExportAllowed) {
        <button mat-menu-item (click)="onQrExportClick()">
            {{ 'inventory.export.qr' | translate }}
        </button>
    }
</mat-menu>
