import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { HdmuiBaseDialogComponent, HdmuiComponentsModule, HdmuiIconsModule } from '@heidelberg/hdmui-angular';

@Component({
    standalone: true,
    imports: [HdmuiComponentsModule, HdmuiIconsModule],
    selector: 'vmi-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
})
export class ConfirmDialogComponent {
    option = HdmuiBaseDialogComponent.OPTION_YES_NO;
    icon = 'hdmui:info';
    constructor(
        public dialogRef: MatDialogRef<ConfirmDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: ConfirmData
    ) {
        if (data.icon) {
            this.icon = data.icon;
        }
    }

    onCancel(): void {
        this.dialogRef.close();
    }

    onOk(): void {
        this.dialogRef.close(true);
    }
}

export interface ConfirmData {
    title: string;
    icon?: string;
    text: string;
    isWarningStrongIcon?: boolean;
}
