<mat-card appearance="outlined" class="card hdmui-m-0 hdmui-p-0">
    <div class="hdmui-content">
        <div class="card__header hdmui-mb-3">
            <div class="card__header-title">
                <h2 class="hdmui-font-serif hdmui-m-0" [matTooltip]="transaction.name || ''">
                    {{ transaction.name | empty }}
                </h2>
            </div>
            <mat-icon
                class="card__status-icon"
                [svgIcon]="transaction.status | transactionStatusIcon"
                [class]="getIconClass(transaction.status)"
            ></mat-icon>
        </div>

        <hdmui-key-value
            [label]="'inventory.products.grid.id' | translate"
            [matTooltip]="transaction.id || ''"
            [suppressColon]="false"
            [wrapText]="true"
            class="hdmui-m-0"
        >
            {{ transaction.id | empty }}
        </hdmui-key-value>

        <hr />

        <div class="card__properties">
            @for (order of attrOrder; track order) {
                <ng-container
                    *ngTemplateOutlet="
                        {
                            status: status,
                            movementType: movementType,
                            category: category,
                            posted: posted,
                            processed: processed,
                            quantity: quantity,
                        }[order]!
                    "
                ></ng-container>
            }

            <ng-template #status>
                <hdmui-key-value
                    class="hdmui-mb-3 hdmui-mx-0"
                    [label]="'inventory.products.grid.status' | translate"
                    [matTooltip]="transaction.status | translateTransactionStatus"
                >
                    {{ transaction.status | translateTransactionStatus }}
                </hdmui-key-value>
            </ng-template>

            <ng-template #movementType>
                <hdmui-key-value
                    class="hdmui-mx-0"
                    [label]="'inventory.productDetails.grid.type' | translate"
                    [matTooltip]="transaction.movementType | translateMovementType"
                >
                    {{ transaction.movementType | translateMovementType }}
                </hdmui-key-value>
            </ng-template>

            <ng-template #category>
                <hdmui-key-value
                    class="hdmui-mx-0"
                    [label]="'inventory.products.grid.category' | translate"
                    [matTooltip]="(transaction.category | translateProductCategory) + ''"
                >
                    {{ transaction.category | translateProductCategory | empty }}
                </hdmui-key-value>
            </ng-template>

            <ng-template #processed>
                <hdmui-key-value
                    class="hdmui-mx-0"
                    [label]="'inventory.products.grid.processed' | translate"
                    [matTooltip]="(transaction | transactionProcessedDate | localizedDate: 'short' : 'short') || ''"
                >
                    {{ transaction | transactionProcessedDate | localizedDate: 'short' : 'short' }}
                </hdmui-key-value>
            </ng-template>

            <ng-template #posted>
                <hdmui-key-value
                    class="hdmui-mx-0"
                    [label]="'inventory.products.grid.posted' | translate"
                    [matTooltip]="(transaction | transactionPostedDate | localizedDate: 'short' : 'short') || ''"
                >
                    {{ transaction | transactionPostedDate | localizedDate: 'short' : 'short' }}
                </hdmui-key-value>
            </ng-template>

            <ng-template #quantity>
                <hdmui-key-value
                    class="hdmui-mx-0"
                    [label]="'inventory.productDetails.grid.amount' | translate"
                    [matTooltip]="(transaction | transactionQuantity) + ''"
                >
                    <div class="quantity__wrapper">
                        {{ transaction | transactionQuantity }}
                        @if (isProcessedStockCount(transaction)) {
                            <mat-icon
                                [svgIcon]="transaction.amount < 0 ? 'hdmui:arrowClassic-D' : 'hdmui:arrowClassic-U'"
                                [class]="transaction.amount < 0 ? 'hdmui-error' : 'hdmui-success'"
                            ></mat-icon>
                        }
                    </div>
                </hdmui-key-value>
            </ng-template>
        </div>
    </div>
</mat-card>
