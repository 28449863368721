import {Pipe, PipeTransform} from '@angular/core';

import {MovementType, Transaction} from '@vmi/feature-transactions';

import {getTransactionMovementType} from '../functions/transaction.functions';

@Pipe({
    standalone: true,
    name: 'transactionQuantity',
})
export class TransactionQuantityPipe implements PipeTransform {
    transform(transaction: Transaction, forClipboard = false): number {
        if (forClipboard && getTransactionMovementType(transaction) === MovementType.PROCESSED_STOCK_COUNT) {
            return transaction.amount;
        }

        return Math.abs(transaction.amount);
    }
}
