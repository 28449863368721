import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';

import {ConfigService} from '@heidelberg/control-center-frontend-integration';
import {CCAuthService} from "@heidelberg/control-center-frontend-integration/auth";

import {ContactParams} from '@vmi/shared-models';

@Injectable({
    providedIn: 'root',
})
export class ContactFormService {
    constructor(
        private readonly http: HttpClient,
        private readonly configService: ConfigService,
        public readonly sessionService: CCAuthService
    ) {}

    sendIssue(params: ContactParams): Observable<string> {
        const id = this.sessionService.getCurrentUser()?.id;

        console.log(`sending an issue: `, params);
        const url = `${this.configService.getControlCenterSvcUrl()}/persons/${id}`;
        return this.http.post<string>(`${url}/contact`, params);
    }
}
