import {inject, Injectable} from '@angular/core';
import {BehaviorSubject, map, Observable, of, Subject, switchMap, take, tap} from 'rxjs';

import {PrintshopVmiSubscriptionCheckDto, SubscriptionInfoClient} from '@heidelberg/vmi-subscription-api-client';

import {UserService} from '@vmi/core';
import {LOCAL_STORAGE} from '@vmi/injection-tokens';
import {PrintShop, Role} from '@vmi/shared-models';

export const SELECTED_PRINT_SHOP_LOCAL_STORAGE_KEY = 'SELECTED_PRINT_SHOP';

@Injectable({ providedIn: 'root' })
export class PrintShopsService {
    readonly #subscriptionInfoClient = inject(SubscriptionInfoClient);
    readonly #userService = inject(UserService);
    readonly #localStorage = inject(LOCAL_STORAGE);

    readonly selectedPrintShop$ = new BehaviorSubject<PrintShop | null>(null);
    readonly selectedPrintShopChange$ = new Subject<void>();
    readonly hasWriteRights$ = of(false).pipe(
        switchMap(() =>
            this.selectedPrintShop$.pipe(
                map((selectedPrintshop) => {
                    const rolesWithWriteRights: string[] = [Role.ADMINISTRATOR, Role.STORE_MAN];
                    return !!selectedPrintshop?.rights.some((right) => rolesWithWriteRights.includes(right));
                })
            )
        )
    );

    #selectedPrintShop!: PrintShop;
    #availablePrintShops: PrintShop[] = [];
    #hasInitializedPrintShops = false;

    public get selectedPrintShop(): PrintShop {
        return this.#selectedPrintShop;
    }

    public get availablePrintShops(): PrintShop[] {
        return this.#availablePrintShops;
    }

    public get selectedPrintShopNumber(): string {
        return this.#selectedPrintShop.customerNumber;
    }

    public get hasInitializedPrintShops(): boolean {
        return this.#hasInitializedPrintShops;
    }

    public selectPrintShop(printShop: PrintShop, shouldPersist = true) {
        this.selectedPrintShopChange$.next();
        this.selectedPrintShop$.next(printShop);
        this.#selectedPrintShop = printShop;
        if (shouldPersist) {
            this.persistPrintShopInStorage(printShop.customerNumber);
        }
    }

    public setUpPrintShops(): Observable<PrintShop[]> {
        return this.fetchVmiCustomerInfo$().pipe(
            take(1),
            map((vmiCustomersInfo) => {
                const existingCustomers = vmiCustomersInfo.filter((customerInfo) => customerInfo.isVmiCustomerExists);

                return this.#userService.customers.filter((printShop) =>
                    existingCustomers.some(
                        (existingCustomer) => existingCustomer.printshopNumber === printShop.customerNumber
                    )
                );
            }),
            tap((availablePrintShops) => {
                this.#availablePrintShops = availablePrintShops;
                this.#hasInitializedPrintShops = true;
            }),
            tap((availablePrintShops) => {
                const persistedCustomerNumber = this.getPersistedPrintShop();
                const persistedPrintShop = this.availablePrintShops.find(
                    (printShop) => printShop.customerNumber === persistedCustomerNumber
                );

                this.selectPrintShop(persistedPrintShop ? persistedPrintShop : availablePrintShops[0], false);
            })
        );
    }

    public persistPrintShopInStorage(sapCustomerNumber: string): void {
        this.#localStorage.setItem(SELECTED_PRINT_SHOP_LOCAL_STORAGE_KEY, sapCustomerNumber);
    }

    public getPersistedPrintShop(): string | null {
        return this.#localStorage.getItem(SELECTED_PRINT_SHOP_LOCAL_STORAGE_KEY);
    }

    private fetchVmiCustomerInfo$(): Observable<PrintshopVmiSubscriptionCheckDto[]> {
        const customerNumbers = this.#userService.customers.map((x) => x.customerNumber);

        if (customerNumbers.length) {
            return this.#subscriptionInfoClient.getVmi(customerNumbers).pipe(take(1));
        }

        return of([]);
    }
}
