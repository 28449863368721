import {inject, Pipe, PipeTransform} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {DateTimeFormatOptions} from 'luxon';

@Pipe({
    standalone: true,
    name: 'takeDateOnly',
    pure: false,
})
export class TakeDateOnlyPipe implements PipeTransform {
    readonly #translateService = inject(TranslateService);

    transform(date: Date | undefined): string | undefined {
        if (!date) {
            return undefined;
        }

        const options: DateTimeFormatOptions = { year: 'numeric', month: 'numeric', day: 'numeric' };
        return date.toLocaleDateString(this.#translateService.currentLang, options);
    }
}
