<hdmui-base-dialog
    dialogIcon="hdmui:qrCodeScan"
    [dialogTitle]="'inventory.qrscandialog.title' | translate"
    [okText]="'inventory.action.manualsearch' | translate"
    [cancelText]="'*.action.cancel' | translate"
    [dialogOptions]="OPTION_NO_BUTTON"
    (okClicked)="onManualSearchClick()"
>
    <div class="dialog__header">
        @if (!isLoading && devices.length) {
            <mat-form-field>
                <mat-label>{{ 'inventory.qrscandialog.cameraselect.label' | translate }}</mat-label>
                <input
                    class="camera__button"
                    matInput
                    [value]="selectedDevice?.label"
                    placeholder="Select your device"
                    readonly
                    (click)="changeCamera()"
                />
                <mat-icon matSuffix svgIcon="hdmui:cameraSwitch"></mat-icon>
            </mat-form-field>
        }
        @if (isTorchCompatible) {
            <mat-button-toggle [checked]="isTorchEnabled" (change)="toggleFlashlight()" class="torch-button hdmui-ms-3">
                <mat-icon [svgIcon]="flashlightIcon"></mat-icon>
            </mat-button-toggle>
        }
    </div>

    <div class="scanner__wrapper" [class.no-devices]="isLoading || !devices.length">
        <div class="scanner__overlay" #overlay></div>

        <div #scanner>
            <zxing-scanner
                [torch]="isTorchEnabled"
                [formats]="ALLOWED_FORMATS"
                [(device)]="selectedDevice"
                (torchCompatible)="onTorchCompatible($event)"
                (camerasFound)="camerasFoundHandler($event)"
                (camerasNotFound)="camerasNotFoundHandler()"
                (scanSuccess)="scanSuccessHandler()"
                (scanError)="scanErrorHandler($event)"
                (scanComplete)="scanCompleteHandler($event)"
                (permissionResponse)="onPermissionsResponse($event)"
            ></zxing-scanner>
        </div>
    </div>

    @if (isLoading) {
        <vmi-loading-indicator></vmi-loading-indicator>
    }

    @if (!isLoading && !devices.length) {
        <hdmui-empty-states icon="hdmui:warning" class="hdmui-my-3">
            <hdmui-empty-states-title>{{ emptyStatesTitle }}</hdmui-empty-states-title>
            <hdmui-empty-states-description>{{ emptyStatesDescription }}</hdmui-empty-states-description>
        </hdmui-empty-states>
    }

    @if (!isLoading && devices.length) {
        <p class="hdmui-caption hdmui-my-4 hdmui-pt-4">
            {{ 'inventory.qrscandialog.description' | translate }}
        </p>
    }

    <div class="dialog__buttons">
        <button mat-stroked-button (click)="onManualSearchClick()" class="hdmui-ms-0">
            <mat-icon svgIcon="hdmui:search"></mat-icon>
            {{ 'inventory.action.manualsearch' | translate }}
        </button>
    </div>
</hdmui-base-dialog>
