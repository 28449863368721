<mat-form-field>
    <mat-icon matPrefix svgIcon="hdmui:search"></mat-icon>
    <input
        #searchInput
        matInput
        [(ngModel)]="searchPhrase"
        (keyup)="applyInput()"
        [placeholder]="'*.search' | translate"
    />
    @if (searchPhrase.length > 0) {
        <button (click)="onResetClick()" aria-label="Clear" mat-icon-button matSuffix>
            <mat-icon svgIcon="hdmui:xSmall"></mat-icon>
        </button>
    } @else {
        @if (qrAllowed) {
            <button (click)="onQrCodeClick()" aria-label="Qr Scan" mat-icon-button matSuffix>
                <mat-icon svgIcon="hdmui:qrCode"></mat-icon>
            </button>
        }
    }
</mat-form-field>
