import {Pipe, PipeTransform} from '@angular/core';

import {ProductBookingDto} from '@heidelberg/vmi-subscription-api-client';

import {ConsumptionReportFilters} from '../models/consumption-report-filters.interface';

interface FilterStep {
    filterFnc: (data: ProductBookingDto[], filters: ConsumptionReportFilters) => ProductBookingDto[];
}

@Pipe({
    standalone: true,
    name: 'filterConsumptionReports',
})
export class FilterConsumptionReportsPipe implements PipeTransform {
    transform(reports: ProductBookingDto[], filters: ConsumptionReportFilters): ProductBookingDto[] {
        if (!filters) {
            return reports;
        }

        const filterPipeline: FilterStep[] = [
            {
                filterFnc: this.filterByCategories,
            },
        ];

        return filterPipeline.reduce((filteredData, { filterFnc }) => {
            return filterFnc(filteredData, filters);
        }, reports);
    }

    private filterByCategories(reports: ProductBookingDto[], filters: ConsumptionReportFilters): ProductBookingDto[] {
        if (filters.category) {
            return reports.filter((report) => report.category === filters.category);
        }

        return reports;
    }
}
