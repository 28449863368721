<hdmui-base-dialog
    dialogIcon="hdmui:trash"
    dialogTitle="{{ '*.button.remove' | translate }}"
    [dialogOptions]="option"
    (okClicked)="onOk()"
    (cancelClicked)="onCancel()"
>
    <vmi-dialog-scroll>
        <p>{{ value }}</p>
    </vmi-dialog-scroll>
</hdmui-base-dialog>
