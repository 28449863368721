import {HttpErrorResponse} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {MatSnackBar} from '@angular/material/snack-bar';

import {sanitiseError} from './error-parser';

@Injectable({
  providedIn: 'root'
})
export class ErrorDisplayService {
  private readonly snackbarDuration = 10000;

  constructor(private readonly snackBar: MatSnackBar) {}

  public displayErrorMessage(error: HttpErrorResponse) {
    const sanitised = sanitiseError(error);
    this.snackBar.open(`Error Occurred: '${sanitised.message}'`, 'Close', {
      duration: this.snackbarDuration
    });
  }
}
